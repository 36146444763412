import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderField from '../form/InputErrorHandler';
import validate from './validate';



class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      showPassword: false
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };


  render() {
    var currentUser = JSON.parse(localStorage.getItem("userData"));
              if (currentUser){
                  window.location.href = "/Anasayfa";
                  return;
              }
    const { handleSubmit, submitting, invalid } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Kullanıcı E-posta Adresi</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
            <AlternateEmailIcon />
            </div>
            <Field name="email" component={renderField} type="text" placeholder="E-posta Adresiniz" required />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Şifreniz</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={renderField}
              type={showPassword ? 'text' : 'password'}
              placeholder="Şifre"
              required
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          { <div className="account__forgot-password">
            <a href="/ResetPassword">Şifremi unuttum</a>
          </div> }
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
          </div>
        </div>
        <Button color="primary" type="submit" className="btn btn-primary account__btn account__btn--small" disabled={invalid || submitting}>GİRİŞ</Button>
        <Link className="btn btn-outline-primary account__btn account__btn--small" to="/register">ÜCRETSİZ ÜYE OL</Link>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
  validate
})(LogInForm);
