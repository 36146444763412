import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';


const KvkkRForm = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Başvuru Formu</h3>
        <h3 className="page-subhead subhead">Başvuru Formuna ait içerik aşağıdaki gibidir</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form">
            <div className="search-result">
              <p className="search-result__title">1. BAŞVURU HAKKININ KAPSAMI</p>
              <p className="search-result__preview">6698  Sayılı Kişisel  Verilerin  Korunması  Kanunu’nda  (“KVKK”)  ilgili kişi  olarak tanımlanan  kişisel  veri  sahiplerine  (“Bundan  sonra  “Başvuru  Sahibi”  olarak anılacaktır), KVKK’nın 11’inci maddesinde kişisel verilerinin işlenmesine ilişkin birtakım taleplerde bulunma hakkı tanınmıştır.
              11. Madde kapsamında “Başvuru Sahibi”’nin hakları aşağıdaki gibidir:
              <ul>
                  <li>a.	Kişisel veri işlenip işlenmediğini öğrenme,</li>
                  <li>b.	Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                  <li>c.	Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                  <li>d.	Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                  <li>e.	Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                  <li>f.	Kişisel verilerin silinmesini, yok edilmesini veya anonim hale getirilmesini isteme,</li>
                  <li>g.	Kişisel verilerin düzletme, silinme, yok edilme veya anonym hale getirilmesi talebinin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                  <li>h.	İşlenen verilerinin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                  <li>i.	Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                </ul>
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">2. BAŞVURU YÖNTEMİ</p>
              <p className="search-result__preview">KVKK’nın 13’üncü maddesinin birinci fıkrası uyarınca; Veri Sorumlusu olan Atax Yazılım ve Danışmanlık A.Ş. (Bundan sonra ATAX YAZILIM olarak anılacaktır) ‘ye bu haklara ilişkin olarak yapılacak başvuruların aşağıdaki başvuru yöntemleri veya Kişisel Verilerin Koruma Kurulu (“Kurul”) tarafından belirlenen diğer yöntemlerle tarafımıza iletilmesi gerekmektedir.
              ATAX YAZILIM ’a “yazılı” olarak yapılacak başvurular, bu formun çıktısı alınarak Tablo-1’de gösterilen 1 veya 2 numaralı yöntemlerden biri ile yapılacaktır. 3, 4 ve 5 numaralı yöntemler için yapılan başvurular da bu formun çıktısını almaya gerek yoktur. Başvuru Sahibi tüm başvuru yöntemlerinde Tablo-1’deki açıklamalara dikkat etmelidir.
              <table className="border-table rForm">
                  <thead>
                    <th>Yöntem No</th>
                    <th>Başvuru Yöntemi</th>
                    <th>Başvuru Adresi</th>
                    <th>Başvuruda Olması Gereken Zorunlu Bilgiler</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>İadeli Taahhütlü Mektup/ Noter Kanalı</td>
                      <td>Bahçelievler Mahallesi 323/1 Cad. C Blok Gazi Üniversitesi Teknokent Binası 10/50C İç Kapı No:72 Gölbaşı/Ankara</td>
                      <td>Zarfın/tebligatın üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.
Başvuruda ad, soyad, imza, T.C. Kimlik numarası, yabancılar için uyruğu, pasaport numarası, tebligata esas adresi, varsa bildirime esas e-posta adresi, telefon ve fax numarası, talep konusu bulunması zorunludur.</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Şahsen Başvuru</td>
                      <td>Bahçelievler Mahallesi 323/1 Cad. C Blok Gazi Üniversitesi Teknokent Binası 10/50C İç Kapı No:72 Gölbaşı/Ankara</td>
                      <td>Zarfın/tebligatın üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.
Başvuruda ad, soyad, imza, T.C. Kimlik numarası, yabancılar için uyruğu, pasaport numarası, tebligata esas adresi, varsa bildirime esas e-posta adresi, telefon ve fax numarası, talep konusu bulunması zorunludur.</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Kayıtlı Elektronik Posta (KEP) Yoluyla</td>
                      <td></td>
                      <td>Zarfın/tebligatın üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.
Başvuruda ad, soyad, imza, T.C. Kimlik numarası, yabancılar için uyruğu, pasaport numarası, tebligata esas adresi, varsa bildirime esas e-posta adresi, telefon ve fax numarası, talep konusu bulunması zorunludur.</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Güvenli Elektronik İmza İle İmzalanmış Elektronik Posta Yoluyla</td>
                      <td>info@ataxyazilim.com</td>
                      <td>Zarfın/tebligatın üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.
Başvuruda ad, soyad, imza, T.C. Kimlik numarası, yabancılar için uyruğu, pasaport numarası, tebligata esas adresi, varsa bildirime esas e-posta adresi, telefon ve fax numarası, talep konusu bulunması zorunludur.</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Elektronik Posta Yoluyla</td>
                      <td>info@ataxyazilim.com</td>
                      <td>Kayıtlı e-postanın konu bölümüne “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır. Başvuru sahibi; ATAX YAZILIM’a daha önce bildirdiği ve ATAX YAZILIM’ın sisteminde kayıtlı bulunan e-posta adresi kullanılarak yapılıp yapılmadığı teyit edilerek, işbu e-posta adresi kullanılarak yapılan talepler kabul edilecektir. Başvuruda ad, soyad, T.C. Kimlik numarası, yabancılar için uyruğu, pasaport numarası, tebligata esas adresi, varsa bildirime esas
posta adresi, telefon ve fax numarası, talep konusu bulunması zorunludur.</td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">3. BAŞVURULARIN SONUÇLANDIRILMASI
</p>
              <p className="search-result__preview">Başvuru Sahibi, kişisel verilerine ilişkin talebini ATAX YAZILIM’a yazılı olarak ve bu başvuru formu kapsamında açıklanan yöntemlerle iletmesi durumunda, ATAX YAZILIM talebin niteliğine göre talebin ATAX YAZILIM’a ulaştığı tarihten itibaren (30) gün içinde sonuçlandıracak ve Başvuru Sahibi’ ne elektronik ortamda iletecektir. 
Kişisel verilerin güvenliğinin sağlanması ve hukuka aykırı veri paylaşımlarının önlenmesi amacıyla ATAX YAZILIM, Başvuru Sahibi’ nden kimlik doğrulaması için bilgi talebinde bulunabilir. ATAX YAZILIM ayrıca başvurunun talebe uygun bir biçimde sonuçlandırılmasını sağlamak adına, Başvuru Sahibi’ ne başvurusu ile ilgili soru yöneltebilir. 
ATAX YAZILIM talebin ayrıca bir maliyet gerektirmesi durumunda Kurul tarafından belirlenecek olan tarifedeki ücreti Başvuru Sahibi’ ne yansıtılabilir. (Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ- Madde 7). 
Başvuru Sahibi, bu form kapsamında iletmekte olduğu taleplere ilişkin bilgilerinin doğru ve güncel olmaması ya da yetkisiz başvuru yapılması durumunda ATAX YAZILIM’ ın söz konusu başvurudan doğacak taleplerden dolayı herhangi bir sorumluluğu olmayacağını kabul eder. 
</p>
            </div>
            <div className="search-result">
              <p className="search-result__title">4. BAŞVURU SAHİBİNİN KVKK KAPSAMINDAKİ TALEBİ
</p>
              <p className="search-result__preview"></p>
            </div>
            <div className="search-result">
              <p className="search-result__title">5. BAŞVURU SAHİBİNİN İLETİŞİM BİLGİLERİ VE İMZASI 
</p>
              <p className="search-result__preview">
                <table className="border-table contact">
                  <tr>
                    <td>Adı-Soyadı</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>T.C. Kimlik Numarası</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Cep Telefonu Numarası</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>E-posta Adresi</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Adresi</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>İmza</td>
                    <td></td>
                  </tr>
                </table>
              </p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default KvkkRForm;
