import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';


const KvkkBForm = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Açık Rıza İzin Formu</h3>
        <h3 className="page-subhead subhead">Açık Rıza İzin Formuna ait içerik aşağıdaki gibidir</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form">
            <div className="search-result">
              <p className="search-result__title"></p>
              <p className="search-result__preview">
                İş bu bilgilendirme metni, <strong>Atax Yazılım ve Danışmanlık A.Ş.</strong> (bundan böyle ATAX YAZILIM olarak ifade edilecektir)’nin “veri sorumlusu” sıfatıyla, 6698 Sayılı Kişisel Verilerin Korunması Kanunu (Kanun)’nun 5, 6, 9. ncu maddesi kapsamında, <strong>“Açık Rıza”</strong> sının alınması amacıyla hazırlanmıştır.
              </p>
              <p className="search-result__preview">Açık Rızanız kapsamında İşlenecek Kişisel Verileriniz şunlardır;
                  <ul>
                  <li><strong>Kimlik Bilgileri: </strong>Ad, soyad</li>
                  <li><strong>İletişim Bilgileri: </strong>Telefon no, mail</li>
                </ul>
              </p>
              <p className="search-result__preview">Kişisel Verileriniz;
              <br />
              ATAX YAZILIM’ ın <strong>e-posta hizmetlerini</strong> (yandex mail, gmail)  yurt dışından alması sebebiyle Kanunun 9(2) maddesi ve Kişisel Verileri Koruma Kurulunun 31.05.2019 tarih ve 2019/157 sayılı kararı ile yurt dışına aktarım şartları doğrultusunda aktarılacak ve paylaşılacaktır.

              </p>
              <p className="search-result__preview">Açık Rıza’nızı geri almak için dilediğiniz zaman <strong>info@ataxyazilim.com</strong> mail adresine e-posta gönderebilir ya da size gönderilen iletilerde yer alan çıkış linkine tıklayabilirsiniz.</p>
              <p className="search-result__preview">Kişisel Verilerin Korunması Politikamıza ve Kanunu’nun 11. Maddesi uyarınca sahip olduğunuz haklarınız ile ilgili <strong>detaylı bilgiye </strong> <a target="_blank"  rel="noopener noreferrer" href="https://www.ataxdenetim.com">www.ataxdenetim.com</a> adresinde yer alan <strong>“Kişisel Verilerin Korunması”</strong> bölümünden ulaşabilirsiniz.</p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default KvkkBForm;
