import LoadingIcon from 'mdi-react/LoadingIcon';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Badge, Button, Card, CardBody, Col, Table } from 'reactstrap';
import ConfirmationDialogRaw from '../../components/modal/ConfirmDialog';
import axiosNew from '../../data/common/getHeader';
import { handle401 } from '../../data/common/LedgerUtils';

const MyJobs = () => {
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);

  const [deleteConfirmOpen, setDeleteConfirmOpenOpen] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState();
  const [lastDeletedReport, setLastDeletedReport] = useState();

  const confirmAndDeleteAnalysis = (id) => {
    //localStorage.setItem("upload", id);
    // window.location.href = "/Sonuclar?analysisId=" + id;
    // console.log("Silinecek analiz: " + id);
    setDeletingId(id);
    setTimeout(() => {
      setDeleteConfirmOpenOpen(true);
    }, 300);
  };
  const refreshList = (newValue) => {
    // console.log("deneme function here"+ newValue);
    // console.log("last deleted"+ lastDeletedReport);
    setStatus(true);
    setLastDeletedReport(newValue);
    // console.log("last deleted"+ lastDeletedReport);
  };
  const handleCloseDeleteConfirm = (newValue) => {
    setDeleteConfirmOpenOpen(false);
    // console.log("confirmed value: " + newValue);
    if (newValue) {
      setDeletingId(newValue);
      const body = {
        jobId: newValue,
      };

      axiosNew
        .post('deleteReports', body)
        .then((back) => {
          if (back.status === 200) {
            console.log('delete completed: ' + newValue);

            setTimeout(() => {
              refreshList(newValue);
            }, 300);
          }
        })
        .catch((err) => {
          // handle401(err);
          console.log(err);
          alert('Dosya silinemedi, firma ile iletişime geçiniz.');
        });
    }
  };

  useEffect(() => {
    if (status) {
      setPM('Henüz hiç analiz yapmadınız');
      console.log('çekiyorum dikkat');
      axiosNew
        .get('getJobs')
        .then((res) => {
          if (res.status === 200) {
            setStatus(false);
            setLoading(false);
            setSub(res.data);
            if (res.data?.length > 0)
              setPM('Daha Önceki Analiz Sonuçları Aşağıda Yer Almaktadır');
          }
        })
        .catch((err) => {
          handle401(err);
        });
    }
  }, [lastDeletedReport]);
  const goAnalysis = (id) => {
    //localStorage.setItem("upload", id);
    window.location.href = '/Sonuclar?analysisId=' + id;
  };

  return (
    <>
      {' '}
      {loading ? (
        <div className="todo__panel-refresh">
          <LoadingIcon />
        </div>
      ) : (
        <Col md={12} lg={12} xl={9}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Analiz Sonuçlarım</h5>
                <h5 className="subhead">{pm}</h5>
              </div>
              <Table className="table--bordered" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>VKN/TCK NO</th>
                    <th>FİRMA</th>
                    <th>ANALİZ DÖNEMİ</th>
                    <th>DURUM</th>
                    <th>Analiz Tarihi</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sub.map((d, i) => (
                    <tr>
                      <td>{d.id}</td>
                      <td>{d.tin}</td>
                      <td>{d.taxpayerTitle}</td>
                      <td>
                        {d.periodYear}/{d.periodMonth}-{d.periodType}
                      </td>
                      <td key={i + 1}>
                        <Badge
                          color={
                            d.jobStatus === 'TAMAMLANDI'
                              ? 'success'
                              : d.jobStatus === 'DEVAM EDİYOR'
                              ? 'warning'
                              : 'danger'
                          }
                        >
                          {d.jobStatus}
                        </Badge>
                      </td>
                      <td>{d.analysisStartDate}</td>
                      {!d.isReportsDeleted ? (
                        <>
                          <td>
                            <Button
                              className="btn btn-primary btn-sm"
                              onClick={() => goAnalysis(d.id)}
                            >
                              Raporları Görüntüle
                            </Button>
                          </td>
                          <td>
                            <Button
                              className="btn btn-danger btn-sm"
                              onClick={() => confirmAndDeleteAnalysis(d.id)}
                            >
                              Raporları Sil
                            </Button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>Kalıcı olarak Silindi</td>
                          <td>-</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <ConfirmationDialogRaw
            id="ringtone-menu"
            keepMounted
            open={deleteConfirmOpen}
            onClose={handleCloseDeleteConfirm}
            value={deletingId}
            title={'Dikkat Kalıcı İşlem'}
            content={
              'Bu analize ait raporlar, geri döndürülemez şekilde silinecektir. Herhangi bir yedeği yoktur. Emin misiniz?'
            }
          />
        </Col>
      )}
    </>
  );
};
export default withTranslation('common')(MyJobs);
