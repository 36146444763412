import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Abonelikler from '../../../components/user/Abonelikler';

const ListSubscriptions = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Yeni Satışlar </h3>
        <h3 className="page-subhead subhead">Son 100 satış aşağıda listelenmektedir.
        </h3>
      </Col>
    </Row>
    <Row>
      <Abonelikler />
    </Row>
  </Container>
);


export default ListSubscriptions