import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import {Card, CardBody, Col, Table, Button} from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axiosNew from '../../data/common/getHeader';
import Modal from '../../components/modal/Modal';
import {handle401} from '../../data/common/LedgerUtils'

const MyCompanies = () =>
{

  const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [closed, setClosed] = useState();
    const [forced, setForced] = useState(false);
  let pm = '';
  const [loading, setLoading] = useState(true);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const subId = params.get('subId');

  const body = {
        "subscriptionId": subId
  }

  const [taxPayerList, setTaxPayerList] = useState([]);
  useEffect(() => {
      axiosNew.post("getTaxpayers",body).then(res => {
                if (res.status === 200) {
                  setLoading(false);
                  setTaxPayerList(res.data)

                  if(res.data?.length > 0)
                    pm  = 'Pakete Ait Firmalarınız aşağıda listeniyor';
                }

        }).catch(err => {
              handle401(err);
          }
        );

    }, [])


  const deleteAndGoCompanies = (id,tid) => {
   const body = {
         subscriptionId: id,
         taxpayerId: tid
       };

    axiosNew.post("removeTaxpayer", body).then((res) => {
          if (res.status === 200) {
           if(res.data === "SUCCESS"){
            setShow(true);
            setMessage('Firma başarıyla silindi. İşleme devam etmek için tamam butonuna basınız.');
            setTitle('İşlem Başarılı');
            setColor('success');
            setLink('/MyCompanies?subId=' + subId);
            setClosed(true);
            setForced(true);
            //window.location.href = "/MyCompanies?subId=" + subId;
          }
          else{
                setShow(true);
                          setMessage(res.data);
                          setTitle('İşlem Başarısız');
                          setColor('warning');
                          setLink('');

          }
        }
        }).catch(err => {
          setShow(true);
          setMessage('Bir hata oluştu, lütfen sistem yöneticisiyle görüşün.');
          setTitle('Hata Oluştu');
          setColor('danger');
          setLink('');
          setForced(false);
          setClosed(false);
          handle401(err);
        });

  }

return(

  <>
  {show &&
        <Modal setShow={setShow} color={color} toggle={show} open={show} header message={message} title={title} closedLink={link} isClosed={closed} isForced={forced}/>
      }
  {loading ? (
    <div className="todo__panel-refresh">
      <LoadingIcon />
    </div>
  ) : (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Firmalarınız</h5>
          <h5 className="subhead">{pm}
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Ünvan</th>
              <th>VKN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

          {taxPayerList.map((d,i) => (
            <tr>
              <td>{d.id}</td>
              <td>{d.title}</td>
              <td>{d.tin}</td>
              <td><Button className="btn btn-warning btn-sm" disabled = {d.hasJobs}  onClick={() => deleteAndGoCompanies(subId,d.id)}>SİL</Button></td>
            </tr>
          ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>)}
  </>
);
}
export default withTranslation('common')(MyCompanies);
