import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';


const StaticForm = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Kişisel Verilerin Korunması Politikamız</h3>
        <h3 className="page-subhead subhead">Aşağıdaki linklerden KVKK Bilgilendirme metinlerine ulaşabilirsiniz.</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div>
              <ul>
                <li>
                  <a href="/KVKKFormu">Aydınlatma Metni</a>
                </li>
                <li>
                  <a href="/BasvuruFormu">KVKK Başvuru Formu</a>
                </li>
                <li>
                  <a href="/RizaFormu">Açık Rıza Formu</a>
                </li>
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default StaticForm;
