import React, { useState,useEffect } from "react";

export const jobIdContext = React.createContext();

export const ProviderContext = ({children}) => {
	const [jobId, setJobId] = useState();
     useEffect(() => {
        });

	return (
	<jobIdContext.Provider value= {{jobId,setJobId}}>
		{children}
	</jobIdContext.Provider>
)

}

