import React, { Component } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../form/RadioButton';
import renderSelectField from '../form/Select';
import renderField from '../form/MaskInput';
import validate from './validate';
import renderFieldErr from '../form/InputErrorHandler';
import axiosNew from '../../data/common/getHeader';
import renderIntervalDatePickerField from '../form/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import Alert from '../../components/form/AlertWB';

let dval = "";
class AddTaxpayerComponent extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeButton: true,
      cityList: [],
      taxAdmin: [],
      packet: [],
      isOpen: false,
      ymm: false,
      value: '',
      dis: false,
      isFull: false
    };
  }


  render() {

    const {
      handleSubmit
    } = this.props;


    const mission = [
      { value: "SMMM", label: "SMMM" },
      { value: "YMM", label: "YMM" },
      { value: "YÖNETİM KURULU BAŞKANI", label: "YÖNETİM KURULU BAŞKANI" },
      { value: "YÖNETİM KURULU ÜYESİ", label: "YÖNETİM KURULU ÜYESİ" },
      { value: "ŞİRKET MÜDÜRÜ", label: "ŞİRKET MÜDÜRÜ" },
      { value: "MUHASEBE MÜDÜRÜ", label: "MUHASEBE MÜDÜRÜ" },
      { value: "MUHASEBE ÇALIŞANI", label: "MUHASEBE ÇALIŞANI" },
      { value: "DENETCI", label: "DENETÇİ" },
      { value: "DİĞER", label: "DİĞER" }
    ];
    const tcVKN = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    const { activeButton, isOpen, packet, dis, ymm, cityList, isFull } = this.state;

    return (
      <Col md={12} lg={12}>
              <Card>
                  <CardBody>
                      <form className="form add_cuser" onSubmit={handleSubmit}>
                          <div className="form__half">
                              <div className="form__form-group">
                                  <span className="form__form-group-label">Vergi Kimlik Numarası / TC Kimlik Numarası</span>
                                  <div className="form__form-group-field">
                                  <Field name="c_vkn" required component={renderField} mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]} guide={false} type="text" placeholder="TCK/VKN" />
                                  </div>
                              </div>
                              <div className="form__form-group">
                                                  <span className="form__form-group-label">Firma Adı</span>
                                                  <div className="form__form-group-field">
                                                    <Field
                                                      name="c_company"
                                                      component={renderFieldErr}
                                                      required type="text"
                                                      placeholder="Firmanızın adı"
                                                    />
                                                  </div>
                              </div>
                              <div className="form__form-group">
                                                  <span className="form__form-group-label">Göreviniz</span>
                                                  <div className="form__form-group-field">
                                                    <Field name="selectMission" component={renderSelectField} required
                                                      className="form__form-group-input-wrap--error-above" onChange={this.changeMission} options={mission} />
                                                  </div>
                              </div>

                            <ButtonToolbar className="form__button-toolbar">
                                                          <Button color="primary" type="submit">Kaydet</Button>
                                                      </ButtonToolbar>
                          </div>



                      </form>
                  </CardBody>
              </Card>
          </Col>
    );
  }
}

AddTaxpayerComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'vertical_form_layout_half',
  validate,
})(withTranslation('common')(AddTaxpayerComponent));