
/* eslint-disable */
const validate = (values) => {
    const errors = {};
    console.log("values in validate",values)
    if (!values.card) {
        errors.card = 'Lütfen kart türünü seçiniz';
    }
    if (!values.checkbox_one) {
        errors.checkbox_one = 'Lütfen sözleşmeyi onaylanıyız';
    }
    if (!values.cvc) {
        errors.cvc = 'Lütfen kartınızın güvenlik numarasını giriniz';
    }
    if (!values.date) {
        errors.date = 'Lütfen kartınızın son kullanma tarihi giriniz';
    }
    /*if (!values.selProd) {
        errors.selProd = 'Lütfen paket türünü seçiniz';
    }
    if (values.selProd?.value === 1 && !values.kota) {
        errors.kota = 'Lütfen paket türünü seçiniz';
    }*/
    if (!values.name) {
        errors.name = 'Lütfen adınızı giriniz';
    }
    if (!values.card_number) {
        errors.card_number = 'Lütfen kart numarasını giriniz';
    } else if(!ValidateCreditCardNumber(values.card_number)){
        errors.card_number = 'Lütfen geçerli bir kart numarası giriniz';
    }
    /*if (!values.kota) {
        errors.kota = 'Lütfen kota sayısını giriniz';
    }else if(values.kota < 1){
        errors.kota = 'Lütfen kota değerini giriniz';
    }
    else if(isNaN(values.kota)){
        errors.kota = 'Lütfen sadece sayısal değer giriniz';
    }*/
    if (!values.c_vkn) {
        errors.c_vkn = 'Lütfen VKN/TC numaranızı giriniz';
    }else if(isNaN(values.c_vkn)){
        errors.c_vkn = 'Lütfen sadece sayısal değer giriniz';
    }else if(values.c_vkn?.length > 11){
        errors.c_vkn = 'Lütfen geçerli bir VKN/TC numarası giriniz';
    }
    else if(values.c_vkn?.length < 10){
        errors.c_vkn = 'Lütfen geçerli bir VKN/TC numarası giriniz';
    }
    if (!values.selectCity) {
        errors.selectCity = 'Lütfen şehir seçiniz';
    }
    if (!values.c_address) {
        errors.c_address = 'Lütfen adres bilginizi giriniz';
    }
    if (values.financialPeriodType!=="GENEL" && !values.financialPeriodStartDate) {
        errors.financialPeriodStartDate = 'Lütfen hesap dönemi başlangıç tarihi seçiniz';
    }
    if (values.financialPeriodType!=="GENEL" && !values.financialPeriodEndDate) {
        errors.financialPeriodEndDate = 'Lütfen hesap dönemi bitiş tarihi seçiniz';
    }

    if(values.c_refcode && (values.c_refcode?.length !== 4 || isNaN(values.c_refcode))){
            errors.c_refcode = 'Lütfen 4 haneli geçerli bir referans numarası giriniz';
        }

    if (!values.c_phone) {
        errors.c_phone = 'Lütfen telefon numaranızı giriniz';
    }else if(values.c_phone?.match(/\d/g)?.length !== 10)
    {
        errors.c_phone = 'Lütfen geçerli telefon numarası giriniz';
    }
    if (!values.c_email) {
        errors.c_email = 'Lütfen e posta adresinizi giriniz';
    }else if (!validateEmail(values.c_email)) {
        errors.c_email = 'Lütfen geçerli e-posta adresi giriniz';
      }
      console.log("errors",errors)
    return errors;
};

export default validate;

function divideDM(d)
{
    var data = {
        month:0,
        year:0,
        status:false
    }
    if(d.length === 5)
    {
        var initial = d.split(/\//);
        if(initial.length === 2)
        {
             data.mpnth = initial[0];
             data.year = initial[1];
             data.status = true;
        }
    }
    return data.status;
}

function ValidateCreditCardNumber(ccNum) {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;
    ccNum = ccNum.replace(/-/g,'');
    
    if (visaRegEx.test(ccNum)) {
        isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
        isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
        isValid = true;
    } else if (discovRegEx.test(ccNum)) {
        isValid = true;
    }
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}