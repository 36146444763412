import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logOut = () =>{
    localStorage.clear();
    window.location.href = "/Login";
  }

  render() {
    const { collapse } = this.state;
    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{JSON.parse(localStorage.getItem("userData"))?.name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
              <TopbarMenuLink title="Analiz" icon="select" path="/AnalizYap" />
              <TopbarMenuLink title="Parametre Yönetimi" icon="menu-circle" path="/ParametreYonetimi"/>
              <TopbarMenuLink title="Şirket Ekle" icon="plus-circle" path="/YeniFirmaEkle"/>
              <TopbarMenuLink title="Kullanıcı Ekle" icon="users" path="/YeniKullaniciEkle"/>
              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Paket Al" icon="rocket" path="/Anasayfa" onClick={this.toggle}/>
              <div className="topbar__menu-divider" />
              <a className="topbar__link" onClick={this.logOut} >
                <span className="topbar__link-icon lnr lnr-exit"></span>
                <p className="topbar__link-title">Çıkış</p>
              </a>
          </div>
        </Collapse>
      </div>
    );
  }
}
