import axios from 'axios';
import {getUrl} from "./LedgerUtils";

const baseURL = getUrl();
const auth = JSON.parse(localStorage.getItem('userData'));
const config = {
  responseType: 'arraybuffer',
  headers: {
    Authorization: `Bearer ${auth?.token}`,
    'Accept': 'application/pdf'
  }
}

const axiosInstance = axios.create({
  baseURL: baseURL,
});

export default axiosInstance;