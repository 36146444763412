import React, { Component } from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import axiosNew from '../../data/common/getHeader';
import Alert from '../../components/form/AlertWB';

class PaymentBack extends Component {
    constructor() {
        super()
        this.state = {
            message: '',
            status: true
        }
    }
    async componentDidMount() {
        const url = "postPaymentToken";
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let result = params.get('result');
        let msg = "";
        let stt = false;
        switch(result){
            case "SUCCESS":
                msg = 'İşleminiz başarıyla gerçekleştirildi';
                stt = true;
                break;
            case "INIT_BANK_TRANSFER":
                msg = 'Havale/EFT işleminiz başlatıldı';
                stt = true;
                break;
            default:
                msg = "Hata oluştu: " + result;
                stt = false;
                break
        }
        this.setState({ message: msg, status: stt });
    }

    render() {
        const { message, status } = this.state
        return (
            <Container>
                <Row>
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <h4>Ödeme işlemi sonuçlandı</h4>
                                <Alert color={status ? "success" : "danger"} className="alert--neutral mt-3" icon>
                                    <p><span className="bold-text"> {status ? "Ödemeniz Başarıyla Tamamlandı" : "Ödeme Esnasında Hata Oluştu"}</span> {message}
                                    </p>
                                </Alert>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default PaymentBack;
