import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Result from '../../components/company/Result';

const AnalysisResult = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Analiz Sonuçlarınız</h3>
        <h3 className="page-subhead subhead">Yapmış olduğunuz analiz sonuçlarına bu sayfa üzerinden ulaşabilirsiniz.
        </h3>
      </Col>
    </Row>
    <Row>
      <Result />
    </Row>
  </Container>
);


export default AnalysisResult
