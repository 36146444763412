import React, {useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import AddCompanyForm from '../../components/company/AddTaxpayerComponent2';
import axiosNew from '../../data/common/getHeader';
import Modal from '../../components/modal/Modal';
import Alert from '../../components/form/AlertWB';
import {handle401} from "../../data/common/LedgerUtils";




const AddTaxpayer = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [closed, setClosed] = useState();
  const [forced, setForced] = useState(false);
 const postData = async values => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const subId = params.get('subId');

    const body = {
      tin: values.c_vkn,
      title: values.c_company,
      titleForTaxpayer: values.selectMission?.value,
      subscriptionId: subId
    };
    await axiosNew.post("addTaxpayer", body).then((res) => {
      if (res.status === 200) {
           if(res.data === "SUCCESS"){
            setShow(true);
            setMessage('Yeni firmanız başarıyle eklendi. İşleme devam etmek için tamam butonuna basınız.');
            setTitle('İşlem Başarılı');
            setColor('success');
            setLink('/MyCompanies?subId=' + subId);
            setClosed(true);
            setForced(true);
            localStorage.removeItem("ss");
          }
          else{
                setShow(true);
                          setMessage(res.data);
                          setTitle('İşlem Başarısız');
                          setColor('warning');
                          setLink('');
          }
    }
    }).catch(err => {
      setShow(true);
      setMessage('Bir hata oluştu, lütfen sistem yöneticisiyle görüşün.');
      setTitle('Hata Oluştu');
      setColor('danger');
      setLink('');
      setForced(false);
      setClosed(false);
      handle401(err);
    });

  }

  return (
    <>
    {show &&
      <Modal setShow={setShow} color={color} toggle={show} open={show} header message={message} title={title} closedLink={link} isClosed={closed} isForced={forced}/>
    }
    <Container>
      <Row>
        <Col md={12}>
        <Alert color="warning" className="alert--bordered" icon>
          <p><span className="bold-text">Aşağıya Lütfen Paket Sahibinin Değil, Analize Tabi Tutulacak Defterlerin Ait Olduğu Firma Bilgilerini Giriniz.</span>
          </p>
        </Alert>
        <h3></h3>
          {/* <h3 className="page-title">Yeni Firma Ekle</h3>
          <h3 className="page-subhead subhead">Mevcut Firmalarıma Yeni Kayıt Ekle</h3> */}
        </Col>
      </Row>
      <Row>
        <AddCompanyForm onSubmit={postData} />
      </Row>
    </Container>
    </>
  )
}
export default withTranslation('common')(AddTaxpayer);
