import axios from 'axios';
import {getUrl} from "./LedgerUtils";

const baseURL = getUrl();
const auth = JSON.parse(localStorage.getItem('userData'));
let headers = { Authorization: `Bearer ${auth?.token}`, 'Access-Control-Allow-Origin': '*' };

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});

export default axiosInstance;