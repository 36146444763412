import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';


const GizlilikPolitikasiForm = () => (


  <Container>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form" style={{padding:"20px"}}>
            <div className="search-result">
                         <h3 className="page-title">GİZLİLİK POLİTİKASI</h3>
                                   <p className="search-result__preview" >ATAX Yazılım ve Danışmanlık Anonim Şirketi (Bundan böyle “A-TAX” olarak anılacak) www.ataxdenetim.com web sitesini ziyaret edenlerin gizliliğini korumayı ilke olarak benimsemiştir. Bu gizlilik bildirimi, web sitemizi ziyaret eden kullanıcılara ilişkin ne tür bilgilerin ATAX tarafından alındığını, bunları toplama, saklama ve kullanma şeklimizi açıklamaktadır. Bu web sitesini kullanarak, iş bu gizlilik politikasında belirlenen bilgilerin toplanmasına ve kullanılmasına izin vermiş sayılırsınız.
                                                                         İşbu Gizlilik Bildirimi, A-TAX (www.ataxdenetim.com) sitesinden link verilmiş başka siteler için geçerli değildir.
                                   </p>

            </div>
            <div className="search-result">
              <p className="search-result__title">1.OTOMATİK TOPLANAN BİLGİLER</p>
            </div>

            <div className="search-result">
                          <p className="search-result__title">1.1.	Kimliği Saptanamayan Bilgiler  </p>
                          <p className="search-result__preview">Belirli bir kişiye ait kullanılacak ve işlenecek bilgiler, yalnızca bu kişinin kendi istemiyle veri girmesi ya da bu hususta açıkça muvafakat vermesi halinde mümkündür. Veri girilmesi veya bu hususta açıkça muvafakat verilmesi, kişinin aşağıda belirtilen şartlar ile mutabık olduğunun göstergesidir.
                                                                Sitemizi ziyaret ettiğinizde, sistem yönetimi, bazı bilgiler sunucularımızda otomatik olarak depolanacaktır. Bunlar; Internet servis sağlayıcınızın ismi, bilgisayarınızın İnternet Protokol adresi (IP), web sitesine giriş yaptığınız tarih ve zaman, web gezgini yazılımınızın versiyonu, erişim sağlanan bilgisayarın işletim sistemi, ziyaret ettiğiniz web sitesi bölümleri, görüntülediğiniz bilgiler ve web sitesinden indirimleriniz gibi. Bu bilgiler web sitesi ve web sitesinin geliştirilmesi için kullanılır.
                                                                Kendi isteğinizle kişisel bilgilerinizin verilmesi halinde, A-TAX, bu bilgileri yasada öngörülen veya ziyaretçinin muvafakat beyannamesinde belirtilen sınırlamalar dahilinde kullanacağını, işleme tabi tutulacağını veya aktarılacağını taahhüt eder. Söz konusu kişisel bilgilerinin üçüncü kişilere aktarımı, 5651 Sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun ve ilgili yönetmelikler kapsamındaki yükümlülüklerimiz gereği bu bilgilerin bir kısmı ilgili adli ve idari mercilerin talepleri üzerine kendileriyle paylaşılabilir.
            </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">1.2.	Çerezler (Cookies) </p>
              <p className="search-result__preview">Bu web sitesi “Cookies” teknolojisi kullanarak optimizasyon amaçlı veri toplayıp saklayabilir. Bu veriler, anonimleştirilmiş kullanıcı profilleri oluşturmak için kullanılabilir. Bu web sitesi kullanıcısının bilgisayarına bilgi yükleyerek, web sitesine bu bilgisayardan yapılacak ziyaretlerin tanınmasını sağlar. Çerezler ayarlanmış olabilir. Bu seçim kalıcı olarak ya da çerezi silene kadar geçerlidir.</p>
            </div>


            <div className="search-result">
                          <p className="search-result__title">2.KULLANILAN BİLGİLER</p>
                          <p className="search-result__preview">Web sitemizde bulunan “Yeni Hesap Oluştur” fonksiyonu ile A-TAX ve faaliyet alanlarımız ile ilgili gelişmelerden sizleri bilgilendirmeyi ve A-TAX ın sunduğu hizmetlerden belirli şartlar altında faydalandırmayı amaçlıyoruz. Kişisel verileri sadece 6698 sayılı Kişisel Verilerin Korunması Kanunu ve işbu Gizlilik Politikasına uygun bir şekilde toplayıp saklayacak, işleme tabi tutacak, kullanacak, iletecek ve/veya açıklayacağız. Ayrıntılı açıklama ve daha fazla bilgiye “KVKK Bilgilendirme Metni “, “Sözleşmeler” ve   “Aydınlatma Metni”ni okuyunuz.
                                                                “Yeni Hesap Oluştur” butonun ile kendinize ait bilgileri girmelisiniz. Gerçek ve tüzel kişi işletmelerin yanı sıra üçüncü kişiler de yetkili oldukları işletmeler için A-TAX aracılığıyla Yeni Hesap Oluştur” butonunu kullanarak hizmet alabilirler. Bu durumda anılan kişiler; adına hareket ettikleri kişiler veya işletmeye ait kişisel verileri yukarıda açıklanan işlemlere tabi tutmamız için gerekli izinleri aldığı anlamına gelmektedir. “Yeni Hesap Oluştur” butonu ile üye olanlar, e-Defterlerini yükledikleri ve raporlama yaptırdıkları işletmelerin yasal yolla, sözleşmeyle veya vekâletnameyle yetkilisi olduklarını, e-Defterlerini işletme sahiplerinin veya yetkililerinin bilgisi ve izniyle yüklediklerini, tüm sorumluluğun kendilerinde olduğunu, işbu sözleşmeyi onaylamakla beyan, kabul ve taahhüt ederler. A-TAX ın bunlar için araştırma yapma yükümlülüğü bulunmamaktadır.
                    </p>
            </div>

            <div className="search-result">
                          <p className="search-result__title">3. GÜVENLİK</p>
                          <p className="search-result__preview">A-TAX, size ait bilgileri güvenli biçimde saklamaktadır; dolayısıyla söz konusu bilgilerin herhangi bir kayıba, suistimale ya da değişikliğe karşı korunması için gerekli tüm önlemleri almaktadır. A-TAX’ ın, gerekli hizmeti size ulaştırmak için bilgilerinize erişimi olan sözleşme ortakları ( iyzico vb), sözleşme yükümlülükleri çerçevesinde bu bilgileri gizli tutmak ve başka hiçbir amaçla kullanmamaktadır. A-TAX bu güvenliği sağlarken https, yurtiçi server kullanımı vb araçları kullanmaktadır.</p>
            </div>

            <div className="search-result">
                          <p className="search-result__title">4. ÇOCUKLARA AİT BİLGİLER VE MAHREMİYETİN KORUNMASI</p>
                          <p className="search-result__preview">Bu web sitesi yetişkinler tarafından kullanılması öngörülmüş olup, web sitesi çocuklara yönelik değildir. Her ne kadar A-TAX 18 yaşından küçük çocuklara ilişkin bilgilerle ilgilenmemekte olsa da ocuklarla ilgili bilgilerin, verdiğimiz hizmetlerin ifası amacıyla toplanması ve saklanması gerekli olabilmektedir. Ebeveynlerin ya da yasal velinin, gözetimleri altındaki çocukların herhangi bir kişiye ilişkin bilgileri A-TAX´a ulaştırıldığına dair şüpheleri olması halinde, eğer söz konusu bilgilerin silinmesi isteniyorsa, web sitemizde yer alan adresten derhal bizle irtibata geçmelerini rica ederiz. </p>
            </div>

            <div className="search-result">
                          <p className="search-result__title">5.KİŞİSEL VERİLERİN KORUNMASI</p>
                          <p className="search-result__preview">Kişisel Verileriniz, bu Gizlilik Politikasında belirtilen durumlar dışında üçüncü kişilere aktarılamayacaktır. </p>
            </div>

            <div className="search-result">
                                      <p className="search-result__title">5.1- Yurt İçi Aktarım</p>
                                      <p className="search-result__preview">A-TAX söz konusu kişisel verileri yalnızca işbu Aydınlatma Metni’ nde belirtilen amaçlar doğrultusunda ve KVK Kanunu’nun 8. maddesine uygun olarak ve ilgili kişilerin açık rızasını alarak üçüncü kişilere aktarmaktadır. A-TAX, Kanun ve sair mevzuat hükümlerinin zorunlu kıldığı/izin verdiği kişi, kurum ve/veya kuruluşlara, bunlarla sınırlı olmamak üzere işbirliği yapılan program ortağı/çözüm ortağı kurum ve kuruluşlara, PTT şubelerine, lojistik destek hizmeti, danışmanlık ve bağımsız denetim hizmeti alınan firmalara, yasal zorunluluklar nedeniyle; yasal zorunluluk olmasa dahi 5.madde kapsamında daha iyi hizmet verebilmeye yönelik amaçlarını gerçekleştirebilmek için 3. kişi ve kurumlara, ilgili kişinin 6698 sayılı Kanun kapsamındaki haklarının saklı kalması koşuluyla, kişisel verileri aktarabilecektir.</p>
            </div>

            <div className="search-result">
                                                  <p className="search-result__title">5.2- Yurt Dışı Aktarım</p>
                                                  <p className="search-result__preview">A-TAX kişisel verileri, e-posta hizmetlerini (yandex mail / gmail) yurt dışından alması sebebiyle Kanunun 9(2) maddesi ve Kişisel Verileri Koruma Kurulunun 31.05.2019 tarih ve 2019/157 sayılı kararı ile yurt dışına aktarım şartları doğrultusunda iletişim faaliyetleri ve işbu Aydınlatma Metninde belirtilen amaçları gerçekleştirmek üzere aktarmaktadır. A-TAX ayrıca ödeme yöntemine, üyeliğine ve siparişine ilişkin bilgilerin, ödemenin gerçekleştirilebilmesi ve ödeme usulsüzlüklerinin önlenmesi, araştırılması ve tespit edilmesini temin amacıyla İyzico Ödeme Hizmetleri A.Ş.’ye aktarmaktadır.</p>
            </div>
            <div className="search-result">
                                                              <p className="search-result__title">6.DİĞER KONULAR</p>
                                                              <p className="search-result__preview"> A-TAX, yukarıda yer alan Gizlilik Politikasında değişiklikler yapma hakkına sahiptir. Bu durumda A-TAX, web sitesinde güncellenmiş Gizlilik Politikasını paylaşacaktır.</p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default GizlilikPolitikasiForm;
