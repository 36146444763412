import React from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../form/Select';
//import UserIcon from 'mdi-react/UserIcon';
import inputAuto from '../form/AutocomplateTB';

const AddCompanyUser = ({ handleSubmit }) => (
    <Col md={12} lg={12}>
        <Card>
            <CardBody>
                <form className="form add_cuser" onSubmit={handleSubmit}>
                    <div className="form__half">
                        <div className="form__form-group">
                            <span className="form__form-group-label">Firma Seçiniz</span>
                            <div className="form__form-group-field">
                            <Field name="select" component={renderSelectField} type="text"
                                options={[
                                    { value: 'one', label: 'Firma 1' },
                                    { value: 'two', label: 'Firma 2' },
                                    { value: 'three', label: 'Firma 3' },
                                ]}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Üye ID Giriniz</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon">

                                </div>
                                <Field component={inputAuto} />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                            <Button color="primary" type="submit">Üye Ekle</Button>
                        </ButtonToolbar>
                    </div>
                    
                    
                </form>
            </CardBody>
        </Card>
    </Col>
);

AddCompanyUser.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'add_cuser', // a unique identifier for this form
})(AddCompanyUser);