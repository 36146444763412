import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SubCustomerJobs from '../../../components/user/SubCustomerJobs';

const ListProduct = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Yapılan Analizler</h3>
        <h3 className="page-subhead subhead">Bağlı firmalarda yapılan analizler aşağıda listelenmiştir.
        </h3>
      </Col>
    </Row>
    <Row>
      <SubCustomerJobs />
    </Row>
  </Container>
);


export default ListProduct
