import React, {  useState, useEffect } from 'react';
import { Button, ButtonToolbar, Col, Card, CardBody } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderDropZoneMultipleField from '../form/DropZoneMultiple';
/*import renderDropZoneMultipleField from '../form/RDU';*/


const AnalysisFormTwo = ({ handleSubmit, reset, jobId}) => {

   const checkFileCount = (e) => {
        const fileCount = localStorage.getItem(jobId + "-smUP");
        if(isNaN(parseInt(fileCount))){
            return window.location.href = "/AnalizYap";
        }

        const totalFile = document.getElementsByClassName("dropzone__img").length;
        setCount(fileCount);
        if(parseInt(fileCount) > parseInt(totalFile)){
            alert("Lütfen en az" + fileCount + " adet dosya yükleyiniz.");
            return false;
        }
        else{
            handleSubmit();
        }
        
    }
    const [checked, setChecked] = useState(true);
    const [count, setCount] = useState(0);
    return (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
            <h3 className="wizard__title"></h3>
            <div className="form__form-group">
                <Field name="files" component={renderDropZoneMultipleField} {...{jobId:jobId}} />
            </div>
            <div className="form__form-group">
                <Col md={12} lg={6}>
                    <Card>
                        <CardBody>
                            {/* <div className="progress-wrap progress-wrap--middle">
                                <Progress value={70}>70%</Progress>
                            </div> */}
                        </CardBody>
                    </Card>
                </Col>
            </div>
            <ButtonToolbar className="upload__toolbar">
                <Button color="primary" onClick={e => checkFileCount(e)}>Defter Tarat</Button>
                <Button type="button" onClick={reset}>İptal</Button>
            </ButtonToolbar>
        </form>
    );
}

AnalysisFormTwo.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'drop_files_form',
    destroyOnUnmount: false, 
})(AnalysisFormTwo);
