import React, {useState, useEffect} from 'react';
import {
  Container, Row, Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import EmailIcon from 'mdi-react/EmailIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import { Link } from 'react-router-dom';
import axiosNew from '../../../data/common/getHeader';



const EmailConfirmation = () => 
{
    const [status, setStatus] = useState("");
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const result = params.get('result');
    const body = {
            "code": result
    }


    useEffect(() => {
          axiosNew.post("approveRegistration",body).then(res => {
                    if (res.status === 200) {
                            if(res.data === "APPROVED"){
                                setTitle("E-posta adresiniz başarıyla doğrulandı");
                                setSubtitle("Bizi seçtiğiniz için teşekkür ederiz");
                            } else if(res.data === "REJECTED"){
                                setTitle("Atax Üyelik onayını reddetme talebiniz alınmıştır");
                                setSubtitle("Bir sorun olduğunu düşünüyorsanız lütfen iletişime geçiniz");
                            } else if(res.data === "ERROR"){
                                setTitle("İşlem sırasında bir hata oluştu, lütfen tekrar deneyin.");
                                setSubtitle("Lütfen site yöneticisiyle iletişime geçiniz");
                            } else{
                                setTitle("Beklenmeyen bir hata oluştu");
                                setSubtitle("Lütfen site yöneticisiyle iletişime geçiniz");
                            }
                    }
                    else {
                        setTitle("Beklenmeyen bir hata oluştu");
                        setSubtitle("Lütfen site yöneticisiyle iletişime geçiniz");
                    }
            }).catch(err => {
                   setTitle("Beklenmeyen bir hata oluştu");
                   setSubtitle("Lütfen site yöneticisiyle iletişime geçiniz");
            });

        }, [])


return(
    <Container>
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <div className="email-confirmation">
                            <div className="email-confirmation__icon">
                                <EmailIcon className="email-confirmation__mail" />
                                <CheckboxMarkedCircleIcon className="email-confirmation__check" />
                            </div>
                            <h3 className="email-confirmation__title">{title}</h3>
                            <p className="email-confirmation__sub">{subtitle}</p>
                            <ButtonToolbar className="email-confirmation__buttons">
                                <Link className="btn btn-primary" to="/Login">Giriş Sayfası</Link>
                            </ButtonToolbar>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
);
}
export default EmailConfirmation;
