import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import AddUserForm from '../../components/company/AddUser';
import showResults from '../Show';

const AddNewUser = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Yeni Kullanıcı Ekle</h3>
        <h3 className="page-subhead subhead">Mevcut Firmalarıma Yeni Kullanıcı Ekle
        </h3>
      </Col>
    </Row>
    <Row>
      <AddUserForm onSubmit={showResults} />
    </Row>
  </Container>
);


export default withTranslation('common')(AddNewUser);
