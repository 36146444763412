import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import axiosNew from '../../data/common/getHeader';
import {handle401} from "../../data/common/LedgerUtils";
const userInfo = JSON.parse(localStorage.getItem("userData"));

const ManageAcc = () => {
   const [name, setName] = useState();
   const [sname, setSName] = useState();
   const [mail, setMail] = useState();
   const [date, setDate] = useState();

   const getInfo = async () =>{
     const res = await getInfo2();
     setName(res.name);
     setSName(res.lastName);
     setMail(res.email);
     setDate(res.registerDate);
   }
   const getInfo2 = async () =>{
   const d = await axiosNew.get("getUserInfo").then(res => {
       if(res.status === 200)
       {
         return res.data;
       }
     }).catch(err => {
       handle401(err);
   });
     return d;
   }
useEffect(() => {
  getInfo();
},[])
return(
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Üyelik Bilgileriniz</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="wizard">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p><span className="red-text">Adınız: </span> {name}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <p><span className="red-text">Soyadınız:    </span> {sname}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <p><span className="red-text">E-Posta:  </span> {mail}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <p><span className="red-text">Üyelik Tarihi:    </span> {date}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);
}

export default ManageAcc
