import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from './User/Login/index';
import Register from './User/Register/index';
import ResetPassword from './User/Reset/index';
import ForgetPassword from './User/Reset/ActReset';
import MailConfirm from './User/EMail/index';
import AllForm from './Static/StaticForms';
import FormKvkk from './Static/Kvkk';
import FormKvkk2 from './Static/Recourse';
import FormApp from './Static/Permit';
import GizlilikPolitikasi from './Static/GizlilikPolitikasi';
import Hakkimizda from './Static/Hakkimizda';
import Anasayfa from './Static/Anasayfa';
import Iletisim from './Static/Iletisim';
import AtaxSummary from './Static/AtaxSummary';
import AtaxDetailed from './Static/AtaxDetailed';
import AtaxVideo from './Static/AtaxVideo';

import MyProd from './User/ListProducts/index';
import MyJobs from './User/ListJobs/index';
// import BuyNow from './Product/BuyProduct';
import BuyNowFree from './Product/BuyProductFree';
// import ConfirmPage from './Product/Confirm';
import ConfirmPageFree from './Product/ConfirmFree';
// import SelectProd from './Product/SelectProduct';
import SelectProdFree from './Product/SelectProductFree';
import AddNew from './Company/AddTaxpayer';
import AddUser from './Company/AddCompanyUser';
import Analyze from './Company/Analysis';
import FileUP from './Company/UploadFiles';
import AnalyzeRes from './Company/AnalysisResult';
import HTMLReport from './Company/AnalysisResultHTML';
import Demo from './Company/AnalysisDemo';
import Dashboard from './Main/index';
import Uyelik from './User/ManageAccount';
import SifreDegisim from './User/ChangePass';
import PayIyzico from './Payment/IyzicoPayment';
import ReturnPayIyzico from './Payment/PaymentBack';
import MyComps from './User/ListCompanies/index';
import MyReferences from './User/ListReferences/index';
import NewUsers from './User/ListUsers/index';
import NewSubscriptions from './User/ListSubscriptions/index';
import NewJobs from './User/ListLastJobs/index';
import SubCostomerJobs from './User/ListSubCustomerJobs/index';

 const getToken = () =>{
  var a = JSON.parse(localStorage.getItem("userData"));
  return a?.token?.length > 100 ? true : false;
 }
const wrappedRoutes = () => (
  <div>
    {
      !getToken() &&  (window.location.href = "/Login")
    }
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route path="/AbonelikFaturaBilgileri" component={BuyNowFree} />
        <Route path="/YeniFirmaEkle" component={AddNew} />
        <Route path="/YeniKullaniciEkle" component={AddUser} />
        <Route path="/AnalizYap" component={Analyze} />
        <Route path="/DosyaYukle" component={FileUP} />
        <Route path="/Demo" component={Demo} />
        <Route path="/Dashboard" component={Dashboard} />
        <Route path="/UyelikBilgileri" component={Uyelik} />
        <Route path="/SifremiDegistir" component={SifreDegisim} />
        <Route path="/OnaySayfasi" component={ConfirmPageFree} />
        <Route path="/Prices" component={SelectProdFree} />
        <Route path="/Odeme" component={PayIyzico} />      
        <Route path="/paymentResult" component={ReturnPayIyzico} />
        <Route path="/Sonuclar" component={AnalyzeRes} />
        <Route path="/HTMLRapor" component={HTMLReport} />
        <Route path="/Paketlerim" component={MyProd} />
        <Route path="/BitenAnalizler" component={MyJobs} />
        <Route path="/BitenSubCustomerAnalizler" component={SubCostomerJobs} />
        <Route path="/MyCompanies" component={MyComps} />
        <Route path="/GizlilikPolitikasi" component={GizlilikPolitikasi} />
        <Route path="/Hakkimizda" component={Hakkimizda} />
        <Route path="/Anasayfa" component={Anasayfa} />
        <Route path="/Iletisim" component={Iletisim} />
        <Route path="/AtaxSummary" component={AtaxSummary} />
        <Route path="/AtaxDetailed" component={AtaxDetailed} />
        <Route path="/AtaxVideo" component={AtaxVideo} />
        <Route path="/Referanslarim" component={MyReferences} />
        <Route path="/Yenikullanicilar" component={NewUsers} />
        <Route path="/Yeniabonelikler" component={NewSubscriptions} />
        <Route path="/Yenianalizler" component={NewJobs} />
      </Switch>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/Login" component={LogIn} />
        <Route exact path="/Register" component={Register} />
        <Route exact path="/confirm" component={MailConfirm} />
        <Route exact path="/ResetPassword" component={ResetPassword} />
        <Route exact path="/forgetPass" component={ForgetPassword} />
        <Route exact path="/Index" component={Layout} />
        <Route path="/Sozlesmeler" component={AllForm} />
        <Route path="/KVKKFormu" component={FormKvkk} />
        <Route path="/RizaFormu" component={FormApp} />
        <Route path="/BasvuruFormu" component={FormKvkk2} />
        <Route path="/" component={wrappedRoutes} />

      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
