import React, {useState,useContext} from 'react';
import { Col, Container, Row } from 'reactstrap';
import AnalysisForm from '../../components/company/AnalysisCompanyDemo';
import axiosNew from '../../data/common/getHeader'
import Modal from '../../components/modal/Modal';
import {jobIdContext} from '../../shared/contextApi/JobIdContextApi';
import Alert from '../../components/form/AlertWB';
import {handle401} from "../../data/common/LedgerUtils";
import moment from "moment";

const AnalysisDemo = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [closed, setClosed] = useState(false);
  const [forced, setForced] = useState(false);
  // const {setJobId} = useContext(jobIdContext)

  const showResults = async values => {
    const month = values.selectMonth?.value;
    const year = values.selectYear?.value;
    const tinID = values.c_vkn;
    let financialPeriodType = values.financialPeriodType;
    let financialPeriodStartDate = values.financialPeriodType !=="GENEL" && values.financialPeriodStartDate !== null ? moment(values.financialPeriodStartDate).format('DD/MM/YYYY'): null;
    let financialPeriodEndDate =values.financialPeriodType !== "GENEL" &&  values.financialPeriodEndDate !== null ? moment(values.financialPeriodEndDate).format('DD/MM/YYYY'): null;

    const body = {
      taxpayerId:0,
      subscriptionId:0,
      periodEndYear:year,
      periodEndMonth:month,
      tin:tinID,
      financialPeriodType:financialPeriodType + " HESAP DÖNEMİ",
      financialPeriodStartDate:financialPeriodStartDate,
      financialPeriodEndDate:financialPeriodEndDate
    };

    const fBody ={ t: 0, s: 0, p: year, n:month, k: "d - " + tinID }
    await axiosNew.post('addJob', body)
    .then(user => {
      if(user.status === 200)
      {
        fBody.filePatterns = user.data?.expectedFilePatterns;
        if(user.data.message === "SUCCESS")
        {
          localStorage.setItem(user.data?.jobId + "-smUP", user.data?.expectedFilePatterns?.length);
          localStorage.setItem(user.data?.jobId + "-upFiles", JSON.stringify(fBody));
          localStorage.setItem(user.data?.jobId + "-fileType",values.fileType);
          setMessage("Devam etmek için Tamam butonuna basınız.");
          setShow(true);
          setTitle("İşlem Başarılı");
          setColor("success");
          setForced(true);
          setClosed(true);
          //setJobId(user.data?.jobId);
          setLink('/DosyaYukle?jobId=' + user.data?.jobId);
        } else {
                      setShow(true);
                      setMessage(user.data);
                      setTitle("Hata Oluştu");
                      setColor("warning");
                      //setForced(true);
                      //setClosed(true);
                      setLink('');
        }
        /*else if(user.data.message === "TIN NOT VALID")
        {
          setShow(true);
          setMessage("Girmiş olduğunuz VKN/TCK numarasını kontrol ediniz.");
          setTitle("Başarısız İşlem Denemesi");
          setColor("warning");
        }
        else if(user.data.message === "PERIOD NOT VALID")
        {
          setShow(true);
          setMessage("Seçmiş olduğunuz periyod arasında sorgulama yapamazsınız.");
          setTitle("Başarısız İşlem Denemesi");
          setColor("warning");
        }*/

      }
    })
    .catch(function (error) {
          setShow(true);
          setMessage("İşlem sırasında bir hata oluştu. Lütfen sistem yöneticisiyle görüşünüz. \nHata Mesajı: " + error.message);
          setTitle("Başarısız İşlem Denemesi");
          setColor("warning");
          handle401(error);
    });
  }
return (
  <>
  {show && 
    <Modal setShow={setShow} color={color} toggle={show} open={show} header={show} message={message} title={title} closedLink={link} isClosed={closed} isForced={forced}/>
  }
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">E-Defter Analizi</h3>
                    <Alert color="warning" className="alert--bordered" icon>
                              <p><span className="bold-text">Zip formatındaki yevmiye dosyaları daha kısa sürede yüklenmektedir.</span>
                              </p>
                            </Alert>
      </Col>
    </Row>
    <Row>
      <AnalysisForm onSubmit={showResults} />
    </Row>
  </Container>
  </>
);
}

export default AnalysisDemo;
