import LoadingIcon from 'mdi-react/LoadingIcon';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Badge, Button, Card, CardBody, Col, Table } from 'reactstrap';
import axiosNew from '../../data/common/getHeader';
import { handle401 } from "../../data/common/LedgerUtils";

const Abonelikler = () =>
{
  const [show, setShow] = useState(false);
     const [message, setMessage] = useState('');
     const [color, setColor] = useState('');
     const [title, setTitle] = useState('');
     const [link, setLink] = useState('');
     const [closed, setClosed] = useState();
     const [forced, setForced] = useState(false);
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);
  useEffect(() => {
    if(status){
      setPM('Yeni Satış bulunmamaktadır');
      axiosNew.get("getLastSubscriptions").then(res => {
        if (res.status === 200) {
          setStatus(false);
          setLoading(false);
          setSub(res.data);
          if(res.data?.length > 0)
            setPM('Yeni satışlar aşağıda listelenmektedir:');
        }
      }).catch(err => {
        handle401(err);
      });
    }
  }, []);

  const approveSubscription = (id) => {
       const body = {
             subscriptionId: id
           };

        axiosNew.post("approveSubscriptionManuelly", body).then((res) => {
              if (res.status === 200) {
               if(res.data === "SUCCESS"){
                setShow(true);
                setMessage('Abonelik onaylandı. İşleme devam etmek için tamam butonuna basınız.');
                setTitle('İşlem Başarılı');
                setColor('success');
                setLink('/Yeniabonelikler');
                setClosed(true);
                setForced(true);
                window.location.href = "/Yeniabonelikler";
              }
              else{
                    setShow(true);
                              setMessage(res.data);
                              setTitle('İşlem Başarısız');
                              setColor('warning');
                              setLink('');

              }
            }
            }).catch(err => {
              setShow(true);
              setMessage('Bir hata oluştu, lütfen sistem yöneticisiyle görüşün.');
              setTitle('Hata Oluştu');
              setColor('danger');
              setLink('');
              setForced(false);
              setClosed(false);
              handle401(err);
            });

      }

return(
  <> {loading ? (
    <div className="todo__panel-refresh">
      <LoadingIcon />
    </div>
  ) : (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Yeni Satışlar</h5>
          <h5 className="subhead">{pm}
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>ÜNVAN / EMAIL /VKN</th>
              <th>REFERANS</th>
              <th>ADRES</th>
              <th>PAKET (Adet)</th>
              <th>TARİH</th>
              <th>KDV DAHİL FİYAT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

          {sub.map((d,i) => (
            <tr>
              <td>{d.id}</td>
              <td>{d.title}/{d.email}/{d.tin}</td>
              <td>{d.referenceCode}</td>
              <td>{d.address}</td>
              <td>{d.packetName} ({d.kota})</td>
              <td>{d.startDate}</td>
              <td><Badge color={d.paid ? 'success' : 'warning'}>{d.totalPrice}</Badge></td>
              <td><Button className="btn btn-success btn-sm" disabled = {!d.waiting}  onClick={() => approveSubscription(d.id)}>ONAYLA</Button></td>
            </tr>
          ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>)}
  </>
);
}
export default withTranslation('common')(Abonelikler);
