import React, {useState, useEffect,useContext} from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, Badge, Table, Button, } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axiosNew from '../../data/common/getHeader';
import {handle401} from "../../data/common/LedgerUtils";

const LastJobs = () =>
{
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);
  useEffect(() => {
    if(status){
      setPM('Yeni Analiz bulunmamaktadır');
      axiosNew.get("getLastJobs").then(res => {
        if (res.status === 200) {
          setStatus(false);
          setLoading(false);
          setSub(res.data);
          if(res.data?.length > 0)
            setPM('Yeni analizler aşağıda listelenmektedir:');
        }
      }).catch(err => {
        handle401(err);
      });
    }
  }, []);

return(
  <> {loading ? (
    <div className="todo__panel-refresh">
      <LoadingIcon />
    </div>
  ) : (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Son yapılan analizler</h5>
          <h5 className="subhead">{pm}
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>AD SOYAD</th>
              <th>ANALİZ TARİHİ</th>
              <th>VKN</th>
              <th>ANALİZ DÖNEMİ - TİPİ</th>
              <th>TESPİT SAYISI</th>
              <th>YENİ TESPİTLER</th>
              <th>ANALİZ TİPİ</th>
            </tr>
          </thead>
          <tbody>

          {sub.map((d,i) => (
            <tr>
              <td>{d.analysisId}</td>
              <td>{d.name} {d.surname}</td>
              <td>{d.analysisDate}</td>
              <td>{d.tin}</td>
              <td>{d.analysisPeriod} - {d.analysisPeriodType}</td>
              <td>{d.detectionNum}</td>
              <td>{d.newDetectedRules}</td>
              <td><Badge color={d.analysisType === 'Normal' ? 'success' : 'warning'}>{d.analysisType}</Badge></td>
            </tr>
          ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>)}
  </>
);
}
export default withTranslation('common')(LastJobs);
