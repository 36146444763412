/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  listbox: {
    width: 'auto',
    'min-width':200,
    top:55,
    margin: 0,
    padding: 5,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
}));

export default function UseAutocomplete() {
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'uyeID-auto',
    options: getUyeID,
    getOptionLabel: (option) => option.id,
  });

  return (
    <div className="form__form-group-field">
      <div {...getRootProps()} className="react-select">
        <input className={classes.input} {...getInputProps()} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.id}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}


// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const getUyeID = [
  {id: "1", mail: "deneme1@example.com"},
  {id: "2", mail: "deneme2@example.com"},
  {id: "3", mail: "deneme3@example.com"},
  {id: "4", mail: "deneme4@example.com"},
  {id: "5", mail: "deneme5@example.com"},
  {id: "6", mail: "deneme6@example.com"},
  {id: "7", mail: "deneme7@example.com"},
  {id: "8", mail: "deneme8@example.com"},
  {id: "9", mail: "deneme9@example.com"},
  {id: "10", mail: "deneme10@example.com"}
];
