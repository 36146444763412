import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import Modal from '../../../components/modal/Modal';
import RegisterForm from '../../../components/user/RegisterForm';
import axiosNew from '../../../data/common/getHeaderWOToken';

const logo = `${process.env.PUBLIC_URL}/img/clogo.png`;

class Register extends Component {
  constructor() {
    super()
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      isSignUp: false,
      showModal: false,
      mColor: 'primary',
      message: '',
      mTitle: '',
      closed: false,
      goLink: '',
      process: false,
      forced: false
    }
  }

  setShow =(e)=> {
      this.setState({showModal: e})
    }

  handleSubmit = async values => {
    axiosNew.post('addCustomer', {
      firstName: values.name,
      lastName: values.surname,
      email: values.email,
      password: values.password,
      passwordAgain: values.password2,
      kvkk: values.checkbox_confirm,
      mailApproval:values.checkbox_mailApproval
    })
      .then(user => {
        let errMessage, sColor, title = "";
        if (user.status === 200) {
          this.setState({ showModal: false });
          switch (user.data) {
            case "SUCCESS":
              {
                localStorage.clear();
                errMessage = "Kaydınız başarıyla gerçekleşti. Lütfen e-postanızı kontrol ederek üyeliğinizi ONAYLAYINIZ. (ONAY maili bazı durumlarda spam kutusuna düşebilmektedir)";
                sColor = "success";
                this.setState({ closed: true, process: true, forced: true, goLink: '/Login' });
                title = "Kayıt İşlemi Başarılı";
                break;
              }
            default: {
              errMessage = "Hata Oluştu! \nHata Mesajı: " + user.data;
              sColor = "danger";
              title = "Kayıt İşlemi Başarısız";
              this.setState({ closed: false, process: false, forced: false, goLink: '' });
              break;
            }
          }
          this.setState({ message: errMessage, showModal: true, mColor: sColor, mTitle: title });
        } else {
          errMessage = "Hata Oluştu! \nHata Mesajı: " + user.data;
          sColor = "danger";
          title = "Kayıt İşlemi Başarısız";
          this.setState({ message: errMessage, showModal: true, mColor: sColor, mTitle: title, closed: false, process: false, goLink: '' });
        }
      })
      .catch(function (error) {
        let errMessage = ""; let sColor = "danger"; let title = "Hata Oluştu!";
        if (error.response) {
          errMessage = "Hata Kodu: " + error.response.status + " Hata Mesajı: " + error.response.data;
        } else if (error.request) {
          errMessage = "Request Hatası: " + error.request;
        } else {
          errMessage = "Hata Mesajı: " + error.message;
        }
        alert("Kayıt esnasında bir hata oluştu, lütfen tekrar deneyiniz.");
      });
  }

  render() {
    const { error, mColor, showModal, message, mTitle, goLink, closed, forced} = this.state;
    return (
      <React.Fragment>
        { showModal &&
          <Modal setShow={this.setShow} color={mColor} toggle={showModal} open={showModal} header message={message} title={mTitle} closedLink={goLink} isClosed={closed} isForced={forced}/>}
        <div className="account account--not-photo">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head">
                <h3 className="account__title">
                  <img src={logo} alt="Atax Yazılım" className="alogo"/>
                </h3>
                <h4 className="account__subhead subhead">Yeni hesap oluştur</h4>
              </div>
              <RegisterForm onSubmit={this.handleSubmit} errorMessage={error} />
              <div className="account__have-account">
                <p>Tanımlı bir hesabınız var mı? <NavLink to="/login">Giriş Yap</NavLink></p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default withRouter(Register);
