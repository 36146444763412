import React from 'react';
import {
  Card,
  CardBody, Col,
  Container,
  Row
} from 'reactstrap';
import Alert from '../../components/form/AlertWB';

const Anasayfa = () => (
  <Container className='white-container'> 
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form">


          <div className="form__form-group">
              <div className="form__form-group-field">
                <Alert color="success" className="alert--bordered" icon>
                  <p>
                    <span className="bold-text">
                      Yeni Özelleştirilebilir Rapor Hazırladık!                      
                    </span>{' '}
                  </p>
                </Alert>
              </div>
            </div>
            <div className="search-result">
              <p className="search-result__preview">
                Analiz sonunda, özelleştirilebilir raporu görüntüleyin. Sonrasında çıktısını almak istediğiniz kısımları + tuşunan basarak ekleyin. Raporu yazdır diyerek size özel çıktı alın. Çıktı ekranında PDF olarak kaydet seçeneğini seçerek bilgisayarda da muhafaza edebilirsiniz. Özelleştirilebilir rapor, analiz sonunda yönlendilirilen sonuç ekranında veya menüdeki "Önceki Analizlerim" sayfasında her analize ait raporlarla birlikte görüntülenebilir.
              </p>
            </div>
            <br></br>


            <div className="form__form-group">
              <div className="form__form-group-field">
                <Alert color="info" className="alert--bordered" icon>
                  <p>
                    <span className="bold-text">
                      ATAX - DENETİMDE YENİ BİR SÜREÇ!
                    </span>{' '}
                  </p>
                </Alert>
              </div>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                e-Defter üzerinde herhangi bir dışsal yardıma veya müdahaleye
                ihtiyaç duymadan;
                <ul>
                  <li> Verileri toplayacak, tasnif edecek, işleyecek,</li>
                  <li>
                    {' '}
                    Bunları vergi, muhasebe, TTK, MASAK vb. mevzuat tabanında
                    oluşturulmuş kural denetimlerinden geçirecek,
                  </li>
                  <li> Elde edilen sonuçları raporlayacak,</li>
                  <li>
                    {' '}
                    Sonuçları değerlendirip e-Defter sahibini yönlendirecek
                  </li>
                </ul>
                bir elektronik denetim programıdır. Kısaca, günümüzde manuel
                olarak gerçekleştirilen (veri toplama, işleme, derleme, analiz
                etme ve raporlama gibi) birçok denetim aşamasını ortadan
                kaldırarak, bunların hepsini tek aşamada hızlı bir şekilde
                gerçekleştiren bir yazılımdır.
              </p>
            </div>
            <br></br>

            <div className="form__form-group">
              <div className="form__form-group-field">
                <Alert color="info" className="alert--bordered" icon>
                  <p>
                    <span className="bold-text">
                      Atax Elektronik Denetim Programı Kullanıcı Dostu Bir
                      Yazılımdır!
                    </span>{' '}
                  </p>
                </Alert>
              </div>
            </div>
            <div className="search-result">
              <p className="search-result__preview">
                <ul>
                  <li> Riskleri Önler</li>
                  <li> Vergisel Fırsatlar Sunar </li>
                  <li> Hukuka Uygunluk</li>
                  <li> Tam Denetim, Hızlı Denetim</li>
                  <li> Finansal Raporlama Yapar</li>
                  <li> Mekan ve Zaman Kısıtı Olmaksızın Denetim </li>
                  <li> Otomatik Öz Denetim</li>
                  <li> Maliyet Tasarrufu Sağlar</li>
                  <li> Güvenli Denetim</li>
                </ul>
              </p>
            </div>
            <div className="search-result">
              <a class="square btn btn-warning" href="/Prices">
                FIRSATLARI İNCELE
              </a>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Anasayfa;
