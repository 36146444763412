import React, {Component, useState} from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../form/Select';
import validate from './validate';
import { withTranslation } from 'react-i18next';
import axiosNew from '../../data/common/getHeader';
import Alert from '../../components/form/AlertWB';
import renderRadioButtonField from '../form/RadioButton';
import {handle401} from "../../data/common/LedgerUtils";
import renderDatePickerField from "../form/DatePicker";

class AnalysisFormOne extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            data: [],
            packet: [],
            packet2: [],
            isOpen: false,
            dis: false,
            dis2: true,
            optionYears: [],
            openMsg: false,
            ozelHesapDonemi:false
        };
        this.changeOzelHesapDonemi = this.changeOzelHesapDonemi.bind(this);
    }

    changeOzelHesapDonemi = (value) => {
        this.setState({ozelHesapDonemi:value !== "GENEL"});
    }
    async componentDidMount() {
        /*var a = JSON.parse(localStorage.getItem("userData"));
        const token = a.token;
        const config = { headers: { Authorization: `Bearer ${token}` } };
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = params.get('paket');*/
        await axiosNew.get("getSubscriptions").then(res => {
            if (res.status === 200) {
                let st = res.data.length > 0;
                let sub = [];
                let count = 0;
                let firstSubID = 0;
                res.data.map((d, i) => {
                    /*if(d.packetLimit > 0){*/
                    if(!d.expired) 
                    {
                        sub.push({ value: d.subscriptionId, label: d.description });
                        if (firstSubID === 0)
                        firstSubID = d.subscriptionId;
                    } 
                    // if(!d.expired) sub.push({ value: d.subscriptionId, label: d.description });
                    // if (firstSubID === 0)
                    //     firstSubID = d.subscriptionId;
                    count++
                    /*}*/
                });
                if (count > 1) { this.setState({ dis: false }) }
                else {
                    this.setState({ dis: true });
                }
                this.packetChange(firstSubID);
                this.setState({ isOpen: st, packet: sub, packet2: '' });
            }
        }).catch(err => {
            handle401(err);
        });
    }
    packetChange = (e) => {
        localStorage.setItem("sb", e);
        var a = JSON.parse(localStorage.getItem("userData"));
        const token = a.token;
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const subID = e;
        const body = {
            subscriptionId: subID
        }
        axiosNew.post("getTaxpayers", body).then(res => {
            if (res.status === 200) {
                let sub = [];
                let stat = false;
                res.data.map((d, i) => {
                    sub.push({ value: d.id, label: d.title + ' - ' + d.tin });
                });
                if (sub.length > 0) { stat = false }
                else {
                    sub.push({ value: '0', label: 'Paketinize Firma Eklemeniz Gerekmektedir.' });
                    stat = true;
                }
                this.setState({ packet2: sub, dis2: stat, openMsg: stat });

            }
            else {
                this.setState({ dis2: true,openMsg: true });
            }
        }).catch(err => {
            handle401(err);
        });

        const sBody = {
            subscriptionId: subID
        }
        axiosNew.post("getSubscriptionYears", body).then(res => {
            if (res.status === 200) {
                if (res.data?.length > 0) {
                    let d = [];
                    for (let x = 0; x < res.data.length; x++) {
                        let c = { value: res.data[x], label: res.data[x] };
                        d.push(c);
                    }
                    this.setState({ optionYears: d });
                } else {
                    alert("Hata Oluştu: " + res.data);
                }
            }
        }).catch(err => {
            handle401(err);
        });
    }
    render() {
        const { data, packet, packet2, dis, dis2, isOpen, optionYears, openMsg,ozelHesapDonemi } = this.state;

        const {
            handleSubmit
        } = this.props;

        return (
            <>
                {isOpen &&
                    <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
                        <h3 className="wizard__title">Analiz Bilgilerini Giriniz</h3>
                        {openMsg && <div className="form__form-group">
                            <span className="form__form-group-label"></span>
                            <div className="form__form-group-field">
                                <Alert color="warning" className="alert--bordered" icon>
                                    <p><span className="bold-text">Dikkat!</span> Paketinize Firma Eklemelisiniz</p>
                                </Alert>
                            </div>
                        </div>}
                        <div className="form__form-group">
                            <span className="form__form-group-label">Paket Seçiniz</span>
                            <div className="form__form-group-field">
                                <Field name="selectPacket" className="form__form-group-input-wrap--error-above " required component={renderSelectField} type="text"
                                    options={packet} onChange={e => this.packetChange(e.value)} disabled={dis} defaultValue={packet[0]} />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Firma Seçiniz</span>
                            <div className="form__form-group-field">
                                <Field name="selectCompany" className="form__form-group-input-wrap--error-above selectCompany " component={renderSelectField}
                                    type="text" options={packet2} defaultValue={dis2 && packet2[0]} required disabled={dis2} />
                            </div>
                        </div>



                        <div className="form__form-group">
                            <span className="form__form-group-label">Hesap Dönemi Türü Seçiniz</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="financialPeriodType"
                                    component={renderRadioButtonField}
                                    label="GENEL HESAP DÖNEMİ"
                                    radioValue="GENEL"
                                    defaultChecked
                                    onChange={e=>this.changeOzelHesapDonemi(e)}
                                />
                                <Field
                                    name="financialPeriodType"
                                    component={renderRadioButtonField}
                                    label="ÖZEL HESAP DÖNEMİ"
                                    radioValue="ÖZEL"
                                    onChange={e=>this.changeOzelHesapDonemi(e)}

                                />
                                <Field
                                    name="financialPeriodType"
                                    component={renderRadioButtonField}
                                    label="KIST HESAP DÖNEMİ"
                                    radioValue="KIST"
                                    onChange={e=>this.changeOzelHesapDonemi(e)}

                                />
                            </div>
                        </div>

                        {
                            this.state.ozelHesapDonemi &&

                            <div className="form__form-group">
                                <span className="form__form-group-label">Başlangıç Tarihi</span>
                                <div className="form__form-group-field">
                                    <Field
                                        className=""
                                        name="financialPeriodStartDate"
                                        required
                                        component={renderDatePickerField}
                                        onChange={()=>{}}
                                    />
                                </div>
                            </div>
                        }
                        {
                            this.state.ozelHesapDonemi &&
                            <div className="form__form-group">
                                <span className="form__form-group-label">Bitiş Tarihi</span>
                                <div className="form__form-group-field">
                                    <Field
                                        className=""
                                        name="financialPeriodEndDate"
                                        required
                                        component={renderDatePickerField}
                                        onChange={()=>{}}
                                    />

                                </div>
                            </div>
                        }




                        <div className="form__form-group-date-cvc">
                            <div className="form__form-group form__form-group-date">
                                <span className="form__form-group-label">Hesap Dönemi Seçiniz / AY</span>
                                <div className="form__form-group-field">
                                    <Field name="selectMonth" required className="form__form-group-input-wrap--error-above " component={renderSelectField} type="text"
                                        options={[
                                            { value: 'zero', label: 'Lütfen Ay Seçiniz' },
                                            { value: '1', label: 'Ocak' },
                                            { value: '2', label: 'Şubat' },
                                            { value: '3', label: 'Mart' },
                                            { value: '4', label: 'Nisan' },
                                            { value: '5', label: 'Mayıs' },
                                            { value: '6', label: 'Haziran' },
                                            { value: '7', label: 'Temmuz' },
                                            { value: '8', label: 'Ağustos' },
                                            { value: '9', label: 'Eylül' },
                                            { value: '10', label: 'Ekim' },
                                            { value: '11', label: 'Kasım' },
                                            { value: '12', label: 'Aralık' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group form__form-group-cvc">
                                <span className="form__form-group-label">Hesap Dönemi Seçiniz / YIL</span>
                                <div className="form__form-group-field">
                                    <Field name="selectYear" required className="form__form-group-input-wrap--error-above " component={renderSelectField} type="text"
                                        options={optionYears}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Raporları yüklemek istediğiniz dosya formatını seçiniz</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="fileType"
                                    component={renderRadioButtonField}
                                    label="zip"
                                    radioValue="zip"
                                    defaultChecked
                                />
                                <Field
                                    name="fileType"
                                    component={renderRadioButtonField}
                                    label="xml"
                                    radioValue="xml"

                                />
                            </div>
                        </div>
                        <ButtonToolbar className="upload__toolbar">
                            <Button color="primary" type="submit" className="">Devam</Button>
                        </ButtonToolbar>
                    </form>}
                {!isOpen &&
                    <div className="text-center">
                        <Alert color="warning" className="alert--bordered" icon>
                            <p><span className="bold-text">Dikkat!</span> Öncelikle Paket Satın Almalısınız</p>
                            <a title="Paket satın alın" href="/Anasayfa">Paket Satın Alın</a>
                        </Alert>
                        {/* <h5 className="bold-text">Paket Satın Al</h5>
                    <h5 className="subhead">Aşağıdaki linkten önce 
                        <span className="red-text"> paket </span>satın almalısınız.
                    </h5> */}
                    </div>
                }
            </>
        )
    }
};

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(withTranslation('common')(AnalysisFormOne));