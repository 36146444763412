import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Result from '../../../components/user/MyProducts';

const ListProduct = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Mevcut Paketlerim</h3>
        <h3 className="page-subhead subhead">Satın almış olduğunuz ürünleri bu sayfada görebilirsiniz.
        </h3>
      </Col>
    </Row>
    <Row>
      <Result />
    </Row>
  </Container>
);


export default ListProduct
