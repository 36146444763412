export const handle401 = (data) => {
    if (data?.response?.status === 401){
        localStorage.clear();
        window.location.href = "/Login";
    }
};

export const getUrl = () => {
    if(process.env.NODE_ENV === 'production') {
        return "https://www.ataxdenetim.com:9090/"
    } else {
        return "http://localhost:9090/";
    }
};