import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import axiosNew from '../../data/common/getHeader';
import BuyFormFree from '../../components/product/BuyFormFree';
import Modal from '../../components/modal/Modal';
import {handle401} from "../../data/common/LedgerUtils";

class BuyProductFree extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      mColor: 'primary',
      message: '',
      mTitle: '',
      pn: ''
    }
    this.sendInvoiceForm = this.sendInvoiceForm.bind(this);
  }

   setShow =(event)=> {
        this.setState({showModal: event})
  }

  getPacketName = () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let id = params.get('paket');
      let pName;
      switch (parseInt(id)) {
        case 1:
          pName = "GEÇMİŞ_YIL-2019";
          break;
        case 2:
          pName = "GEÇMİŞ_YIL-2020";
          break;
        case 3:
          pName = "GEÇMİŞ_YIL-2021";
          break;
        case 4:
          pName = "2021-PAKET-10LU";
          break;
        case 5:
          pName = "2021-PAKET-20LI";
          break;
        case 6:
          pName = "CARİ YIL-2022";
          break;
        case 7:
          pName = "2022-PAKET-10LU";
          break;
        case 8:
          pName = "2022-PAKET-20LI";
          break;
        case 9:
          pName = "2021-2022";
          break;
        case 10:
          pName = "2021-2022-PAKET-10LU";
          break;
        case 11:
          pName = "2021-2022-PAKET-20LI";
          break;
        case 12:
          pName = "2021-PAKET-21-50";
          break;
        case 13:
          pName = "2022-PAKET-21-50";
          break;
        case 14:
          pName = "2021-2022-PAKET-21-50";
          break;  

          case 15:
            pName = "CARİ YIL-2023";
            break;
          case 16:
            pName = "2023-PAKET-10LU";
            break;
          case 17:
            pName = "2023-PAKET-20LI";
            break;            
          case 18:
            pName = "2023-PAKET-21-50";
            break;

          case 19:
            pName = "2022-2023";
            break;
          case 20:
            pName = "2022-2023-PAKET-10LU";
            break;
          case 21:
            pName = "2022-2023-PAKET-20LI";
            break;
          case 22:
            pName = "2022-2023-PAKET-21-50";
            break;

          case 23:
              pName = "GEÇMİŞ_YIL-2022";
              break;       
          case 24:
            pName = "CARİ YIL-2024";
            break;
          case 25:
            pName = "2024-PAKET-10LU";
            break;
          case 26:
            pName = "2024-PAKET-20LI";
            break;            
          case 27:
            pName = "2024-PAKET-21-50";
            break;

          case 28:
            pName = "2023-2024";
            break;
          case 29:
            pName = "2023-2024-PAKET-10LU";
            break;
          case 30:
            pName = "2023-2024-PAKET-20LI";
            break;
          case 31:
            pName = "2023-2024-PAKET-21-50";
            break;


        default:
          pName = "";
          break;
      }
    return pName;
    //this.setState({pn: name});
    
  }

  getKota = (kota, paket) => {
      switch (paket) {
          case "GEÇMİŞ_YIL-2019":
              return 1;
          case "GEÇMİŞ_YIL-2020":
              return 1;
          case "GEÇMİŞ_YIL-2021":
              return 1;
          case "GEÇMİŞ_YIL-2022":
              return 1;
          case "2021-PAKET-10LU":
              return 10;
          case "2021-PAKET-20LI":
              return 20;
          case "2021-PAKET-21-50":
              return 50;
          case "CARİ YIL-2022":
             return kota;
          case "2022-PAKET-10LU":
             return 10;
          case "2022-PAKET-20LI":
             return 20;
          case "2022-PAKET-21-50":
              return 50;
          case "2021-2022":
             return kota;
          case "2021-2022-PAKET-10LU":
             return 10;
          case "2021-2022-PAKET-20LI":
             return 20;
          case "2021-2022-PAKET-21-50":
              return 50;

              case "CARİ YIL-2023":
                return kota;
             case "2023-PAKET-10LU":
                return 10;
             case "2023-PAKET-20LI":
                return 20;
             case "2023-PAKET-21-50":
                 return 50;
             case "2022-2023":
                return kota;
             case "2022-2023-PAKET-10LU":
                return 10;
             case "2022-2023-PAKET-20LI":
                return 20;
             case "2022-2023-PAKET-21-50":
                 return 50;

                 case "CARİ YIL-2024":
                  return kota;
               case "2024-PAKET-10LU":
                  return 10;
               case "2024-PAKET-20LI":
                  return 20;
               case "2024-PAKET-21-50":
                   return 50;
               case "2023-2024":
                  return kota;
               case "2023-2024-PAKET-10LU":
                  return 10;
               case "2023-2024-PAKET-20LI":
                  return 20;
               case "2023-2024-PAKET-21-50":
                   return 50;
          default:
             return 0;
      }
  }

  sendInvoiceForm = async values => {
    let pl = this.getPacketName();
    let kt = this.getKota(values.kota,pl);
    const body = {
      packetLevel: pl,
      invoiceTitle: values.c_company,
      invoiceIdentity: values.c_vkn,
      invoiceAddress: values.c_address,
      invoiceProvince: values.selectCity?.label,
      invoicePhone: values.c_phone,
      packetLimit: kt,
      invoiceEmail: values.c_email,
      referenceCode: values.c_refcode
    }

   await axiosNew.post('subscribe',body)
      .then((user) => {
        let errMessage, sColor, title = "";
        if (user.status === 200) {
          this.setState({ showModal: false });
          if(user.data.message === "SUCCESS")
          {
                errMessage = "İşlem başarılı";
                sColor = "success";
                title = "Kayıt İşlemi Başarılı";

                localStorage.setItem(user.data.id + "-aggReports",user.data.reports);
                window.location.href = "/OnaySayfasi?subId=" + user.data.id;
                return true;
          }
          else{
              errMessage = "Hata Oluştu! \nHata Mesajı: " + user.data;
              sColor = "danger";
              title = "Kayıt İşlemi Başarısız";
              this.setState({ message: errMessage, showModal: true, mColor: sColor, mTitle: title });
          }
        }
        else{
          errMessage = "Hata Oluştu! \nHata Mesajı: " + user.data;
          sColor = "danger";
          title = "Kayıt İşlemi Başarısız";
          this.setState({ message: errMessage, showModal: true, mColor: sColor, mTitle: title });

      }
      })
      .catch(error => {
        let errMessage = "";
        if (error.response) {
            errMessage = "Hata Kodu: " + error.response.status + " Hata Mesajı: " + error.response.data;
        } else if (error.request) {
            errMessage = "Sistem Hatası: Lütfen daha sonra tekrar deneyiniz.";
        } else {
            errMessage = "Hata Mesajı: " + error.message;
        }
        alert("Bir hata oluştu, lütfen sistem yönecisiyle irtibata geçin.");
        handle401(error);
      });
      
  }


  render() {
    const { error } = this.state;
    return (
      <React.Fragment>
        { this.state.showModal &&
          <Modal setShow={this.setShow} color={this.state.mColor} toggle={this.state.showModal} open={this.state.showModal} header message={this.state.message} title={this.state.mTitle} />}
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Fatura Bilgilerinizi Giriniz</h3>

            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h4 className="page-title">Fatura Bilgileri</h4>
                  </div>
                  <div className="payment">
                    <BuyFormFree onSubmit={this.sendInvoiceForm} PSN={this.getPacketName}/>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}
BuyProductFree.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
export default withRouter(BuyProductFree);
