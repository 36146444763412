import React from 'react';
import { Col, Container, Row, Card, CardBody, CardHeader } from 'reactstrap';
import YoutubeEmbed from "./YoutubeEmbed";


const AtaxVideo = () => (


  <Container>
    <Row>
      <Col md={6} lg={6}>
        <Card>
            <CardHeader><p className="font-weight-bold text-lg-left">A-tax Yazılım</p></CardHeader>
          <CardBody className="user-form">

              <YoutubeEmbed embedId="ROyxFCShG7o" titleY="A-tax Yazılım" />
          </CardBody>
        </Card>
      </Col>
        <Col md={6} lg={6}>
            <Card>
                <CardHeader><p className="font-weight-bold text-lg-left">A-tax Tanıtım</p></CardHeader>
                <CardBody className="user-form">

                    <YoutubeEmbed embedId="PoCShsLiICA" titleY="A-tax Tanıtım" />
                </CardBody>
            </Card>
        </Col>
        <Col md={6} lg={6}>
            <Card>
                <CardHeader><p className="font-weight-bold text-lg-left">A-tax Analiz Raporlama İşlemleri</p></CardHeader>
                <CardBody className="user-form">

                    <YoutubeEmbed embedId="hML6VNrv54I" titleY="A-tax Analiz Raporlama İşlemleri" />
                </CardBody>
            </Card>
        </Col>
        <Col md={6} lg={6}>
            <Card>
                <CardHeader><p className="font-weight-bold text-lg-left">A-tax Parola Değişikliği</p></CardHeader>
                <CardBody className="user-form">

                    <YoutubeEmbed embedId="X5k4ucINIf8" titleY="A-tax Parola Değişikliği" />
                </CardBody>
            </Card>
        </Col>
        <Col md={6} lg={6}>
            <Card>
                <CardHeader><p className="font-weight-bold text-lg-left">A-tax Üyelik</p></CardHeader>
                <CardBody className="user-form">

                    <YoutubeEmbed embedId="1xKR7PGir-g" titleY="A-tax Üyelik" />
                </CardBody>
            </Card>
        </Col>
        <Col md={6} lg={6}>
            <Card>
                <CardHeader><p className="font-weight-bold text-lg-left">A-tax Firma Ekleme</p></CardHeader>
                <CardBody className="user-form">

                    <YoutubeEmbed embedId="rpT2QCi1TtA" titleY="A-tex Firma Ekleme" />
                </CardBody>
            </Card>
        </Col>
        {/* <Col md={6} lg={6}>
            <Card>
                <CardHeader><p className="font-weight-bold text-lg-left">A-tax Satın Alma</p></CardHeader>
                <CardBody className="user-form">
                    // Deneme
                    <YoutubeEmbed embedId="-kYxp8dgThk" titleY="A-tex Satın Alma" />
                </CardBody>
            </Card>
        </Col> */}
    </Row>
  </Container>
);

export default AtaxVideo;
