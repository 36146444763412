import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';


export default function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, title, content, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);
  
    React.useEffect(() => {
      if (!open) {
        setValue(valueProp);
      }
    }, [valueProp, open]);
  
    const handleEntering = () => {
      if (radioGroupRef.current != null) {
        radioGroupRef.current.focus();
      }
    };
  
    const handleCancel = () => {
      console.log("confirmm cancelled");      
      onClose();
    };
  
    const handleOk = () => {
      console.log("confirmm confirmed, value: " + value);
      onClose(value);
    };
  
  
    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
           {content}
        </DialogContent>
        <DialogActions>
          <Button className="btn btn-primary btn-sm" autoFocus onClick={handleCancel}>
            Vazgeç
          </Button>
          <Button  className="btn btn-danger btn-sm" onClick={handleOk}>Devam</Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,

  };