import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';


const GizlilikPolitikasiForm = () => (


  <Container>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form" style={{padding:"20px"}}>
            <div className="search-result">
                         <h3 className="page-title">HAKKIMIZDA</h3>


            </div>
            <div className="search-result">

                                      <p className="search-result__preview" >
                                            Atax Yazılım ve Dan. A.Ş., 2020 yılında akademisyen, mühendis ve meslek mensuplarından oluşan tecrübeli bir ekip tarafından kurulmuştur. Bilgi ve birikimlerini elektronik bir denetim yazılımı geliştirerek ülkenin hizmetine sunmak isteyen ekibimiz, bu amaçla Gazi Üniversitesi’nin (Ankara) Gölbaşı Yerleşkesinde bulunan Gazi Teknopark’ta faaliyete başlamıştır.
                                      </p>

            </div>

             <div className="search-result">

                                                  <p className="search-result__preview" >
                                                  Gazi Teknopark bünyesinde yürütülmekte olan A-Tax (Auditing Tax) Elektronik Denetim Programı projesiyle, vergi, muhasebe ve diğer mali konularda işletmelere denetim hizmeti sunacak bir yazılımın geliştirilmesi hedeflenmektedir. Hazırlanan Programla, herhangi bir dışsal müdahale olmadan doğrudan e-Defter dosyaları üzerinden çalışacak, tüm denetim süreçlerini elektronik ortama alarak zaman, mekân ve maliyetlerde tasarruf sağlayacak kapsamlı etkin bir denetim yazılımı kullanıma sunulacaktır. Projeye göre, A-TAX’ın ilk defa 2021 yılı bahar aylarında kullanılmaya başlanması amaçlanmaktadır.
                                                  </p>

                        </div>

              <div className="search-result">

                                                               <p className="search-result__preview" >
                                                               Kişi ve kurumların denetiminin, en iyi kendileri tarafından gerçekleştirebileceği inancıyla, “The Best Audit is Self Audit – En İyi Denetim Öz Denetim” cümlesi slogan olarak seçilmiştir. Sloganımıza uygun olarak hazırlanan A-TAX, tamamen kullanıcı dostu bir yaklaşımla tasarlanmış olup uzman personele ihtiyaç duyulmadan herhangi bir işletme çalışanı tarafından çalıştırılabilen, kullanımı son derece kolay ve hizmet (rapor) sunumu çok hızlı bir yazılımdır.
                                                               </p>

              </div>
               <div className="search-result">

                                                                               <p className="search-result__preview" >
Proje, muhtemel güvenlik endişelerini giderecek şekilde hazırlanmıştır.
Şirketimiz geliştirdiği ve geliştireceği ürünlerle, alanında öncü kuruluşlardan biri olmayı hedeflemektedir. Elektronik ortamdaki hizmetlerimiz, önce ülkemize ardından da tüm dünyaya zaman ve mekân sınırlaması olmaksızın sunulabilecektir

                                                                               </p>

               </div>
               <div className="search-result">

                                                                                              <p className="search-result__preview" >
               İlkemiz, sorunun değil çözümün parçası olmaktır. Ürün geliştirmede işlevsellik ve kalite, hizmet sunumunda ise müşteri memnuniyeti önceliğimizdir.
                                                                                              </p>

                              </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default GizlilikPolitikasiForm;
