import React, {useState} from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import FormPass from '../../components/user/ChangePass';
//import showResults from '../Show';
import axiosNew from '../../data/common/getHeader';
import Modal from '../../components/modal/Modal';
import {handle401} from "../../data/common/LedgerUtils";

const userInfo = JSON.parse(localStorage.getItem("userData"));

const ChangePass = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [closed, setClosed] = useState();
  const [forced, setForced] = useState(false);
  const showResults = values => {
    const body = {
      oldPassword: values.password3,
      newPassword: values.password,
      newPasswordAgain: values.password2
    }
    axiosNew.post("changePassword", body).then(res => {
      if (res.status === 200) {
        if(res.data === "SUCCESS"){
          setShow(true);
          setMessage('Şifre Değiştirme Başarılı.');
          setTitle('İşlem Başarılı');
          setColor('success');
          setLink('/SifremiDegistir');
          setClosed(true);
          setForced(true);
        }
        else {
          setShow(true);
          setMessage('Hata Oluştu: ' + res.data);
          setTitle('İşlem Başarısız');
          setColor('success');
          setLink('');
          //setClosed(false);
          //setForced(false);
        }
        
      }
    }).catch(err => {
      setShow(true);
      setMessage('Bir hata oluştu, lütfen sistem yöneticisiyle görüşün.');
      setTitle('Hata Oluştu');
      setColor('danger');
      setLink('');
      setForced(false);
      setClosed(false);
      handle401(err);
    });
  }

  return (
    <>
      {show &&
        <Modal setShow={setShow} color={color} toggle={show} open={show} header message={message} title={title} closedLink={link} isClosed={closed} isForced={forced} />
      }
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Şifrenizi Değiştirin</h3>
            <h3 className="page-subhead subhead">{userInfo.mail}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody className="">
                <FormPass onSubmit={showResults} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ChangePass
