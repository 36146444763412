import React, { Component,useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import LogInForm from '../../../components/user/LogInForm';
import axiosNew from '../../../data/common/getHeader';
import Modal from '../../../components/modal/Modal';
import {handle401} from "../../../data/common/LedgerUtils";


const logo = `${process.env.PUBLIC_URL}/img/clogo.png`;

class Login extends Component {
  constructor() {
    super();

    this.state = {
      isSignUp: false,
      showModal: false,
      mColor: 'primary',
      message: '',
      mTitle: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setShow =(event)=> {
    this.setState({showModal: event})
  }

  handleSubmit = values => {
    var currentUser = JSON.parse(localStorage.getItem("userData"));
    if (!currentUser){
        axiosNew.post("login", {
              email: values.email,
              password: values.password
            })
              .then(user => {
                let errMessage, sColor, title = "";
                if (user.status === 200) {
                  this.setState({ showModal: false });
                  let sm = true;
                  if (user.data.message === "SUCCESS") {
                    var userData = {
                      mail: user.data.email,
                      name: user.data.username,
                      token: user.data.token,
                      status: user.data.paid,
                      last: user.data.admin,
                      isController: user.data.controller,
                      role: user.data?.roles[0],
                      dealer: user.data.dealer
                    };

                    localStorage.clear();
                    localStorage.setItem("userData", JSON.stringify(userData));
                    sm = false;
                    window.location.href = "/Anasayfa";
                  }
                  else {
                    errMessage = "Hata Oluştu: " + user.data;
                    sColor = "danger";
                    title = "Sistem Yöneticine Başvurun";
                  }
                  this.setState({ message: errMessage, showModal: sm, mColor: sColor, mTitle: title });

                }
              })
              .catch(function (error) {
                let errMessage = ""; let sColor = "danger"; let title = "Hata Oluştu!";
                if (error.response) {
                  errMessage = "Hata Kodu: " + error.response.status + " Hata Mesajı: " + error.response.data;
                } else if (error.request) {
                  errMessage = "Bağlantı hatası oldu, lütfen daha sonra tekrar deneyin.";
                } else {
                  errMessage = "Hata Mesajı: " + error.message;
                }
                alert(errMessage);
                handle401(error);
              });
    }
    else {
        alert("Zaten giriş yaptınız!");
        window.location.href = "/Anasayfa";
    }
  }

  render() {
    const { error, showModal, mColor, message, mTitle } = this.state;
    return (
      <div className="account">
         { showModal &&
          <Modal setShow={this.setShow} color={mColor} toggle={showModal} open={showModal} header message={message} title={mTitle} />}
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title"> 
                <img src={logo} alt="Atax Denetim" className="alogo"/>
              </h3>
              <h4 className="account__subhead subhead">En iyi denetim öz denetim</h4>
            </div>
            <LogInForm onSubmit={this.handleSubmit} errorMessage={error} />
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default withRouter(Login);
