import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';

const AtaxSummaryForm = () => (
  <Container>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form" style={{ padding: '20px' }}>
            <div className="search-result">
              <h3 className="page-title">A-TAX KULLANIM KILAVUZU - ÖZET</h3>
              <br></br>
              <p className="search-result__preview">
                A-TAX a ilişkin tanıtım bilgileri{' '}
                <strong>www.ataxyazilim.com </strong>internet adresinde yer
                almaktadır
              </p>
              <p className="search-result__preview">
                A-TAX a erişim, ya <strong>www.ataxyazilim.com</strong>{' '}
                adresindeki <strong>“Analize Giriş” </strong>butonu aracılığıyla
                ya da doğrudan tarayıcının adres kısmına{' '}
                <strong> www.ataxdenetim.com </strong>yazılarak sağlanabilir.
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">1. Sisteme Üye Olunması</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                A-TAX ı kullanabilmek için öncelikle üye olunmalıdır.
              </p>
              <p className="search-result__preview">
                <strong>www.ataxdenetim.com</strong> adresine gidilerek{' '}
                <strong>“Yeni Hesap Oluştur”</strong> butonu aracılığıyla, mail
                ve şifre girilmelidir.
              </p>
              <p className="search-result__preview">
                Mail adresine gelen doğrulama mesajı onaylandığında üyelik
                gerçekleşecektir.
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">
                2. Paket/Ürün Satın Alınması
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                Üye olarak giriş yapıldıktan sonra{' '}
                <strong>“Yeni Paket Al”</strong> butonuna basılmalıdır.
              </p>
              <p className="search-result__preview">
                Fiyat tarifesinde alınacak paketin altındaki{' '}
                <strong>“Satın Al”</strong> butonuna basılmalıdır.
              </p>
              <p className="search-result__preview">
                Açılan sayfada analizi yapılacak firma sayısı kadar kota
                seçilmelidir.
              </p>
              <p className="search-result__preview">
                Paketi satın alan firmaya ait unvan, vergi kimlik numarası (VKN
                no), adres, mail ve telefon bilgileri girilerek “Kaydet”
                butonuna basılmalıdır..
              </p>
              <p className="search-result__preview">
                Açılan sayfadaki belgeler onaylanarak{' '}
                <strong>“Satın Al” </strong>butonuna basılmalıdır.
              </p>
              <p className="search-result__preview">
                Kredi kartı bilgileri girilerek ödeme işlemi tamamlandığında
                satın alma gerçekleştirilmiş olacaktır.
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">3. Firma Eklenmesi</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                Satın alınan paketteki kota kadar firma sisteme girilerek analiz
                yapılabilir.{' '}
              </p>
              <p className="search-result__preview">
                Firma bilgisini girmek için <strong>“Firma Ekle”</strong>{' '}
                butonuna basılmalıdır.
              </p>
              <p className="search-result__preview">
                Analize tabi firmanın unvanı, VKN si ve analizi gerçekleştirenin
                görevi girilerek <strong>“Kaydet” </strong>butonuna basıldığında
                firma sisteme eklenmiş olacaktır.
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">
                4. Analizin Başlatılması ve Rapor Alınması
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                Menüden <strong>“Analizi Başlat”</strong> butonuna basılarak
                analiz sayfasına ulaşılabilir.
              </p>
              <p className="search-result__preview">
                Açılan sayfada önce hangi paketin kullanılacağı seçilmelidir.
              </p>
              <p className="search-result__preview">
                Daha sonra analizi yapılacak firma ve analiz dönemi (en son ay)
                seçilmelidir.
              </p>
              <p className="search-result__preview">
                Yüklenmek istenilen dosya formayı (zip veya xml) seçilmelidir.{' '}
                <strong>“zip”</strong> formatının ve <strong>“zip”</strong>{' '}
                eklentili dosyaların seçilmesi yükleme hızını artıracaktır.
              </p>
              <p className="search-result__preview">
                Bu seçim yapılırken analiz dönemi ve önceki aylara ait tüm
                Yevmiye Defter parçaları seçilmelidir.
              </p>
              <p className="search-result__preview">
                <strong>“Devam” </strong>butonuna basıldığında{' '}
                <strong>“e-Defter Analizleri”</strong> başlığı altında e-Defter
                yükleme ekranı gelecektir.
              </p>
              <p className="search-result__preview">
                Açılan ekranın ortasına tıklanarak analize tabi{' '}
                <strong>YEVMİYE DEFTERLERİ</strong>yüklenebilir.{' '}
              </p>
              <p className="search-result__preview">
                -----> Bu aşamada yüklenecek (Y) defterler daha önce seçilen{' '}
                <strong>“zip”</strong> veya <strong>“xml”</strong> formatıyla
                aynı formatta olmalıdır. Her aya ait yevmiye defteri ayrı ayrı
                seçilerek yüklenir.
              </p>
              <p className="search-result__preview">
                -----> <strong> DAHA HIZLI </strong>dosya seçimi için, ayrı bir
                pencere açılarak defter dosyalarının olduğu klasör (Ör:2020)
                bulunur ve mouse la sürüklenerek dpsya yükleme alanına bırakır.
                Bu durumda sistem klasörden sadece gerekli (Y) dosyalarını
                seçecektir.
              </p>
              <p className="search-result__preview">
                {' '}
                <strong>Önce</strong> e-Defter dosyalarının doğru seçilip
                seçilmediği kontrol edilmeli sonra{' '}
                <strong>“Defter Tarat”</strong> butonuna basılmalıdır.
              </p>
              <p className="search-result__preview">
                -----> Yükleme işlemi tamamlanınca (yatay bar göstergesi 12/12,
                6/6 vb. olunca) <strong>“Analizi Başlat”</strong> butonuna
                basılarak analiz ve raporlama işlemi başlatılmalıdır.
              </p>
              <p className="search-result__preview">
                -----><strong> “Analizi Başlat” </strong>butonuna basıldıktan
                sonra işlemin bitmesinin beklenmesine gerek yoktur, raporlara
                (analiz raporu, mizan) daha sonra{' '}
                <strong>“Önceki Analizlerim” </strong>sayfasından ulaşabilir.{' '}
              </p>
              <p className="search-result__preview">
                -----> Yükleme işleminde gecikme yaşanması halinde öncelikle
                bağlantı hızı, dosyaların büyüklüğü ve doğru dosyaların seçilip
                seçilmediği kontrol edilmelidir.
              </p>
              <p className="search-result__preview">
                12 aylık (yıllık) yapılan analizlerin doğru sonuç vermesi ve
                dönem sonu işlemlerinin doğruluğunun kontrolü için, yüklenecek
                12 aylık yevmiye defterlerinde dönem sonu işlemleri ve kapanış
                kaydının yapılmış olması gerekmektedir.
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">5. Özelleştirilebilir Rapor</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
               Analiz sonunda PDF ve WORD formatında hazırlanan raporu özelleştirilebilir şekilde de sunuyoruz. 
               Bu sayede çok uzun ve detaylı rapor yerine sadece incelemek
               istediğiniz kısmını belirleyip çıktısını alabilirsiniz. Çıktı almak yerine PDF olarak da kaydedebilirsiniz. 
               Özelleştirilebilir rapor, analiz sonunda yönlendilirilen sonuç ekranında veya menüdeki 
               "Önceki Analizlerim" sayfasında her analize ait raporlarla birlikte görüntülenebilir.
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">6. Önceki Analizler</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                Daha önce yapılmış olan analizlerin sonuçlarına{' '}
                <strong>“Önceki Analizlerim”</strong> sayfasından ulaşılabilir.
                Buradaki liste en yeni analiz sonucu en üstte olacak şekilde
                sıralanmıştır. Bu sayfadaki{' '}
                <strong>“Raporları Görüntüle”</strong> butonundan eski raporlar
                tekrar indirilebilir.{' '}
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">7. Parola Değişikliğir</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                Kullanıcı sol menüdeki <strong>“Üyelik Bilgilerim”</strong>{' '}
                linkini kullanarak mevcut üyelik bilgilerini görüntüleyebilir ve
                şifre değişikliği yapabilir.{' '}
              </p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AtaxSummaryForm;
