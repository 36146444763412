import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Result from '../../../components/user/MyCompanies';

const ListCompanies = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Firmalarım</h3>
        <h3 className="page-subhead subhead">Satın almış olduğunuz pakete eklenmiş firmaları bu sayfada görebilirsiniz.
        </h3>
      </Col>
    </Row>
    <Row>
      <Result />
    </Row>
  </Container>
);


export default ListCompanies