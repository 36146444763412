import React, {useState, useEffect,useContext} from 'react';
import { withTranslation } from 'react-i18next';
//import {Card, CardBody, Col, Table,} from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axiosNew from '../../data/common/getHeader';
import {Card, CardBody, Col, Table, Badge, Button} from 'reactstrap';
import checkUser from "../../data/common/getHeader";
//import {jobIdContext} from '../../shared/contextApi/JobIdContextApi';

import {handle401} from '../../data/common/LedgerUtils'

const MyProducts = () => 
{
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);
  //const {setJobId}= useContext(jobIdContext)
  useEffect(() => {

    if(status){
      setPM('Henüz hiç paket almadınız');
      axiosNew.get("getSubscriptions").then(res => {
        if (res.status === 200) {
          setStatus(false);
          setLoading(false);
          setSub(res.data);
          if(res.data?.length > 0) 
            setPM('Mevcut paketleriniz aşağıda listeniyor')
        }
      }).catch(
          err => {
            handle401(err);
          }
      );
    }
  });
const goCompanies = id => {
  window.location.href = "/MyCompanies?subId=" + id;
}
const addCompanies = id => {
  window.location.href = "/YeniFirmaEkle?subId=" + id;
}
return(
  <> {loading ? (
    <div className="todo__panel-refresh">
      <LoadingIcon />
    </div>
  ) : (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">PAKETLERİM</h5>
          <h5 className="subhead">{pm}
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Ürün Adı</th>
              <th>Analize Başlanabilecek Tarih</th>
              <th>En Son Analiz Yapılabilecek Tarih</th>
              <th>Kota</th>
              <th>Kalan</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>

          {sub.map((d,i) => (
            <tr>
              <td>{i + 1}</td>
              <td key={i + 1}>{d.description}</td>

              <td>{d.analysisStartDate}</td>
              <td>{d.analysisEndDate} {d.expired ? "(Süresi dolmuş)" : ""}</td>
              <td>{d.quota}</td>
              <td>{d.remaining}</td>
              <td><Button className="btn btn-primary btn-sm" onClick={() => goCompanies(d.subscriptionId)}>Firmalarım</Button></td>
              <td><Button className="btn btn-success btn-sm" disabled = {d.remaining === 0 || d.expired} onClick={() => addCompanies(d.subscriptionId)}>Firma Ekle</Button></td>
            </tr>
          ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>)}
  </>
);
}
export default withTranslation('common')(MyProducts);
