import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import ResetPasswordForm from '../../../components/user/ResetPass';
import axiosNew from '../../../data/common/getHeaderWOToken';
import Modal from '../../../components/modal/Modal';

const ResetPassword = (props) => {

    const [show, setShow] = useState(false);
    const [mColor, setMColor] = useState("warning");
    const [message, setMessage] = useState("");
    const [mTitle, setMTitle] = useState("");
    const onSubmit = (item) => {
        const body = {
           email: item.email
        }
        axiosNew.post('forgetPassword', body).then(res =>{
            if(res.status === 200){
                if(res.data === "SUCCESS"){
                    setShow(true);
                    setMColor("success");
                    setMessage("Parola değiştirme linki e-posta adresinize gönderildi (E-mail bazı durumlarda spam kutusuna düşebilmektedir.)");
                    setMTitle("İşlem Başarılı");
                }else{
                    setShow(true);
                    setMColor("warning");
                    setMessage("İşlem sırasında bir hata oluştu :" + res.data);
                    setMTitle("İşlem Başarısız");
                }
            }
        }).catch(err =>{
            setShow(true);
            setMColor("danger");
            setMessage("İşlem sırasında bir hata oluştu, lütfen tekrar deneyiniz.");
            setMTitle("İşlem Başarısız");
        });
    };
    const logo = `${process.env.PUBLIC_URL}/img/clogo.png`;
    return (
        <>
         { show &&
          <Modal setShow={setShow} color={mColor} toggle={show} open={show} header message={message} title={mTitle} />}
        <div className="account account--not-photo">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">
                            <img src={logo} alt="Atax Denetim" className="alogo"/>
                        </h3>
                        <h4 className="account__subhead subhead">Şifremi değiştir</h4>
                    </div>
                    <ResetPasswordForm {...props} onSubmit={onSubmit} form="reset_password_form" />
                    <div className="account__have-account">
                        <p>Tanımlı bir hesabınız var mı? <NavLink to="/login">Giriş Yap</NavLink></p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};


export default ResetPassword;
