/* eslint-disable */
const validate = (values) => {
  
  const errors = {};
  if(values.selectCompany?.label === undefined)
  {
    errors.selectCompany = 'Lütfen bir şirket seçiniz';
  }
  if (!values.selectCompany) {
    errors.selectCompany = 'Lütfen bir şirket seçiniz';
  }
  if (!values.selectMonth) {
    errors.selectMonth = 'Lütfen bir ay seçin';
  }else if(dateControl(values)){
    errors.selectMonth = 'Bugünün tarihinden daha ileri bir tarih seçemezsiniz';
  }
  if (!values.selectCity) {
    errors.selectCity = 'Lütfen bir şehir seçin';
  }
  if (!values.selectTaxAd) {
    errors.selectTaxAd = 'Lütfen bir vergi dairesi seçin';
  }
  if (!values.selectYear) {
    errors.selectYear = 'Lütfen bir yıl seçin';
  }else if(dateControl(values)){
    errors.selectYear = 'Bugünün tarihinden daha ileri bir tarih seçemezsiniz';
  }
  if (!values.c_address) {
    errors.c_address = 'Lütfen firmanın adresini giriniz';
  }
  if (!values.c_company) {
    errors.c_company = 'Lütfen firmanın adını giriniz';
  }
  if (!values.c_contract) {
    errors.c_contract = 'Lütfen şözleşme numarasını giriniz';
  }
  if (!values.c_date) {
    errors.c_date = 'Lütfen şözleşme tarihini giriniz';
  }
  if (!values.selectMission) {
    errors.selectMission = 'Lütfen görevinizi seçiniz';
  }
  if (!values.c_nace) {
    errors.c_nace = 'Lütfen NACE kodunuzu giriniz';
  }else if(values.c_nace?.length !== 6){
    errors.c_nace = 'Lütfen geçerli NACE kodunuzu giriniz';
  }else if(isNaN(values.c_nace)){
    errors.c_nace = 'Lütfen sadece sayısal değer giriniz';
  }
  if (!values.c_phone) {
    errors.c_phone = 'Lütfen telefon numaranızı giriniz';
  }
  if (!values.c_vkn) {
    errors.c_vkn = 'Lütfen VKN/TC numaranızı giriniz';
  }else if(values.c_vkn?.length < 10 || values.c_vkn?.length > 11)
  {
    errors.c_vkn = 'Lütfen VKN/TC numaranızı kontrol ediniz';
  }else if(isNaN(values.c_vkn)){
    errors.c_vkn = 'Lütfen sadece sayısal değer giriniz';
  }
  if(!values.date)
  {
    errors.date = "Lütfen geçerli bir tarih giriniz"
  }
  if (values.financialPeriodType!=="GENEL" && !values.financialPeriodStartDate) {
    errors.financialPeriodStartDate = 'Lütfen hesap dönemi başlangıç tarihi seçiniz';
  }
  if (values.financialPeriodType!=="GENEL" && !values.financialPeriodEndDate) {
    errors.financialPeriodEndDate = 'Lütfen hesap dönemi bitiş tarihi seçiniz';
  }
  return errors;
};

function dateControl(values){
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  let status = false;
  if(values.selectYear && values.selectMonth){
    if(parseInt(values.selectYear.value) === year){
        status = parseInt(values.selectMonth.value) <= month ? false : true;}
  }
  
  return status
}

export default validate;
