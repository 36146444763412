import React from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';

const paperPlane = `${process.env.PUBLIC_URL}/img/cards/004-paper-plane.svg`;

const CardBasicFree = ({cardData}) => {
return( 
  <Col md={6} xl={3} sm={12}>
    <Card>
      <CardBody className="pricing-card pricing-card--primary">
        <div className="pricing-card__body">
          <img className="pricing-card__img" src={paperPlane} alt="" />
          <h2 className="pricing-card__plan">{cardData?.packetName}</h2>
          <hr />
          <p className="pricing-card__feature">{cardData?.packetDescription}</p>
          <hr />
          {/*cardData?.priceList?.length > 0 &&
            cardData.priceList.map((i) => 
              <div className="mt-3 cardPrice">
                <p className="cross">{i.listPrice} TL</p>
                <p>Firma başına kampanyalı fiyat.</p>
                                <p className="pricing-card__price">{i.visibleSellPrice}<span> TL</span></p>
                                <p>(Fiyatlara KDV dahil değildir)</p>
              </div>
            )*/

          <div className="mt-3 cardPrice">
            <p><br></br></p>
            <p><br></br></p>
            <p><h4>Tanıtıma özel ücretsiz deneme fırsatı.</h4></p>
            <p><br></br></p>
            <p><br></br></p>
          </div>
          }

          <a href={"/AbonelikFaturaBilgileri?paket=" + cardData?.paket}>
            <Button className="pricing-card__button" color="success">Ücretsiz Demo</Button>
          </a>
        </div>
      </CardBody>
    </Card>
  </Col>
)
}

export default CardBasicFree;
