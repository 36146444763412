import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

const DatePickerField = ({ onChange}) => {
  const [startDate, setStartDate] = useState(null);
  const handleChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
  const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz']

  const locale = {
    localize: {
      month: n => months[n],
      day: n => days[n]
    },
    formatLong: {},
    match:{}
  }

  return (
    <div className="date-picker form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <DatePicker
        className="form__form-group-datepicker"
        selected={startDate}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        dropDownMode="select"
        popperPlacement="center"
        locale={locale}
        /*withPortal={isMobileOnly}*/
      />
    </div>
  );
};

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const renderDatePickerField = ({ input,meta: { touched, error, warning } }) => <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above"><DatePickerField {...input} />{touched &&
((error && <span className="form__form-group-error">{error}</span>) ||
    (warning && <span>{warning}</span>))}</div>;

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDatePickerField;
