import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';


const GizlilikPolitikasiForm = () => (


  <Container>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form" style={{padding:"20px"}}>
            <div className="search-result">
                         <h3 className="page-title">İLETİŞİM BİLGİLERİMİZ</h3>


            </div>
            <div className="search-result">

                                      <p className="search-result__preview" >
                                        Bahçelievler Mahallesi 323/1 Cad. C Blok Gazi Üniversitesi Teknopark Binası 10/50C İç Kapı No:72 Gölbaşı/Ankara
                                      </p>

            </div>
            <div className="search-result">

                                                  <p className="search-result__preview" >
                                                  +90 (312) 232 4640

                                                  </p>

                        </div>
            <div className="search-result">

                                                              <p className="search-result__preview" >
                                                              info@ataxyazilim.com
                                                              </p>

                                    </div>


          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default GizlilikPolitikasiForm;
