import React, {useState} from 'react';

import axios from 'axios';
import Modal from '../../../components/modal/Modal';

import { Field, reduxForm, Form } from 'redux-form';

import validate from '../../../components/user/validate';

import RegisterForm from '../../../components/user/ChangeNewPass';
import {handle401} from "../../../data/common/LedgerUtils";
import axiosNew from '../../../data/common/getHeaderWOToken';
const logo = `${process.env.PUBLIC_URL}/img/clogo.png`;

const RegisterForm2 = () => {
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

      const [show, setShow] = useState(false);
      const [mColor, setMColor] = useState("warning");
      const [message, setMessage] = useState("");
      const [mTitle, setMTitle] = useState("");
      const [link, setLink] = useState('');
      const [closed, setClosed] = useState();
      const [forced, setForced] = useState(false);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const action = params.get('action');
      const confirmId = params.get('confirmationId');

      if (action === "reject"){
                                const body2 = {
                                    confirmationId: confirmId
                                }
                                axiosNew.post('rejectResetPassword', body2).then(res =>{
                                                              if(res.status === 200){
                                                                  if(res.data === "SUCCESS"){
                                                                      setShow(true);
                                                                      setMColor("success");
                                                                      setMessage("Talebiniz Alındı");
                                                                      setMTitle("İşlem Başarılı");
                                                                      setClosed(true);
                                                                      setForced(true);
                                                                      setLink('/login')
                                                                  }else{
                                                                      setShow(true);
                                                                      setMColor("warning");
                                                                      setMessage("İşlem sırasında bir hata oluştu :" + res.data);
                                                                      setMTitle("İşlem Başarısız");
                                                                      setClosed(true);
                                                                      setForced(true);
                                                                      setLink('/login')
                                                                  }
                                                              }
                                                  }).catch(err =>{
                                                              setShow(true);
                                                              setMColor("danger");
                                                              setMessage("İşlem sırasında bir hata oluştu, lütfen tekrar deneyiniz.");
                                                              setMTitle("İşlem Başarısız");
                                                              setClosed(true);
                                                              setForced(true);
                                                              setLink('/login')
                                                              handle401(err);
                                });
            }


   const handleSubmit = values => {

          switch(action){
              case "confirm":
                  const body = {
                               confirmationId: confirmId,
                               newPassword: values.password,
                               newPasswordAgain: values.password2
                  }
                  axiosNew.post('resetPassword', body).then(res =>{
                              if(res.status === 200){
                                  if(res.data === "SUCCESS"){
                                      setShow(true);
                                      setMColor("success");
                                      setMessage("Şifreniz değiştirildi.");
                                      setMTitle("İşlem Başarılı");
                                      setClosed(true);
                                      setForced(true);
                                      setLink('/login')
                                  }else{
                                      setShow(true);
                                      setMColor("warning");
                                      setMessage("İşlem sırasında bir hata oluştu :" + res.data);
                                      setMTitle("İşlem Başarısız");
                                      setClosed(true);
                                      setForced(true);

                                      setLink('/login')
                                  }
                              }
                  }).catch(err =>{
                              setShow(true);
                              setMColor("danger");
                              setMessage("İşlem sırasında bir hata oluştu, lütfen tekrar deneyiniz.");
                              setMTitle("İşlem Başarısız");
                              setLink('/login')
                              handle401(err);
                  });
                  break;
              default:
                  setShow(true);
                  setMColor("warning");
                  setMessage("Beklenmeyen bir hata oluştu :");
                  setMTitle("İşlem Başarısız");
                  setLink('/login')
                  break;

          }


      }

  return (
    <div className="account">
        { show &&
                  <Modal setShow={setShow} color={mColor} toggle={show} open={show} header message={message} title={mTitle} isClosed={closed} isForced={forced} />}
    <div className="account__wrapper">
    {action === "confirm" ?  (<div className="account__card">
                                       <div className="account__head">
                                       <h3 className="account__title">
                                           <img src={logo} alt="Atax Denetim" className="alogo"/>
                                       </h3>
                                       <h4 className="account__subhead subhead">En iyi denetim öz denetim</h4>

                                       </div>

                                           <RegisterForm onSubmit={handleSubmit}  />

                                       </div>) : null

    }
    </div>
    </div>

  );
};


export default reduxForm({
  form: 'register_form',
  validate
})(RegisterForm2);


