import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, CardBody, Card, Table } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import * as PropTypes from 'prop-types';
import renderCheckBoxField from '../form/CheckBox';
import validate from './validate';
import axios from 'axios';
import axiosPDF from '../../data/common/getHeaderPdf';
import {handle401} from "../../data/common/LedgerUtils";

const ConfirmForm = ({ handleSubmit,body }) => {

  const [permButton, setPermButton] = useState(false);
  const [fCount, setFCount] = useState(true);
  //const [btnText, setBtnText] = useState('Satın Al');
  //const [btnCount, setBtnCount] = useState();
  //const [paid, setPaid] = useState(true);

  function getChecked() {
    let checkboxes = document.querySelectorAll("input[type=checkbox]");
    let checked = [];

    for (let i = 0; i < checkboxes.length; i++) {
      let checkbox = checkboxes[i];
      if (checkbox.checked) checked.push(checkbox.value);
    }

    return checked.length === checkboxes.length;
  }

  const confirmContract = e => {
    let count = getChecked();
    setPermButton(count);
  }

  var id = body.subscriptionId;

  var reportLen = localStorage.getItem(id + "-aggReports")?.split(',')?.length

  //setFCount(reportLen === 2);

  const btnClickAct = async (e) => {
    //e.preventDefault();

    var a = JSON.parse(localStorage.getItem("userData"));
    const config = {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${a?.token}`,
        'Accept': 'application/pdf'
      }
    }
    let d = e.target.value;
    var arr = d?.split(',');


    let download = false;
    let fName = "";//, afName = "";

    if (localStorage.getItem(id + "-aggReports")?.split(',')?.length > 1) {
      var newArr = localStorage.getItem(id + "-aggReports")?.split(',');
      let start = "";
      if(newArr.length === 4){
        if(arr[1] === "hizmet"){start = newArr[0]}
        else if(arr[1] === "bilgi"){start = newArr[2]}
        else if(arr[1] === "cayma"){start = newArr[1]}
        else if(arr[1] === "kvkk"){start = newArr[3]}
      }
      else if(newArr.length === 2){
             if(arr[1] === "hizmet"){start = newArr[0]}
             else if(arr[1] === "kvkk"){start = newArr[1]}
      }

      fName = start;

    }

    if (arr === undefined || arr.length < 1) {
      alert("Bir sorun oluştu tekrar deneyiniz.");
      return false;
    }
    else {

      if (parseInt(arr[0]) === 2) { download = true; }

      const body = {
        subscriptionId: id,
        fileName: fName,
        download: download
      }
      axiosPDF.post("getAggrementReports", body, config).then((back) => {
        if (back.status === 200) {
          const report = new Blob([back.data], { type: 'application/pdf' });
          var reportUrl = URL.createObjectURL(report);
          if (download) {
            const link = document.createElement('a');
            link.href = reportUrl;
            link.setAttribute('download', body.fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
          else {
            window.open(reportUrl, "_blank");
          }
        }
      }).catch(err =>{
          handle401(err);
      });
    }

  }


  return (
    <Card>
      <CardBody>
        <form className="form form--horizontal confirm-form" onSubmit={handleSubmit} >
          <Table className="cTable" hover>
            <tr>
              <td>
                <Field name="c_one" onChange={confirmContract} component={renderCheckBoxField} label="Hizmet Sözleşmesini okudum kabul ediyorum" />
              </td>
              <td>
                <Button color="primary" type="button" size="sm" value="1,hizmet" onClick={btnClickAct}>Görüntüle</Button>
              </td>
              <td>
                <Button color="success" type="button" size="sm" value="2,hizmet" onClick={btnClickAct}>İndir</Button>
              </td>
            </tr>
            {reportLen === 4 ? (<>
                                            <tr>
                                              <td>
                                                <Field name="c_two" onChange={confirmContract} component={renderCheckBoxField} label="Satış Öncesi Ön Bilgilendirme Formunu okudum kabul ediyorum" />
                                              </td>
                                              <td>
                                                <Button color="primary" type="button" size="sm" value="1,bilgi" onClick={btnClickAct}>Görüntüle</Button>
                                              </td>
                                              <td>
                                                <Button color="success" type="button" size="sm" value="2,bilgi" onClick={btnClickAct}>İndir</Button>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <Field name="c_three" onChange={confirmContract} component={renderCheckBoxField} label="Cayma Formunu okudum kabul ediyorum" />
                                              </td>
                                              <td>
                                                <Button color="primary" type="button" size="sm" value="1,cayma" onClick={btnClickAct}>Görüntüle</Button>
                                              </td>
                                              <td>
                                                <Button color="success" type="button" size="sm" value="2,cayma" onClick={btnClickAct}>İndir</Button>
                                              </td>
                                            </tr>
                                            </>) : null
            }<tr>
              <td>
                <Field name="c_four" onChange={confirmContract} component={renderCheckBoxField} label="KVKK Formunu okudum kabul ediyorum" />
              </td>
              <td>
                <Button color="primary" type="button" size="sm" value="1,kvkk" onClick={btnClickAct}>Görüntüle</Button>
              </td>
              <td>
                <Button color="success" type="button" size="sm" value="2,kvkk" onClick={btnClickAct}>İndir</Button>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <ButtonToolbar>
                  <Button color="primary" type="submit" disabled={!permButton} >Satın Al</Button>
                </ButtonToolbar>
              </td>
            </tr>
          </Table>
        </form>
      </CardBody>
    </Card>
  )
};

ConfirmForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  subId:PropTypes.string
};

export default reduxForm({
  form: 'payment_form',
  validate
})(ConfirmForm);
