import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import {Card, CardBody, Col, Table, Badge, Button} from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axiosNew from '../../data/common/getHeader';
import {handle401} from "../../data/common/LedgerUtils";

const MyReferences = () =>
{
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);
  useEffect(() => {
    if(status){
      setPM('Referansınız bulunmamaktadır');
      axiosNew.get("getReferences").then(res => {
        if (res.status === 200) {
          setStatus(false);
          setLoading(false);
          setSub(res.data);
          if(res.data?.length > 0)
            setPM('Referanslarınız aşağıda listelenmektedir.');
        }
      }).catch(err => {
        handle401(err);
      });
    }
  }, []);

return(
  <> {loading ? (
    <div className="todo__panel-refresh">
      <LoadingIcon />
    </div>
  ) : (
  <Col md={12} lg={12} xl={8}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Referanslarınız</h5>
          <h5 className="subhead">{pm}
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>ÜNVAN</th>
              <th>E-POSTA</th>
              <th>PAKET ADI</th>
              <th>KOTA</th>
              <th>BAŞLANGIÇ TARİHİ</th>
              <th>BİTİŞ TARİHİ</th>
            </tr>
          </thead>
          <tbody>

          {sub.map((d,i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{d.title}</td>
              <td>{d.email}</td>
              <td>{d.packetId}-{d.packetName}</td>
              <td>{d.kota}</td>
              <td>{d.startDate}</td>
              <td>{d.endDate}</td>
            </tr>
          ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>)}
  </>
);
}
export default withTranslation('common')(MyReferences);
