import React, {useState} from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormOne from '../../components/company/AnalysisCompanyForm';
import axiosNew from '../../data/common/getHeader';
import Modal from '../../components/modal/Modal';
import Alert from '../../components/form/AlertWB';
import {handle401} from "../../data/common/LedgerUtils";
import moment from 'moment';



const Analysis = () => {

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [closed, setClosed] = useState(false);
  const [forced, setForced] = useState(false);

  const showResults = values => {
    const month = values.selectMonth?.value;
    const year = values.selectYear?.value;
    const tpID = values.selectCompany?.value;
    let subID = values.selectPacket?.value;
    let financialPeriodType = values.financialPeriodType;
    let financialPeriodStartDate =values.financialPeriodType !== "GENEL" && values.financialPeriodStartDate !== null ? moment(values.financialPeriodStartDate).format('DD/MM/YYYY'): null;
    let financialPeriodEndDate = values.financialPeriodType !== "GENEL" && values.financialPeriodEndDate !== null ? moment(values.financialPeriodEndDate).format('DD/MM/YYYY'): null;

    if(subID === undefined){
        subID = localStorage.getItem("sb");
    }
    const body = { taxpayerId: tpID, subscriptionId:subID, periodEndYear: year, periodEndMonth: month, tin: "0",financialPeriodType:financialPeriodType+ " HESAP DÖNEMİ",financialPeriodStartDate:financialPeriodStartDate,financialPeriodEndDate:financialPeriodEndDate };
    const fBody = { t: tpID, s: subID, p: year, n: month, k: values.selectCompany?.label };

    axiosNew.post('addJob', body)
      .then(user => {
        if (user.status === 200) {
          fBody.filePatterns = user.data?.expectedFilePatterns;
          if (user.data.message === "SUCCESS") {
            localStorage.setItem(user.data?.jobId + "-smUP", user.data?.expectedFilePatterns?.length);
            localStorage.setItem(user.data?.jobId + "-upFiles", JSON.stringify(fBody));
            localStorage.setItem(user.data?.jobId + "-fileType",values.fileType);
            setMessage("Devam etmek için Tamam butonuna basınız.");
            setShow(true);
            setTitle("İşlem Başarılı");
            setColor("success");
            setForced(true);
            setClosed(true);
            setLink('/DosyaYukle?jobId=' + user.data?.jobId);
          } else {
            setShow(true);
            setMessage(user.data);
            setTitle("Hata Oluştu");
            setColor("warning");
            setForced(false);
            setClosed(false);
            setLink('');
          }
        }
      })
      .catch(function (error) {
        setShow(true);
        setMessage("Lütfen Sistem Yöneticinizle irtibata geçiniz. Hata mesajı: " + error.message);
        setTitle("Başarısız İşlem Denemesi");
        setColor("danger");
        setForced(false);
        setClosed(false);
        setLink('');
        handle401(error);
      });

  }

  return (
    <>
      {show &&
        <Modal setShow={setShow} color={color} toggle={show} open={show} header message={message} title={title} closedLink={link} isClosed={closed} isForced={forced} />
      }
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">E-Defter Analizi</h3>
            <Alert color="warning" className="alert--bordered" icon>
                      <p><span className="bold-text">Zip formatındaki yevmiye dosyaları daha kısa sürede yüklenmektedir.</span>
                      </p>
                    </Alert>

          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody className="wizard">
                <div className="wizard__steps">
                </div>
                <div className="wizard__form-wrapper">
                  <FormOne onSubmit={showResults} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );

}

Analysis.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default withTranslation('common')(Analysis);