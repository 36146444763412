import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Users from '../../../components/user/Users';

const ListUsers = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Yeni Kullanıcılar </h3>
        <h3 className="page-subhead subhead">Son 100 kullanıcı aşağıda listelenmektedir.
        </h3>
      </Col>
    </Row>
    <Row>
      <Users />
    </Row>
  </Container>
);


export default ListUsers