import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, Badge, Table, Button } from 'reactstrap';
import axiosNew from '../../data/common/getHeader';
import axiosPDF from '../../data/common/getHeaderPdf';
import Alert from '../../components/form/AlertWB';
import checkUser from '../../data/common/getHeader';
import { handle401 } from '../../data/common/LedgerUtils';

class bTable extends Component {
  constructor() {
    super();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      dataList: [],
      newDataList: [],
      status: 'warning',
      listingsExist: false,
      bilanco: 'warning',
      bilancoMsg: 'Bekleniyor',
      mizan: 'warning',
      mizanMsg: 'Bekleniyor',
      gelir: 'warning',
      gelirMsg: 'Bekleniyor',
      analiz: 'warning',
      analizMsg: 'Bekleniyor',
      analiz_docx: 'warning',
      analizDocxMsg: 'Bekleniyor',
      analysisDate: '',
      analysisId: params.get('analysisId'),
      admin: false,
      go2HTMLAnalysis:  () => {
        window.location.href = "/HTMLRapor?analysisId=" + this.state.analysisId;
      }
    };
  }

  delFile = (fnm) => {
    //const id = localStorage.getItem("upload");
    const id = this.state.analysisId;
    const body = {
      jobId: id,
      reportType: fnm,
    };

    axiosNew
      .post('deleteReport', body)
      .then((back) => {
        if (back.status === 200) {
          alert(back.data);
        }
      })
      .catch((err) => {
        handle401(err);
      });
  };

  loopThroughListings = async () => {
    const url = 'getReportResult';
    let data = this.state.newDataList;
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let tRow = data[i];
        let body = {
          jobId: tRow?.jobId,
          reportType: tRow?.reportType,
        };
        let statusOfReport = tRow?.status;
        let analysisDate = tRow?.analysisDate;
        switch (body.reportType) {
          case 'MİZAN':
            switch (statusOfReport) {
              case 'GOING ON':
                const ready = await this.getListing(url, body)
                  .then((res) => {
                    return res;
                  })
                  .catch((err) => {
                    handle401(err);
                    return err;
                  });
                if (!ready.error) {
                  switch (ready.data) {
                    case 'GOING ON':
                      this.setState({
                        mizan: 'warning',
                        mizanMsg: 'Bekleniyor',
                      });
                      break;
                    case 'FINISHED':
                      this.setState({
                        mizan: 'success',
                        mizanMsg: 'Tamamlandı',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    default:
                      this.setState({
                        mizan: 'danger',
                        mizanMsg: 'Hata Oluştu',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                  }
                }
                break;
              case 'FINISHED':
                this.setState({ mizan: 'success', mizanMsg: 'Tamamlandı' });
                break;
              default:
                this.setState({ mizan: 'danger', mizanMsg: 'Hata Oluştu' });
            }
            break;
          case 'GELİR TABLOSU':
            switch (statusOfReport) {
              case 'GOING ON':
                const ready = await this.getListing(url, body)
                  .then((res) => {
                    return res;
                  })
                  .catch((err) => {
                    handle401(err);
                    return err;
                  });
                if (!ready.error) {
                  switch (ready.data) {
                    case 'GOING ON':
                      this.setState({
                        gelir: 'warning',
                        gelirMsg: 'Bekleniyor',
                      });
                      break;
                    case 'FINISHED':
                      this.setState({
                        gelir: 'success',
                        gelirMsg: 'Tamamlandı',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    default:
                      this.setState({
                        gelir: 'danger',
                        gelirMsg: 'Hata Oluştu',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                  }
                }
                break;
              case 'FINISHED':
                this.setState({ gelir: 'success', gelirMsg: 'Tamamlandı' });
                break;
              default:
                this.setState({ gelir: 'danger', gelirMsg: 'Hata Oluştu' });
            }
            break;
          case 'BİLANÇO':
            switch (statusOfReport) {
              case 'GOING ON':
                const ready = await this.getListing(url, body)
                  .then((res) => {
                    return res;
                  })
                  .catch((err) => {
                    handle401(err);
                    return err;
                  });
                if (!ready.error) {
                  switch (ready.data) {
                    case 'GOING ON':
                      this.setState({
                        bilanco: 'warning',
                        bilancoMsg: 'Bekleniyor',
                      });
                      break;
                    case 'FINISHED':
                      this.setState({
                        bilanco: 'success',
                        bilancoMsg: 'Tamamlandı',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    default:
                      this.setState({
                        bilanco: 'danger',
                        bilancoMsg: 'Hata Oluştu',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                  }
                }
                break;
              case 'FINISHED':
                this.setState({ bilanco: 'success', bilancoMsg: 'Tamamlandı' });
                break;
              default:
                this.setState({ bilanco: 'danger', bilancoMsg: 'Hata Oluştu' });
            }
            break;
          case 'ANALİZ':
            switch (statusOfReport) {
              case 'GOING ON':
                const ready = await this.getListing(url, body)
                  .then((res) => {
                    return res;
                  })
                  .catch((err) => {
                    handle401(err);
                    return err;
                  });
                if (!ready.error) {
                  switch (ready.data) {
                    case 'GOING ON':
                      this.setState({
                        analiz: 'warning',
                        analizMsg: 'Bekleniyor',
                      });
                      break;
                    case 'FINISHED':
                      this.setState({
                        analiz: 'success',
                        analizMsg: 'Tamamlandı',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    case 'DELETED':
                      this.setState({ analiz: 'info', analizMsg: 'Silindi' });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    default:
                      this.setState({
                        analiz: 'danger',
                        analizMsg: 'Hata Oluştu',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                  }
                }
                break;
              case 'FINISHED':
                this.setState({ analiz: 'success', analizMsg: 'Tamamlandı' });
                break;
              default:
                this.setState({ analiz: 'danger', analizMsg: 'Hata Oluştu' });
            }
            break;
          case 'ANALİZ_DOCX':
            switch (statusOfReport) {
              case 'GOING ON':
                const ready = await this.getListing(url, body)
                  .then((res) => {
                    return res;
                  })
                  .catch((err) => {
                    handle401(err);
                    return err;
                  });
                if (!ready.error) {
                  switch (ready.data) {
                    case 'GOING ON':
                      this.setState({
                        analiz_docx: 'warning',
                        analizDocxMsg: 'Bekleniyor',
                      });
                      break;
                    case 'FINISHED':
                      this.setState({
                        analiz_docx: 'success',
                        analizDocxMsg: 'Tamamlandı',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    case 'DELETED':
                      this.setState({
                        analiz_docx: 'info',
                        analizDocxMsg: 'Silindi',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                    default:
                      this.setState({
                        analiz_docx: 'danger',
                        analizDocxMsg: 'Hata Oluştu',
                      });
                      data.splice(i, 1);
                      this.setState({ newDataList: data });
                      break;
                  }
                }
                break;
              case 'FINISHED':
                this.setState({
                  analiz_docx: 'success',
                  analizDocxMsg: 'Tamamlandı',
                });
                break;
              default:
                this.setState({
                  analiz_docx: 'danger',
                  analizDocxMsg: 'Hata Oluştu',
                });
            }
            break;
          case 'ANALİZ_HTML':
              switch (statusOfReport) {
                case 'GOING ON':
                  const ready = await this.getListing(url, body)
                    .then((res) => {
                      return res;
                    })
                    .catch((err) => {
                      handle401(err);
                      return err;
                    });
                  if (!ready.error) {
                    switch (ready.data) {
                      case 'GOING ON':
                        this.setState({
                          analiz_html: 'warning',
                          analizHtmlMsg: 'Bekleniyor',
                        });
                        break;
                      case 'FINISHED':
                        this.setState({
                          analiz_html: 'success',
                          analizHtmlMsg: 'Tamamlandı',
                        });
                        data.splice(i, 1);
                        this.setState({ newDataList: data });
                        break;
                      case 'DELETED':
                        this.setState({
                          analiz_html: 'info',
                          analizHtmlMsg: 'Silindi',
                        });
                        data.splice(i, 1);
                        this.setState({ newDataList: data });
                        break;
                      default:
                        this.setState({
                          analiz_html: 'danger',
                          analizHtmlMsg: 'Hata Oluştu',
                        });
                        data.splice(i, 1);
                        this.setState({ newDataList: data });
                        break;
                    }
                  }
                  break;
                case 'FINISHED':
                  this.setState({
                    analiz_html: 'success',
                    analizHtmlMsg: 'Tamamlandı',
                  });
                  break;
                default:
                  this.setState({
                    analiz_html: 'danger',
                    analizHtmlMsg: 'Hata Oluştu',
                  });
              }
              break;  
        }
      }
    }
  };

  getListing = async (url, body) => {
    return new Promise((resolve, reject) => {
      axiosNew
        .post(url, body)
        .then((res) => {
          return resolve({
            error: false,
            message: 'Success',
            data: res.data,
            type: body.reportType,
          });
        })
        .catch(function (error) {
          handle401(error);
          if (error instanceof URIError) {
            return reject({ error: true, message: 'Failed' });
          } else {
            return reject({ error: true, message: 'Failed' });
          }
        });
    });
  };  

  downFile = (fnm) => {
    //const id = localStorage.getItem("upload");
    const id = this.state.analysisId;
      const body = {
        jobId: id,
        reportType: fnm,
      };
      var a = JSON.parse(localStorage.getItem('userData'));
      const config = {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${a?.token}`,
          Accept: !body.reportType.includes('DOCX')
            ? 'application/pdf'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //'application/octet-stream //'
        },
      };
    axiosPDF
      .post('downloadReport', body, config)
      .then((back) => {
        if (back.status === 200) {
          const report = new Blob([back.data], {
            type: !body.reportType.includes('DOCX')
              ? 'application/pdf'
              : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          var reportUrl = URL.createObjectURL(report);
          const link = document.createElement('a');
          link.href = reportUrl;
          var type = body.reportType.toLowerCase().includes('docx')
            ? body.reportType.toLowerCase().replace('_docx', '')
            : body.reportType.toLowerCase();
          const fileName = body.jobId + '-' + type;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        handle401(err);
      });
  };
  async componentDidMount() {
    const urlRep = 'getReports';
    let count = 0;
    const id = this.state.analysisId;
    //const id = 1;
    let newReport = new FormData();
    newReport.append('jobId', id);
    await axiosNew
      .post(urlRep, newReport)
      .then((res) => {
        if (res.status === 200) {
          console.log("get reports");  
          console.log(res);  
          console.log(res.data);  
          this.setState({
            dataList: res.data,
            newDataList: res.data,
            listingsExist: true,
          });
        }
      })
      .catch((err) => {
        handle401(err);
      });

    if (count === 0) {
      this.loopThroughListings();
      count++;
    }

    setInterval(async () => {
      if (this.state.listingsExist) {
        this.loopThroughListings();
        if (this.state.newDataList?.length === 0) {
          this.setState({ listingsExist: false });
        }
      }
    }, 20000);

    await checkUser
      .get('getAdminStatus')
      .then((res) => {
        if (res.status === 200) {
          this.setState({ admin: res.data });
        }
      })
      .catch((err) => {
        handle401(err);
      });
  }
  render() {
    const {
      mizan,
      bilanco,
      gelir,
      analiz,
      analiz_docx,
      analiz_html,
      mizanMsg,
      bilancoMsg,
      gelirMsg,
      analizMsg,
      analizDocxMsg,
      analizHtmlMsg,
      analizHtmlReport,
      admin,
    } = this.state;
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <div className="">
              <h5 className="bold-text card-title">ANALİZ SONUCU</h5>
              <div dangerouslySetInnerHTML={{__html: analizHtmlReport}}>
              {/* {analizHtmlReport} */}
              </div>
              <Alert color="info" className="alert--bordered" icon>
                <p>
                  Yapmış olduğunuz analiz sonuçları
                  <span className="red-text"> aşağıda görüntülenir.</span>
                </p>
              </Alert>
              {/* <h5 className="subhead">Yapmış olduğunuz analiz sonuçları
              <span className="red-text"> aşağıda görüntülenir.</span> BU İŞLEM ORTALAMA 1-5 DAKİKA ARASINDA SÜRMEKTEDİR.
              </h5> */}
            </div>
            <Table className="table--bordered" responsive>
              <thead>
                <tr>
                  <th>Tablo Adı</th>
                  <th>Durum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mizan</td>
                  <td>
                    <Badge color={mizan}>{mizanMsg}</Badge>
                  </td>
                  <td>
                    {' '}
                    {mizan === 'success' ? (
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => this.downFile('MİZAN')}
                      >
                        İndir
                      </Button>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>Gelir Tablosu</td>
                  <td>
                    <Badge color={gelir}>{gelirMsg}</Badge>
                  </td>
                  <td>
                    {' '}
                    {gelir === 'success' ? (
                      <Button
                        className="btn btn-primary btn-sm"
                        onClick={() => this.downFile('GELİR TABLOSU')}
                      >
                        İndir
                      </Button>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>Bilanço</td>
                  <td>
                    <Badge color={bilanco}>{bilancoMsg}</Badge>
                  </td>
                  <td>
                    {' '}
                    {bilanco === 'success' ? (
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => this.downFile('BİLANÇO')}
                      >
                        İndir
                      </Button>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>Analiz Raporu</td>
                  <td>
                    <Badge color={analiz}>{analizMsg}</Badge>
                  </td>
                  {analiz === 'info' ? (
                    <td>Bu rapor silinmiştir. </td>
                  ) : (
                    <React.Fragment>
                      <td>
                        {' '}
                        {analiz === 'success' ? (
                          <Button
                            className="btn btn-sm btn-primary"
                            onClick={() => this.downFile('ANALİZ')}
                          >
                            İndir
                          </Button>
                        ) : null}
                      </td>
                    </React.Fragment>
                  )}
                </tr>
                {(analiz_docx === 'success' || analiz_docx === 'info') && (
                  <tr>
                    <td>Analiz Raporu (Word)</td>
                    <td>
                      <Badge color={analiz_docx}>{analizDocxMsg}</Badge>
                    </td>
                    {analiz_docx === 'info' ? (
                      <td>Bu rapor silinmiştir. </td>
                    ) : (
                      <React.Fragment>
                        <td>
                          {' '}
                          {analiz_docx === 'success' ? (
                            <Button
                              className="btn btn-sm btn-primary"
                              onClick={() => this.downFile('ANALİZ_DOCX')}
                            >
                              İndir
                            </Button>
                          ) : null}
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                )}
                {(analiz_html === 'success' || analiz_html === 'info') && (
                  <tr>
                    <td>Analiz Raporu (Özelleştirilebilir)</td>
                    <td>
                      <Badge color={analiz_docx}>{analizHtmlMsg}</Badge>
                    </td>
                    {analiz_html === 'info' ? (
                      <td>Bu rapor silinmiştir. </td>
                    ) : (
                      <React.Fragment>
                        <td>
                          {' '}
                          {analiz_html === 'success' ? (
                            <Button
                              className="btn btn-sm btn-primary"
                              // onClick={() => {
                              //   return this.readHTMLFile('ANALİZ_HTML');
                              //   console.log("gonna view analiz html");
                              // }}
                              onClick={this.state.go2HTMLAnalysis}
                            >
                              Görüntüle
                            </Button>
                          ) : null}
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withTranslation('common')(bTable);
