import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Jobs from '../../../components/user/MyJobs';

const ListProduct = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Analizlerim </h3>
        <h3 className="page-subhead subhead">Daha Önceki Analiz Sonuçları Aşağıda Yer Almaktadır.
        </h3>
      </Col>
    </Row>
    <Row>
      <Jobs />
    </Row>
  </Container>
);


export default ListProduct
