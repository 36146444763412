import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import renderFieldError from '../form/InputErrorHandler';
import { Button, ButtonToolbar } from 'reactstrap';
import validate from './validate';



const RegisterForm = ({ handleSubmit, errorMessage, submitting, invalid  }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleShowPassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

  const toggleShowPassword3 = (e) => {
    e.preventDefault();
    setShowPassword3(!showPassword3);
  };
  
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">Mevcut Şifreniz</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password3"
            component={renderFieldError}
            type={showPassword3 ? 'text' : 'password'}
            placeholder="******"
            required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword3 ? ' active' : ''}`}
            onClick={toggleShowPassword3}
          ><EyeIcon />
          </button>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Yeni Şifreniz</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component={renderFieldError}
            type={showPassword ? 'text' : 'password'}
            placeholder="******"
            required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={toggleShowPassword}
          ><EyeIcon />
          </button>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Yeni Şifre Tekrar</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password2"
            component={renderFieldError}
            type={showPassword2 ? 'text' : 'password'}
            placeholder="******"
            required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword2 ? ' active' : ''}`}
            onClick={toggleShowPassword2}
          ><EyeIcon />
          </button>
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar">
        <Button type="submit" color="primary" className="account__btn" disabled={invalid || submitting}>
          Şifremi Değiştir
        </Button>
      </ButtonToolbar>
    </form>
  );
};


export default reduxForm({
  form: 'register_form',
  validate
})(RegisterForm);
