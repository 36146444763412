import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';


const KvkkForm = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">KVKK Form</h3>
        <h3 className="page-subhead subhead">KVKK Formuna ait içerik aşağıdaki gibidir</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form">
            <div className="search-result">
              <p className="search-result__title">1- GENEL AÇIKLAMA VE TANIMLAR</p>
              <p className="search-result__preview">İş bu bilgilendirme metni, Atax Yazılım ve Danışmanlık A.Ş. (Bundan böyle ATAX YAZILIM Danışmanlık olarak ifade edilecektir)’ın “veri sorumlusu” sıfatıyla, 6698 Sayılı Kişisel Verilerin Korunması Kanunu (Kanun)’nun 10 ncu maddesi kapsamında, “aydınlatma yükümlülüğü” nün yerine getirilmesi amacıyla hazırlanmıştır.</p>
            </div>
            <div className="search-result">
              <p className="search-result__title">2- VERİ SORUMLUSUNA İLİŞKİN BİLGİLER</p>
              <p className="search-result__preview">ATAX YAZILIM, Türkiye’de yerleşik, tüzel kişiliği olan bir sermaye şirketi olarak faaliyetlerini yürütmektedir. Veri sorumlusu olarak ATAX YAZILIM hakkındaki bilgiler aşağıda belirtildiği şekildedir.
              <br />Veri Sorumlusunun;
                <table>
                  <tr>
                    <td>Ünvanı</td>
                    <td>: Atax Yazılım ve Danışmanlık A.Ş.</td>
                  </tr>
                  <tr>
                    <td>Mersis No</td>
                    <td>: 0099116000100001</td>
                  </tr>
                  <tr>
                    <td>Adresi</td>
                    <td>: Bahçelievler Mah. 323/1 Cad. C Blok Gazi Üniversitesi Teknokent Binası No:10/50C İç Kapı No:72 Gölbaşı / Ankara</td>
                  </tr>
                  <tr>
                    <td>Telefonu</td>
                    <td>: 0 (312) 232 4640</td>
                  </tr>
                  <tr>
                    <td>E-posta Adresi</td>
                    <td>: info@ataxyazilim.com</td>
                  </tr>
                </table>
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">3- İŞLENECEK KİŞİSEL VERİLER</p>
              <p className="search-result__preview">ATAX YAZILIM tarafından veri işlemeye konu edilen kişisel veriler aşağıdadır.
                <ul className="ml-2">
                  <li> <strong>Kimlik Bilgileri: (Ad, Soyad, TC No)</strong></li>
                  <li> <strong>İletişim Bilgileri:</strong> (İş Adresi, İş telefonu, fax, İş/özel e-posta, Kep Adresi, web adresi)</li>
                  <li> <strong>Finans:</strong> (Ödeme işlemi sonucuna ilişkin log kayıtları. Not: Kredi kartı numarası ATAX sisteminde tutulmamaktadır.)</li>
                  <li> <strong>Hukuki İşlem:</strong> (İmza, imza beyanı, imza sirküsü, vekaletname, sözleşme ,açık rıza onayı ve aydınlatma metni)</li>
                  <li> <strong>Müşteri İşlem:</strong> (Vergi Kimlik Bilgileri, nace kodu, Sektör, Sigorta Bilgileri, Fatura)</li>
                  <li> <strong>İşlem Güvenliği:</strong> (IP Adresi, user-agnet bilgileri, log bilgileri)</li>
                </ul>
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">4- KİŞİSEL VERİLERİN ELDE EDİLME YÖNTEMLERİ VE HUKUKİ SEBEBİ</p>
              <p className="search-result__preview">Kişisel veriler, hukuki yükümlüğünün yerine getirilmesi, kanunlarda öngörülmesi, açık rızanın alınması ve sözleşmenin kurulması hukuki sebeplerine dayalı olarak,  doğrudan ilgili kişinin kendisinden temin edilebileceği gibi, mail yoluyla, elektronik ortamlar yoluyla çözüm ortağı kurum ve kuruluşlar ile bunlarla sınırlı olmamak üzere edinilen verinin edinme amacına göre dolaylı yollardan da temin edilebilmekte ve otomatik, kısmen otomatik ve veri kayıt sisteminin bir parçası olarak otomatik olmayan şekilde işlenmektedir.</p>
            </div>
            <div className="search-result">
              <p className="search-result__title">5- KİŞİSEL VERİLERİN İŞLENME AMACI</p>
              <p className="search-result__preview">ATAX YAZILIM kişisel verileri aşağıdaki amaçlar doğrultusunda işlemektedir.
                <ul>
                  <li>	Finans ve muhasebe işlerinin yürütülmesi</li>
                  <li>	Erişim yetkilerinin yürütülmesi</li>
                  <li>	Reklam, kampanya, promosyon süreçlerinin yürütülmesi</li>
                  <li>	Saklama ve arşiv faaliyetlerinin yürütülmesi</li>
                  <li>	Sözleşme süreçlerinin yürütülmesi</li>
                  <li>	Ürün ve hizmetlerin pazarlama süreçlerinin yürütülmesi</li>
                  <li>	İletişim faaliyetlerinin yürütülmesi</li>
                  <li>	Faaliyetlerin mevzuata uygun yürütülmesi</li>
                  <li>	Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi</li>
                  <li>	Mal ve hizmet satış süreçlerinin yürütülmesi</li>
                  <li>	Mal ve hizmet satın alım süreçlerinin yürütülmesi</li>
                  <li>	Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi</li>
                </ul>
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">6- KİŞİSEL VERİLERİN AKTARIMI VE AKTARIM AMAÇLARI</p>
              <p className="search-result__title">6.1- Yurt İçi Aktarım</p>
              <p className="search-result__preview">ATAX YAZILIM söz konusu kişisel verileri yalnızca işbu Aydınlatma Metni’ nde belirtilen amaçlar doğrultusunda ve KVK Kanunu’nun 8. maddesine uygun olarak ve ilgili kişilerin açık rızasını alarak üçüncü kişilere aktarmaktadır.
              ATAX YAZILIM, Kanun ve sair mevzuat hükümlerinin zorunlu kıldığı/izin verdiği kişi, kurum ve/veya kuruluşlara, bunlarla sınırlı olmamak üzere işbirliği yapılan program ortağı/çözüm ortağı kurum ve kuruluşlara, PTT şubelerine, lojistik destek hizmeti, danışmanlık ve bağımsız denetim hizmeti alınan firmalara, yasal zorunluluklar nedeniyle; yasal zorunluluk olmasa dahi 5.madde kapsamında daha iyi hizmet verebilmeye yönelik amaçlarını gerçekleştirebilmek için 3. kişi ve kurumlara, ilgili kişinin 6698 sayılı Kanun kapsamındaki haklarının saklı kalması koşuluyla, kişisel verileri aktarabilecektir.</p>
              <p className="search-result__title">6.2- Yurt Dışı Aktarım</p>
              <p className="search-result__preview">ATAX YAZILIM kişisel verileri, e-posta hizmetlerini (yandex mail / gmail)  yurt dışından alması sebebiyle Kanunun 9(2) maddesi ve Kişisel Verileri Koruma Kurulunun 31.05.2019 tarih ve 2019/157 sayılı kararı ile yurt dışına aktarım şartları doğrultusunda iletişim faaliyetleri ve işbu Aydınlatma Metninde belirtilen amaçları gerçekleştirmek üzere aktarmaktadır.
              ATAX YAZILIM ayrıca ödeme yöntemine, üyeliğine ve siparişine ilişkin bilgilerin, ödemenin gerçekleştirilebilmesi ve ödeme usulsüzlüklerinin önlenmesi, araştırılması ve tespit edilmesini temin amacıyla İyzico Ödeme Hizmetleri A.Ş.’ye aktarmaktadır.</p>
            </div>
            <div className="search-result">
              <p className="search-result__title">7- İLGİLİ KİŞİNİN HAKLARI ve VERİ SORUMLUSUNA BAŞVURU</p>
              <p className="search-result__preview">Kişisel verisi işlenen gerçek kişiler, 6698 sayılı Kanunun 11 nci maddesinde belirtilen hakları kapsamındaki taleplerine “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkındaki Tebliğe” göre ATAX YAZILIM’ a başvurabilir. İlgili kişiler taleplerini, aşağıdaki iletişim kanallarından biri vasıtası ile ATAX YAZILIM’ a iletebilir. Bu doğrultuda ilgili kişiler taleplerini; Yazılı olarak, Güvenli elektronik imza veya mobil imza yoluyla, Kişisel verisi işlenen kişi tarafından ATAX YAZILIM’ a daha önce bildirilen ve ATAX YAZILIM’ ın sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle iletebilirler. Konuya ilişkin bilgi ve belgeler başvuruya eklenmelidir.  Başvuru talepleri, “Başvuru Talep Formu” kullanılmak suretiyle yapılabilir. “Başvuru Talep Formu” na https://ataxdenetim.com adresinden ulaşılabilir.</p>
            </div>
            <div className="search-result">
              <p className="search-result__title">8- DEĞİŞİKLİK VE GÜNCELLEMELER</p>
              <p className="search-result__preview">İlgili yasal mevzuat ve/veya ATAX YAZILIM’ ın kişisel veri işleme amaç ve politikalarında meydana gelecek değişiklikler doğrultusunda bilgilendirme metninde gerekli değişiklikler yapılabilir. Bilgilendirme Metninin en güncel haline https://ataxdenetim.com adresinden ulaşılabilecektir.</p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default KvkkForm;
