import React from 'react';
import { Col, Container, Card, CardBody } from 'reactstrap';
import { Badge, Table, Button } from 'reactstrap';
import axiosNew from '../../data/common/getHeader';
import axiosPDF from '../../data/common/getHeaderPdf';
import Alert from '../../components/form/AlertWB';
import checkUser from '../../data/common/getHeader';
import { handle401 } from '../../data/common/LedgerUtils';
import InnerHTML from 'dangerously-set-html-content'

const AnalysisResultHTML = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  let analysisId = params.get('analysisId');
  let fnm = "ANALİZ_HTML";

  const [rawHTML, setRawHTML] = React.useState();


  const readHTMLFile = () => {    
    const body = {
      jobId: analysisId,
      reportType: fnm,
    };
    var a = JSON.parse(localStorage.getItem('userData'));
    const config = {
      headers: {
        Authorization: `Bearer ${a?.token}`,
      },
    };
    axiosPDF
      .post('readReport', body, config)
      .then((back) => {
        if (back.status === 200) {
          const report = back.data;
          // setExtractscript(report.match(/<script>(.|\n|\r)+<\/script>/));
          setRawHTML(report);
        }
      })
      .catch((err) => {
        handle401(err);
      });
  };

  React.useEffect(() => {
    readHTMLFile();
  },[] )

  // React.useEffect(() => {
  //   if(extractscript && extractscript.length > 0)
  //   {
  //     console.log("extractscript3");
  //     var deneme = extractscript[0].replace("<script>", "")
  //      deneme = deneme.replace("<\/script>", "")
  //     console.log(deneme);
  //     window.eval(deneme);
  //   }

  // },[extractscript] )

  return (
    <Container>
      <Col md={12} lg={12} xl={12}>
      <Card>
          <CardBody>
            {/* {rawHTML && <div dangerouslySetInnerHTML={{ __html: rawHTML }} >
            </div>} */}
            <InnerHTML html={rawHTML} />
        </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default AnalysisResultHTML;
