import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LastJobs from '../../../components/user/LastJobs';

const ListLastJobs = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Yeni analizler </h3>
        <h3 className="page-subhead subhead">Son 100 analiz aşağıda listelenmektedir.
        </h3>
      </Col>
    </Row>
    <Row>
      <LastJobs />
    </Row>
  </Container>
);


export default ListLastJobs