import PropTypes from 'prop-types';
import React, { Component } from 'react';
import checkUser from '../../data/common/getHeader';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';

// const ozet = `${process.env.PUBLIC_URL}/files/atax_summary.pdf`;
// const detay = `${process.env.PUBLIC_URL}/files/atax_detailed.pdf`;

class SidebarContent extends Component {

  constructor() {
    super()
    this.state = {
      status: false,
      controller: false,
      dealer: false
    }
  }
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  logOut = () => {
    localStorage.clear();
    checkUser.get("signout").then(res => {

    }).catch(err => {

    });
    window.location.href = "/Login";
  }

  openFile = (fileLink) => {
    window.open(fileLink, "_blank");
  }

  st = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      let isController = userData?.isController;
      let isAdmin = userData?.last;
      this.setState({ status: isAdmin });
      this.setState({ controller: isController });
      let isDealer = userData?.dealer;
      this.setState({ dealer: isDealer });
      if (window.location.pathname === "/Dashboard"
            || window.location.pathname === "/Demo"
            || window.location.pathname === "/Yenikullanicilar"
            || window.location.pathname === "/Yeniabonelikler"
            || window.location.pathname === "/Yenianalizler") {
          if (!isAdmin) {
              window.location.href = "/Anasayfa";
          }
      }
      if (window.location.pathname === "/Referanslar") {
                if (!isDealer) {
                    window.location.href = "/Anasayfa";
                }
      }
  }

  async componentDidMount() {
    this.st();
  }

  render() {
    const durum = this.state.status;
    const controller = this.state.controller;
    const dealer = this.state.dealer;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink icon="home" title="Anasayfa" route="/Anasayfa" />
        </ul>
        <ul className="sidebar__block" >
                                          <SidebarCategory title="Kullanım Kılavuzu" icon="menu">
                                            <SidebarLink  title="Özet" route="/AtaxSummary" />
                                            <SidebarLink  title="Detaylı Kılavuz" route="/AtaxDetailed" />
                                              <SidebarLink  title="Video Rehber" route="/AtaxVideo" />

                                          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarLink icon="inbox" title="Mevcut Paketlerim" route="/Paketlerim" />
        </ul>

        <ul className="sidebar__block">
          <SidebarLink icon="magic-wand" title="Analiz Başlat" route="/AnalizYap" />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink icon="menu" title="Önceki Analizlerim" route="/BitenAnalizler" />
        </ul>
        <ul className="sidebar__block">
                  <SidebarLink icon="license" title="YENİ PAKET AL" route="/Prices" />
        </ul>
        {
                          dealer && <ul className="sidebar__block">
                            <SidebarLink title="Referanslarım" icon="chart-bars" route="/Referanslarim" />
                          </ul>
                        }
        <ul className="sidebar__block">
          <SidebarCategory title="Üyelik Bilgilerim" icon="user">
            <SidebarLink icon="" title="Üyelik Bilgilerini Görüntüle" route="/UyelikBilgileri" />
            <SidebarLink icon="" title="Parolayı Değiştir" route="/SifremiDegistir" />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
                                  <SidebarCategory title="Bilgilendirme" icon="user">
                                    <SidebarLink icon="" title="Hakkımızda" route="/Hakkimizda" />
                                    <SidebarLink icon="" title="Gizlilik Politikası" route="/GizlilikPolitikasi" />
                                    <SidebarLink icon="" title="İletişim" route="/Iletisim" />
                                  </SidebarCategory>
                </ul>
        <ul className="sidebar__block">
          <li className="sidebar__link">
            <a onClick={this.logOut}>
              <span className="sidebar__link-icon lnr lnr-exit"></span>
              <p className="sidebar__link-title" >Çıkış</p>
            </a>
          </li>
        </ul>

        {
          durum && <ul className="sidebar__block">
           <SidebarCategory title="Admin İşlemleri" icon="chart-bars">
            {/*<SidebarLink title="Dashboard" icon="chart-bars" route="/Dashboard" />*/}
            <SidebarLink title="Yeni Kullanıcılar" icon="" route="/Yenikullanicilar" />
            <SidebarLink title="Yeni Abonelikler" icon="" route="/Yeniabonelikler" />
            <SidebarLink title="Yeni Analizler" icon="" route="/Yenianalizler" />
            <SidebarLink icon="" title="Demo" route="/Demo" />
            </SidebarCategory>
          </ul>
        }
        {
          controller && <ul className="sidebar__block">
           <SidebarCategory title="Koordinatör İşlemleri" icon="chart-bars">            
            <SidebarLink title="Yapılan Analizler" icon="" route="/BitenSubCustomerAnalizler" />
            
            </SidebarCategory>
          </ul>
        }

      </div>
    );
  }
}

export default SidebarContent;
