import LoadingIcon from 'mdi-react/LoadingIcon';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CardBasicFree from '../../components/product/CardBasicFree';
import CardSpecialFree from '../../components/product/CardSpecialFree';
import axiosNew from '../../data/common/getHeader';
import { handle401 } from "../../data/common/LedgerUtils";
// const iyzicoImg = `${process.env.PUBLIC_URL}/img/logo-band@1X.png`;

const PricingCardsFree = () => {

  useEffect(() => {
    const stData = () => {
      axiosNew.get("getPacketTypes")
        .then((res) => {
          if (res.status === 200) {
            sendData(res.data);
          }
        }).catch(err => {
          handle401(err);
        });
    }
    const sendData = packet => {
      if (packet?.length > 0) {
        for (let i = 0; i < packet?.length; i++) {
          let body = { 'packetLevel': packet[i] }
          getData(body);
        }
        setLoading(false);
      }
    }

    const getData = packet => {
      axiosNew.post("getPackets", packet)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.message === "SUCCESS") {
              switch (res.data.packetName) {
                case "2019 Analizi":
                  setP2019(res.data);
                  break;
                case "2020 Analizi":
                  setP2020(res.data);
                  break;
                case "2021 Analizi":
                  setP2021(res.data);
                  break;
                case "2022 Analizi":
                  setP2022(res.data);
                  break;
                case "2021 Paket-10":
                  setP2021_10(res.data);
                  break;
                case "2021 Paket-20":
                  setP2021_20(res.data);
                  break;
                case "2021 Paket-50":
                  setP2021_21_50(res.data);
                  break;
                case "2022 Kurumsal Paket":
                  setP2022(res.data);
                  break;
                case "2022 Paket-10":
                  setP2022_10(res.data);
                  break;
                case "2022 Paket-20":
                  setP2022_20(res.data);
                  break;
                case "2022 Paket-50":
                  setP2022_21_50(res.data);
                  break;
                case "2021-2022 Kurumsal Paket":
                  setP2021_2022(res.data);
                  break;
                case "2021-2022 Paket-10":
                  setP2021_2022_10(res.data);
                  break;
                case "2021-2022 Paket-20":
                  setP2021_2022_20(res.data);
                  break;
                case "2021-2022 Paket-50":
                  setP2021_2022_21_50(res.data);
                  break;


                  case "2023 Kurumsal Paket":
                    setP2023(res.data);
                    break;
                  case "2023 Paket-10":
                    setP2023_10(res.data);
                    break;
                  case "2023 Paket-20":
                    setP2023_20(res.data);
                    break;
                  case "2023 Paket-50":
                    setP2023_21_50(res.data);
                    break;
                  
                  case "2022-2023 Kurumsal Paket":
                    setP2022_2023(res.data);
                    break;
                  case "2022-2023 Paket-10":
                    setP2022_2023_10(res.data);
                    break;
                  case "2022-2023 Paket-20":
                    setP2022_2023_20(res.data);
                    break;
                  case "2022-2023 Paket-50":
                    setP2022_2023_21_50(res.data);
                    break;  



                    case "2024 Kurumsal Paket":
                      setP2024(res.data);
                      break;
                    case "2024 Paket-10":
                      setP2024_10(res.data);
                      break;
                    case "2024 Paket-20":
                      setP2024_20(res.data);
                      break;
                    case "2024 Paket-50":
                      setP2024_21_50(res.data);
                      break;
                    
                    case "2023-2024 Kurumsal Paket":
                      setP2023_2024(res.data);
                      break;
                    case "2023-2024 Paket-10":
                      setP2023_2024_10(res.data);
                      break;
                    case "2023-2024 Paket-20":
                      setP2023_2024_20(res.data);
                      break;
                    case "2023-2024 Paket-50":
                      setP2023_2024_21_50(res.data);
                      break;                      
              }
            }

          }

        }).catch(err => {
          handle401(err);
        });
    }
    stData()
  }, [])
  //const [allData, setAllData] = useState([]);
  const [p2023_2024,        setP2023_2024] = useState();
  const [p2023_2024_10,     setP2023_2024_10] = useState();
  const [p2023_2024_20,     setP2023_2024_20] = useState();
  const [p2023_2024_21_50,  setP2023_2024_21_50] = useState();

  const [p2024, setP2024] = useState();
  const [p2024_10, setP2024_10] = useState();
  const [p2024_20, setP2024_20] = useState();
  const [p2024_21_50, setP2024_21_50] = useState();


  const [p2022_2023,        setP2022_2023] = useState();
  const [p2022_2023_10,     setP2022_2023_10] = useState();
  const [p2022_2023_20,     setP2022_2023_20] = useState();
  const [p2022_2023_21_50,  setP2022_2023_21_50] = useState();

  const [p2023, setP2023] = useState();
  const [p2023_10, setP2023_10] = useState();
  const [p2023_20, setP2023_20] = useState();
  const [p2023_21_50, setP2023_21_50] = useState();

  const [p2021_2022, setP2021_2022] = useState();
  const [p2021_2022_10, setP2021_2022_10] = useState();
  const [p2021_2022_20, setP2021_2022_20] = useState();
  const [p2021_2022_21_50, setP2021_2022_21_50] = useState();

  const [p2021, setP2021] = useState();
  const [p2021_10, setP2021_10] = useState();
  const [p2021_20, setP2021_20] = useState();
  const [p2021_21_50, setP2021_21_50] = useState();

  const [p2022, setP2022] = useState();
  const [p2022_10, setP2022_10] = useState();
  const [p2022_20, setP2022_20] = useState();
  const [p2022_21_50, setP2022_21_50] = useState();

  const [p2020, setP2020] = useState();
  const [p2019, setP2019] = useState();


  const [loading, setLoading] = useState(true);


  return (
    <>
      {loading ? (
        <div className="todo__panel-refresh">
          <LoadingIcon />
        </div>
      ) : (
        <Container>
          <div >
            <Row>
              <Col sm={12} className="text-center">
                <h2><span className="text-primary">Kullanmak istediğiniz paketi seçerek kayıt yaptırınız.</span></h2>
                <br></br>
              </Col>
            </Row>

            <Row>
              <CardBasicFree cardData={p2022} />
              <CardBasicFree cardData={p2021} />
              <CardBasicFree cardData={p2020} />
              <CardBasicFree cardData={p2019} />
            </Row>
            <Row>
              <CardSpecialFree cardData={p2023_2024} cardFirmaCount={1} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2023_2024_10} cardFirmaCount={10} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2023_2024_20} cardFirmaCount={20} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2023_2024_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
            </Row>            
            {/* <Row>
              <CardSpecialFree cardData={p2022_2023} cardFirmaCount={1} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2022_2023_10} cardFirmaCount={10} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2022_2023_20} cardFirmaCount={20} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2022_2023_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
            </Row> */}
            {/* <Row>
              <CardSpecialFree cardData={p2021_2022} cardFirmaCount={1} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2021_2022_10} cardFirmaCount={10} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2021_2022_20} cardFirmaCount={20} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
              <CardSpecialFree cardData={p2021_2022_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--warning"} cardImage={`${process.env.PUBLIC_URL}/img/cards/003-airplane.svg`} />
            </Row> */}
            
            {/* <Row>
              <CardSpecialFree cardData={p2022} cardFirmaCount={1} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2022_10} cardFirmaCount={10} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2022_20} cardFirmaCount={20} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2022_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
            </Row> */}
            {/* <Row>
              <CardSpecialFree cardData={p2021} cardFirmaCount={1} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2021_10} cardFirmaCount={10} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2021_20} cardFirmaCount={20} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2021_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
            </Row> */}
            <Row>
              <CardSpecialFree cardData={p2024} cardFirmaCount={1} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2024_10} cardFirmaCount={10} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2024_20} cardFirmaCount={20} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2024_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
            </Row>
            <Row>
              <CardSpecialFree cardData={p2023} cardFirmaCount={1} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2023_10} cardFirmaCount={10} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2023_20} cardFirmaCount={20} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
              <CardSpecialFree cardData={p2023_21_50} cardFirmaCount={"21-50 arası"} cardClass={"pricing-card--info"} cardImage={`${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`} />
            </Row>            
            <div className="container-fluid">
              <div className="footer">
                <h4>Kayıt yaptırdığınız paket, hesaba havale/eft ile ödeme yaptığınızda kullanıma açılacaktır. Fiyat almak için iletişime geçiniz.</h4>
                <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                  <a class="btn btn-primary" href='tel:+905539016056' >0553 901 60 56</a>
                  <a class="btn btn-primary" href='tel:+903122194904' >0312 219 49 04</a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )
      }
    </>)
}

export default PricingCardsFree;
