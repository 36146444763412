import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Modal from '../../components/modal/Modal';
import ContractForm from '../../components/product/Contract';
import axiosNew from '../../data/common/getHeader';
import { handle401 } from "../../data/common/LedgerUtils";
// const iyzicoImg = `${process.env.PUBLIC_URL}/img/logo-band@1X.png`;

class ConfirmPageFree extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      mColor: 'primary',
      message: '',
      mTitle: '',
      subData: [],
      token: '',
      last: false,
      closed: false,
      goLink: '/Odeme',
      process: false,
      bodyToSend: {}
    }
  }

  setShow =(event)=> {
      this.setState({showModal: event})
    }

  componentDidUpdate(){
     let chk = document.querySelectorAll("input[type=checkbox]");
     chk.forEach(item => { item.checked = false });
  }

  componentDidMount(){
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const subId = params.get('subId');
        const body = {
              //"subscriptionId": localStorage.getItem("txID"),
              "subscriptionId": subId,
              "approved": true
            }
       this.setState({bodyToSend: body})
  }


  submitForm = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const subId = params.get('subId');
    const body = {
      //"subscriptionId": localStorage.getItem("txID"),
      "subscriptionId": subId,
      "approved": true
    }


    let errMessage, sColor = "";
    let title = "Sözleşme Onayı";
    axiosNew.post('approveSubscription', body)
      .then(user => {
        if (user.status === 200) {
          this.setState({ showModal: false });
          switch (user.data) {
            case "PAYMENT":
              {
               errMessage = "Onay işleminiz başarıyla sonuçlandı. Ödeme yapıldıktan sonra analiz yapmaya başlayabilirsiniz. Ana sayfaya dönmek için tıklayınız.";
                                 sColor = "success";
                                 //window.location.href = "/Odeme";
                                 this.setState({goLink: '/Anasayfa'});
                this.setState({closed: true, process: true});
                break;
              }
            case "DEMO":
             {
                           errMessage = "Onay işleminiz başarıyla sonuçlandı. Firma ekleyerek analiz işlemine başlayabilirsiniz.";
                                                         sColor = "success";
                                                         this.setState({goLink: '/Paketlerim'});
                            this.setState({closed: true, process: true});
                            break;
            }
            default: {
              errMessage = "Hata Oluştu! \nHata Mesajı: " + user.data;
              sColor = "danger";
              break;
            }
          }
          this.setState({ message: errMessage, showModal: true, mColor: sColor, mTitle: title });
        }
        else {
          errMessage = "Hata Oluştu! \nHata Mesajı: " + user.data;
          sColor = "danger";
          title = "Onay İşlemi Başarısız";
          this.setState({ message: errMessage, showModal: true, mColor: sColor, mTitle: title });

        }
      })
      .catch(function (error) {
        sColor = "danger"; title = "Hata Oluştu!";
        if (error.response) {
          errMessage = "Hata Kodu: " + error.response.status + " Hata Mesajı: " + error.response.data;
        } else if (error.request) {
          errMessage = "Sistem Hatası: Lütfen daha sonra tekrar deneyiniz.";
        } else {
          errMessage = "Hata Mesajı: " + error.message;
        }
        alert(errMessage);
        handle401(error);
      });
  }

  render() {
    return (
      <>
      {this.state.showModal &&
          <Modal setShow={this.setShow} color={this.state.mColor} toggle={this.state.showModal} open={this.state.showModal} header message={this.state.message} title={this.state.mTitle} closedLink={this.state.goLink} isClosed={this.state.closed} isForced={this.state.process}/>}
  
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Sözleşme </h3>
            <h3 className="page-subhead subhead">
              Lütfen tüm sözleşmeleri okuyarak onaylayın.
          </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <ContractForm onSubmit={this.submitForm} body={this.state.bodyToSend} />
          </Col>
        </Row>
        {/* <div className="container-fluid">
            <div className="footer">
                 <img style={{width:"40%",margin: "2% auto"}} src={iyzicoImg} alt="iyzico.png" />
            </div>
        </div> */}
      </Container>
      </>
    )
  }
}

export default withRouter(ConfirmPageFree);
