import React from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';

//const balloon = `${process.env.PUBLIC_URL}/img/cards/002-hot-air-balloon.svg`;

const CardSpecialFree = ({cardData, cardFirmaCount, cardImage, cardClass}) => (
  <Col md={6} xl={3} sm={12}>
    <Card>
      <CardBody className={"pricing-card " + cardClass} >
        <div className="pricing-card__body card-body-justified">
          <img className="pricing-card__img" src={cardImage} alt="" />
          <h2 className="pricing-card__plan">{cardData?.packetName}</h2>
          <hr />
          <p className="pricing-card__feature">{cardData?.packetDescription}</p>
          <hr />
          <p className="pricing-card__feature">{cardFirmaCount} firma içindir.</p>
          <hr />
          <a href={"/AbonelikFaturaBilgileri?paket=" + cardData?.paket}>
            <Button className="pricing-card__button" color="primary">Kayıt Yaptır</Button>
          </a>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default CardSpecialFree;
