import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, Table, Badge, Button } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axiosNew from '../../data/common/getHeader';
import { handle401 } from "../../data/common/LedgerUtils";

const SubCustomerJobs = () => {
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);


  useEffect(() => {

    if (status) {
      setPM('Henüz hiç analiz yapılmadı');
      console.log("çekiyorum dikkat");
      axiosNew.get("getSubCustomerJobs").then(res => {
        if (res.status === 200) {
          setStatus(false);
          setLoading(false);
          setSub(res.data);
          if (res.data?.length > 0)
            setPM('Daha Önceki Analiz Sonuçları Aşağıda Yer Almaktadır');
        }
      }).catch(err => {
        handle401(err);
      });
    }
  }, []);
  const goAnalysis = id => {
    //localStorage.setItem("upload", id);
    window.location.href = "/Sonuclar?analysisId=" + id;
  }


  return (
    <> {loading ? (
      <div className="todo__panel-refresh">
        <LoadingIcon />
      </div>
    ) : (
      <Col md={12} lg={12} xl={9}>
        <Card>
          <CardBody>
            {/* <div className="card__title">
              <h5 className="bold-text">Tüm Hesaplar Sonuçlarım</h5>
              <h5 className="subhead">{pm}
              </h5>
            </div> */}
            <Table className="table--bordered" responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>VKN/TCK NO</th>
                  <th>FİRMA</th>
                  <th>ANALİZ DÖNEMİ</th>
                  <th>DURUM</th>
                  <th>Analiz Tarihi</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {sub.map((d, i) => (
                  <tr>
                    <td>{d.id}</td>
                    <td>{d.tin}</td>
                    <td>{d.taxpayerTitle}</td>
                    <td>{d.periodYear}/{d.periodMonth}-{d.periodType}</td>
                    <td key={i + 1}><Badge color={d.jobStatus === 'TAMAMLANDI' ? 'success' : d.jobStatus === 'DEVAM EDİYOR' ? 'warning' : 'danger'}>{d.jobStatus}</Badge></td>
                    <td>{d.analysisStartDate}</td>
                    {!d.isReportsDeleted ?
                      <>
                        <td><Button className="btn btn-primary btn-sm" onClick={() => goAnalysis(d.id)}>Raporları Görüntüle</Button></td>                        
                      </> :
                      <>
                        <td>Kalıcı olarak Silindi</td>
                        <td>-</td>
                      </>}

                  </tr>
                ))
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>)}
    </>
  );
}
export default withTranslation('common')(SubCustomerJobs);
