import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import axiosNew from '../../data/common/getHeader';
import { handle401 } from "../../data/common/LedgerUtils";
import renderFieldErr from '../form/InputErrorHandler';
import renderField from '../form/MaskInput';
import renderSelectField from '../form/Select';
import validate from './validate';




const BuyFormFree = ({ handleSubmit }) => {
  const [cityList, setCityList] = useState([]);
  const [price, setPrice] = useState();
  const [packetName, setPacketName] = useState();
  const [postBF, setPostBF] = useState(false)
  const [kotaDisabled, setKotaDisabled] = useState(false)
  const [kotaPlaceHolder, setKotaPlaceHolder] = useState("")
  const [refDisabled, setRefDisabled] = useState(false)

  const getKota = (paket,kota) => {
        console.log("kotaaa" + paket + ": " + paket)
        switch (paket) {
            case "GEÇMİŞ_YIL-2019":
                return 1;
            case "GEÇMİŞ_YIL-2020":
                return 1;
            case "GEÇMİŞ_YIL-2021":
                return 1;
            case "GEÇMİŞ_YIL-2022":
              return 1;
            case "2021-PAKET-10LU":
                return 10;
            case "2021-PAKET-20LI":
                return 20;
            case "2021-PAKET-21-50":
                return 50;
            case "CARİ YIL-2022":
               return kota;
            case "2022-PAKET-10LU":
               return 10;
            case "2022-PAKET-20LI":
               return 20;
            case "2022-PAKET-21-50":
                return 50;
            case "2021-2022":
               return kota;
            case "2021-2022-PAKET-10LU":
               return 10;
            case "2021-2022-PAKET-20LI":
               return 20;
            case "2021-2022-PAKET-21-50":
               return 50;
            
            case "CARİ YIL-2023":
                return kota;
             case "2023-PAKET-10LU":
                return 10;
             case "2023-PAKET-20LI":
                return 20;
             case "2023-PAKET-21-50":
                 return 50;
             case "2022-2023":
                return kota;
             case "2022-2023-PAKET-10LU":
                return 10;
             case "2022-2023-PAKET-20LI":
                return 20;
             case "2022-2023-PAKET-21-50":
                return 50;

                case "CARİ YIL-2024":
                  return kota;
               case "2024-PAKET-10LU":
                  return 10;
               case "2024-PAKET-20LI":
                  return 20;
               case "2024-PAKET-21-50":
                   return 50;
               case "2023-2024":
                  return kota;
               case "2023-2024-PAKET-10LU":
                  return 10;
               case "2023-2024-PAKET-20LI":
                  return 20;
               case "2023-2024-PAKET-21-50":
                  return 50;

            default:
               return 0;
        }
    }

  const getPrice = async e => {
    getPriceOnLoad(undefined,Number(e.target.value));
  }

  const getPriceOnLoad = async (pName,kota) => {
      console.log("xxx" + pName + kota)
      let productNum = kota === undefined ? getKota(pName) : kota;
      if (productNum > 0){
          if (packetName === "CARİ YIL-2024" || packetName === "2023-2024" || packetName === "CARİ YIL-2023" || packetName === "2023-2024" ){
              if (productNum <= 3){
                  setPostBF(true);
              } else {
                  setPrice("En fazla 3 adet seçilmelidir.");
                  setPostBF(false);
              }
          } else {
              setPostBF(true);
          }
      } else {
          setPrice("");
          setPostBF(false);
      }

      if (productNum > 0) {
          const body = {
              "packetLevel": packetName === undefined ? pName : packetName,
              "packetLimit": productNum
          }
          await axiosNew.post("getPrice", body).then(res => {
              if (res.status === 200) {
                  if (res.data?.result === 'SUCCESS') {
                      let price = res.data?.price;
                      console.log("price: " + price);
                      if (price > 0){
                          // setPrice("Toplam: " + price.toLocaleString().replace(",", ".") + " TL");
                          setPrice("");
                      }
                      else
                          setPrice("");
                  } else {
                      // setPrice(res.data.result);
                      setPrice("");
                  }
              }
          }).catch(
              err => {
                  setPrice("Fiyat bilgisi yüklenirken bir hata oluştu.");
                  handle401(err);
              }
          );
      }
  }

  useEffect(() => {
    async function getPacket() {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let id = params.get('paket');
      let pName;
      let disableKota = false;
      let kotaPlace = "";
      let disableRef = false;
        switch (parseInt(id)) {
            case 1:
                pName = "GEÇMİŞ_YIL-2019";
                disableKota = true;
                kotaPlace = "1";
                disableRef = true;
                break;
            case 2:
                pName = "GEÇMİŞ_YIL-2020";
                disableKota = true;
                kotaPlace = "1";
                disableRef = true;
                break;
            case 3:
                pName = "GEÇMİŞ_YIL-2021";
                disableKota = true;
                kotaPlace = "1";
                disableRef = true;
                break;
            case 4:
                pName = "2021-PAKET-10LU";
                disableKota = true;
                kotaPlace = "10";
                break;
            case 5:
                pName = "2021-PAKET-20LI";
                disableKota = true;
                kotaPlace = "20";
                break;
            case 6:
                pName = "CARİ YIL-2022";
                disableKota = false;
                kotaPlace = "Lütfen kota sayısını giriniz";
                break;
            case 7:
                pName = "2022-PAKET-10LU";
                disableKota = true;
                kotaPlace = "10";
                break;
            case 8:
                pName = "2022-PAKET-20LI";
                disableKota = true;
                kotaPlace = "20";
                break;
            case 9:
                pName = "2021-2022";
                disableKota = false;
                kotaPlace = "Lütfen kota sayısını giriniz";
                break;
            case 10:
                pName = "2021-2022-PAKET-10LU";
                disableKota = true;
                kotaPlace = "10";
                break;
            case 11:
                pName = "2021-2022-PAKET-20LI";
                disableKota = true;
                kotaPlace = "20";
                break;
             case 12:
                pName = "2021-PAKET-21-50";
                disableKota = true;
                kotaPlace = "50";
                break;
              case 13:
                pName = "2022-PAKET-21-50";
                disableKota = true;
                kotaPlace = "50";
                break;
              case 14:
                pName = "2021-2022-PAKET-21-50";
                disableKota = true;
                kotaPlace = "50";
                break;
              
              case 15:
                pName = "CARİ YIL-2023";
                disableKota = false;
                kotaPlace = "Lütfen kota sayısını giriniz";
                break;
              case 16:
                pName = "2023-PAKET-10LU";
                disableKota = true;
                kotaPlace = "10";
                break;
              case 17:
                pName = "2023-PAKET-20LI";
                disableKota = true;
                kotaPlace = "20";
                break;
              case 18:
                pName = "2023-PAKET-21-50";
                disableKota = true;
                kotaPlace = "50";
                break;
              case 19:
                pName = "2022-2023";
                disableKota = false;
                kotaPlace = "Lütfen kota sayısını giriniz";
                break;
              case 20:
                pName = "2022-2023-PAKET-10LU";
                disableKota = true;
                kotaPlace = "10";
                break;
              case 21:
                pName = "2022-2023-PAKET-20LI";
                disableKota = true;
                kotaPlace = "20";
                break;
              case 22:
                pName = "2022-2023-PAKET-21-50";
                disableKota = true;
                kotaPlace = "50";
                break;

                case 23:
                  pName = "GEÇMİŞ_YIL-2022";
                  disableKota = true;
                  kotaPlace = "1";
                  disableRef = true;
                  break;

                case 24:
                  pName = "CARİ YIL-2024";
                  disableKota = false;
                  kotaPlace = "Lütfen kota sayısını giriniz";
                  break;
                case 25:
                  pName = "2024-PAKET-10LU";
                  disableKota = true;
                  kotaPlace = "10";
                  break;
                case 26:
                  pName = "2024-PAKET-20LI";
                  disableKota = true;
                  kotaPlace = "20";
                  break;
                case 27:
                  pName = "2024-PAKET-21-50";
                  disableKota = true;
                  kotaPlace = "50";
                  break;
                case 28:
                  pName = "2023-2024";
                  disableKota = false;
                  kotaPlace = "Lütfen kota sayısını giriniz";
                  break;
                case 29:
                  pName = "2023-2024-PAKET-10LU";
                  disableKota = true;
                  kotaPlace = "10";
                  break;
                case 30:
                  pName = "2023-2024-PAKET-20LI";
                  disableKota = true;
                  kotaPlace = "20";
                  break;
                case 31:
                  pName = "2023-2024-PAKET-21-50";
                  disableKota = true;
                  kotaPlace = "50";
                  break;

                default:
                pName = "";
                disableKota = true;
                kotaPlace = "";
                break;
        }
      if (!pName) {
        alert("İşlem yapmak için, lütfen anasayfadan bir paket seçiniz.");
        return false;
      }
      else {
        await axiosNew.get("getPacketTypes").then(res => {
          if (res.status === 200) {
              res.data.map((d, i) => {

                  if(d === pName){
                      setPacketName(pName);
                      setKotaDisabled(disableKota);
                      setKotaPlaceHolder(kotaPlace);
                      setRefDisabled(disableRef);
                      getPriceOnLoad(pName);
                  }
              })

          }
        }).catch(err => {
            handle401(err);
        });

        await axiosNew.get("getProvinces").then(res => {
          let list = []
          if (res.status === 200) {
            res.data.map((d, i) => {
              list.push({ value: i, label: d });
            });
          }
          else {
            list = [];
          }
          setCityList(list);
        }).catch(err => {
            handle401(err);
        });

      }
    }
    getPacket();
  }, [])
  const tcVKN = [/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/];
  const kota = [/\d/,/\d/];
  const refCode = [/\d/,/\d/,/\d/,/\d/];
  return (
    <form className="form payment__credit-cards" onSubmit={handleSubmit}>
      <div className="form__form-group-id-category">
        <div className="form__form-group form__form-group-category">
          <span className="form__form-group-label">Abone olunan paket</span>
          <div className="form__form-group-field">
            {/*  <Field
              name="selProd"
              component={renderSelectField}
              type="text"
              value={packetName}
              disabled={true}
              className="form__form-group-input-wrap--error-above"
              onChange={handleChangePackage}
              options={options}
            /> */}
            <Field name="selProd" component="input" type="text" placeholder={packetName} disabled={true} />
          </div>
        </div>
          <div className="form__form-group form__form-group-id">
            <span className="form__form-group-label">Kota</span>
            <div className="form__form-group-field">
              <Field name="kota" component="input" type="text" guide={false} mask={kota}  onChange={getPrice} disabled={kotaDisabled} placeholder={kotaPlaceHolder} />
            </div>
          </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Firma Ünvanı</span>
        <div className="form__form-group-field ">
          <Field name="c_company" required component={renderFieldErr} type="text" placeholder="Şahıs/Şirket" />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">TC Kimlik Numarası / Vergi Kimlik Numarası</span>
        <div className="form__form-group-field ">
          <Field name="c_vkn" required component={renderField} guide={false} mask={tcVKN} type="text" placeholder="TCK/VKN" />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">İl</span>
        <div className="form__form-group-field ">
          <Field
            name="selectCity"
            component={renderSelectField}
            required
            className="form__form-group-input-wrap--error-above"
            options={cityList}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Adres</span>
        <div className="form__form-group-field ">
          <Field name="c_address" required component={renderFieldErr} type="text" placeholder="" />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Telefon</span>
        <div className="form__form-group-field form__form-group-input-wrap--error-above ">
          <Field name="c_phone" component={renderField} type="text" mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} className="phone-input" guide={false} placeholder="(xxx)xxx-xx-xx" />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">E-posta</span>
        <div className="form__form-group-field ">
          <Field name="c_email" required component={renderFieldErr} type="email" placeholder="example@mail.com" />
        </div>
      </div>
        { !refDisabled &&
            <div className="form__form-group">
                <span className="form__form-group-label">Referans Kodu</span>
                <div className="form__form-group-field ">
                    <Field name="c_refcode" component={renderField} type="text" mask={refCode}
                           placeholder="Referans kodunuz varsa girebilirsiniz"/>
                </div>
            </div>
        }
      <h4 className="payment__total">{price}</h4>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit" disabled={!postBF}>Kaydet</Button>
      </ButtonToolbar>
    </form>
  )
};

BuyFormFree.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'payment__credit-cards',
  validate
})(BuyFormFree);
