import React, { Component } from 'react';
import axiosNew from '../../data/common/getHeader';
import {handle401} from "../../data/common/LedgerUtils";


class IyzicoPayment extends Component {
    constructor(){
        super()
        this.state = {
            form: ''
        }
    }
    componentDidMount(){
    var a = JSON.parse(localStorage.getItem("userData"));
    const config = {
      headers: { 
        Authorization: `Bearer ${a?.token}`
      }
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const subId = params.get('subId');
    const body = {
            subscriptionId: subId
        }
    axiosNew.post("getCheckoutForm", body).then((back) => {
        if(back.status === 200)
        {
            if (back.data?.status === "success"){
            var data = back.data?.checkoutFormContent.replace('<script type="text/javascript">','').replace('</script>','');
            //this.setState({ form: data});
            const script = document.createElement("script");
            script.async = true;
            script.innerHTML = data;
            document.body.appendChild(script);
            } else {
                alert(back.data);
            }
        }

    }).catch((error) => {
        alert("Bir hata oluştu, lütfen tekrar deneyiniz.");
        handle401(error);
    });
    
  }
    
    render() {
        const iyzico = `${process.env.PUBLIC_URL}/img/ilogo.svg`;
        return (
            <>
                <div id="iyzipay-checkout-form" class="responsive"></div>
                <img src={iyzico} height="30"/>
            </>
        );
    }
}

export default IyzicoPayment;
