import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row, Card, CardBody, Progress, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import Loading from '../../components/form/Loading';
//import AnalysisForm from '../../components/company/AnalysisCompany';
import { withRouter } from 'react-router-dom';
//import postData from '../../data/company/selectCompanyAPI';
//import validate from '../../components/company/validate';
//import AForm from '../../components/company/AnalysisCompany';
//import showResults from '../../data/company/upFiles';
//import { withTranslation } from 'react-i18next';
import FormTwo from '../../components/company/AnalysisCompanyFormTwo';
import axios from 'axios';
import axiosNew from '../../data/common/getHeader';
import Modal from '../../components/modal/Modal';
import {getUrl, handle401} from "../../data/common/LedgerUtils";
//import {jobIdContext} from '../../shared/contextApi/JobIdContextApi';

const Upload = () => {
  const [prog, setProg] = useState(0);
  const [checked, setChecked] = useState(true);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [closed, setClosed] = useState();
  const [forced, setForced] = useState(false);
  const [finished, setFinished] = useState([]);
  const [messageUpload, setMessageUpload] = useState('');
  const [loading, setLoading] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let jobIdToSend = params.get('jobId');

  let filterRes = [];
  let promises = [];
  let fileNameArr = [];
  const sendData = async (values) => {
    let url = getUrl() + "uploadFile";
    var a = JSON.parse(localStorage.getItem("userData"));
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProg(percentCompleted);
      },
      headers: {
        Authorization: `Bearer ${a?.token}`,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      }
    };
    let count = values.files.length;

    const jid = params.get('jobId');//localStorage.getItem("upload");
    let upCount = 0;
    setMessageUpload(upCount + "/" + count + " adet dosya yüklendi");
    
    for (let i = 0; i < count; i++) {
      let twiceFile = false;
      if(finished?.length > 0){
          for(let z = 0; z < finished.length; z++){
              if(finished[z].name === values.files[i].name)
                  {
                    twiceFile = true;
                    break;
                  }
          }
      }
      if(!twiceFile){
        let formData = new FormData();
        formData.append('jobId', jid);
        formData.append('file', values.files[i]);
        fileNameArr.push(values.files[i].name);
        const ready = await getReady(url, formData, config).then(res => { 
          return res;
        }).catch(err => {
          handle401(err);
          return err;
        });
        if(ready.error === false) {upCount++;}
        promises.push(ready);
        setMessageUpload(upCount + "/" + count + " adet dosya yüklendi");
      }
    }

    const res =  promises.filter(val => { return val.error === true; });
    filterRes = promises.filter(i => i.error === false);
    if(filterRes?.length > 0){
      setFinished(filterRes);
    }
    
    if (res.length === 0) {
      setChecked(false);
      return true;
    } else {
      let c = promises?.length - res?.length;
      setChecked(true);
      setShow(true);
      setMessage('Dosya yükleme esnasında hata oluştu. ' + c + ' adet dosya yüklenemedi.');
      setTitle('Hata Oluştu');
      setColor('warning');
      setLink('');
      setForced(false);
      setClosed(false);
      return false;
    }
  }

  async function getReady(url, body, config) {
    return new Promise((resolve, reject) => {
      axios.post(url, body, config).then(res => {
        if(res.status === 200)
        {
          if(res.data.message.includes("UPLOADED"))
          {
            return resolve({ error: false, message: "Success", name: res.data.fileName, status: res.data.message });
          }else{
            return resolve({ error: true, message: "Failed: " + res.data});
          }
        }
        
      }).catch(function(error) {
         handle401(error);
        if (error instanceof URIError) {
          return reject({ error: true, message: "Failed" });
        } else {
          return reject({ error: true, message: "Failed" });
        }
      });
    });
  }
  const sender = async (val) => {
    const s = await sendData(val);
    return s;
  }

  const goList = async () => {
    const url = "analysis";
    if (!checked) {
      var b = JSON.parse(localStorage.getItem(params.get('jobId') + "-upFiles"))
      if (b !== null) {
        const tin = b.k?.split(" - ")[1];
        const id = params.get('jobId');//localStorage.getItem("upload");
        let formDataNew = new FormData();
        formDataNew.append('jobId', id);
        setLoading(true);
        await axiosNew.post(url,formDataNew).then((res) => {
           setLoading(false);
            if(res.status === 200)
            {
              if(res.data.analysisId !== null){
                setShow(true);
                setMessage('İşlem başarılı, analizi takip etmek için tamam butonuna tıklayınız.');
                setTitle('İşlem Başarılı');
                setColor('success');
                setLink('/Sonuclar?analysisId=' + id);
                setForced(true);
                setClosed(true);
              }
              else if(res.data.errors.length > 0){
                let err = "";
                for(let i = 0; i < res.data.errors.length; i++){
                  err += res.data.errors[i] + " ,";
                }
                setShow(true);
                setMessage( err + "\n Lütfen Analizi En Baştan Başlatınız");
                setTitle('Hata Oluştu');
                setColor('warning');
                setLink('/AnalizYap');
                setForced(true);
                setClosed(true);
              }
              else{
                setShow(true);
                setMessage(res.data + "\n Lütfen Analizi En Baştan Başlatınız");
                setTitle('Hata Oluştu');
                setColor('warning');
                setLink('/AnalizYap');
                setForced(true);
                setClosed(true);
              }
            }
        }).catch((err) => {
           setLoading(false);
            setShow(true);
            setMessage(err + ': lütfen sistem yöneticisiyle görüşün.');
            setTitle('Hata Oluştu');
            setColor('danger');
            setLink('/AnalizYap');
            setForced(true);
            setClosed(true);
            handle401(err);
        });
      }
      //ANALYSIS STARTED
    }
    //window.location.href = "/Sonuclar";
  }


  return (
    <>
      {show && 
      <Modal setShow={setShow} color={color} toggle={show} open={show} header message={message} title={title} closedLink={link} isClosed={closed} isForced={forced}/>
    }
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">E-Defter Analizi</h3>
          <h3 className="page-subhead subhead">Firma ve Hesap Dönemi Seçerek Analizlerinizi Yapın</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody className="">
              <FormTwo onSubmit={sender} jobId={jobIdToSend}/>
              {prog > 0 &&
                <>
                  <Progress value={prog}>{prog}%</Progress>
                  <p>{messageUpload}</p>
                </>
              }
              <ButtonToolbar className="upload__toolbar">
              {loading ? (
    <div className="todo__panel-refresh">
      <Loading />
    </div>
  ): (
                <Button color="primary" className="next mt-3" onClick={goList} disabled={checked}>Analizi Başlat</Button>)}
              </ButtonToolbar>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );

}

Upload.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default withRouter(Upload)//withTranslation('common')(Upload);
