import React, {useState, useEffect,useContext} from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, Badge, Table, Button, } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axiosNew from '../../data/common/getHeader';
import Modal from '../../components/modal/Modal';
import {handle401} from "../../data/common/LedgerUtils";

const Users = () =>
{
   const [show, setShow] = useState(false);
   const [message, setMessage] = useState('');
   const [color, setColor] = useState('');
   const [title, setTitle] = useState('');
   const [link, setLink] = useState('');
   const [closed, setClosed] = useState();
   const [forced, setForced] = useState(false);
  const [sub, setSub] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState('');
  const [status, setStatus] = useState(true);
  useEffect(() => {
    if(status){
      setPM('Yeni Kullanıcı bulunmamaktadır');
      axiosNew.get("getLastUsers").then(res => {
        if (res.status === 200) {
          setStatus(false);
          setLoading(false);
          setSub(res.data);
          if(res.data?.length > 0)
            setPM('Yeni kullanıcılar aşağıda listelenmektedir:');
        }
      }).catch(err => {
        handle401(err);
      });
    }
  }, []);

  const approveUser = (id) => {
     const body = {
           userId: id
         };

      axiosNew.post("approveUserManuelly", body).then((res) => {
            if (res.status === 200) {
             if(res.data === "SUCCESS"){
              setShow(true);
              setMessage('Kullanıcı onaylandı. İşleme devam etmek için tamam butonuna basınız.');
              setTitle('İşlem Başarılı');
              setColor('success');
              setLink('/Yenikullanicilar');
              setClosed(true);
              setForced(true);
              window.location.href = "/Yenikullanicilar";
            }
            else{
                  setShow(true);
                            setMessage(res.data);
                            setTitle('İşlem Başarısız');
                            setColor('warning');
                            setLink('');

            }
          }
          }).catch(err => {
            setShow(true);
            setMessage('Bir hata oluştu, lütfen sistem yöneticisiyle görüşün.');
            setTitle('Hata Oluştu');
            setColor('danger');
            setLink('');
            setForced(false);
            setClosed(false);
            handle401(err);
          });

    }

return(
  <> {loading ? (
    <div className="todo__panel-refresh">
      <LoadingIcon />
    </div>
  ) : (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Yeni Kullanıcılar</h5>
          <h5 className="subhead">{pm}
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>AD</th>
              <th>SOYAD</th>
              <th>E-MAİL</th>
              <th>KAYIT TARİHİ</th>
              <th>DURUM</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

          {sub.map((d,i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{d.name}</td>
              <td>{d.surname}</td>
              <td>{d.email}</td>
              <td>{d.registerDate}</td>
              <td><Badge color={d.status === 'ONAYLANDI' ? 'success' : 'warning'}>{d.status}</Badge></td>
              <td><Button className="btn btn-success btn-sm" disabled = {!d.manuelApprove}  onClick={() => approveUser(d.userId)}>MANUEL ONAYLA</Button></td>
            </tr>
          ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>)}
  </>
);
}
export default withTranslation('common')(Users);
