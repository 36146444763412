/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Lütfen adınızı giriniz';
  }
  if (!values.surname) {
    errors.surname = 'Lütfen soyadınızı giriniz';
  }
  if (!values.username) {
    errors.username = 'Kullanıcı adı alanı boş bırakılmamalıdır';
  }
  if (!values.email) {
    errors.email = 'E-posta alanı boş bırakılmamalıdır';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geçersiz e-posta adresi';
  }
  if (!values.url) {
    errors.url = 'URL alanı boş bırakılmamalıdır';
  } else if (!/^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
    .test(values.url)) {
    errors.url = 'Geçersiz URL';
  }
  if (!values.password3) {
    errors.password3 = 'Şifre alanı boş bırakılmamalıdır';
  } 
  if (!values.password) {
    errors.password = 'Şifre alanı boş bırakılmamalıdır';
  } else if(values.password?.length < 8){
    errors.password = 'Şifreniz en az 8 karakter olmalıdır';
  }else if(!checkStrPass(values.password)){
    errors.password = 'Şifrenizde büyük ve küçük harf, en az bir rakam olmalı.';
  }
  if (values.password !== values.password2) {
    errors.password2 = 'Girdiğiniz şifreler aynı olmalıdır';
  } 
  if (!values.select) {
    errors.select = 'Lütfen bir seçenek seçin';
  }
  if (!values.phone) {
    errors.phone = 'Lütfen telefon numaranızı giriniz';
  }
  return errors;
};
function checkStrPass(pass){
  //var rgx = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
  //var rgx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W\_])[a-zA-Z0-9\W\._]{8,}$/
  var rgx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  return rgx.test(pass);
}
export default validate;
