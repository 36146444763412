import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TotalProfitEarned from '../../components/main/TotalM';
import TotalCustomers from '../../components/main/TotalPM';
import TotalBookings from '../../components/main/PMThisMonth';
import BookingCancels from '../../components/main/PaymentThisMonth';
import { RTLProps } from '../../components/prop-types/ReducerProps';

const MainDashboard = ({ t, rtl }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">İstatistikler</h3>
      </Col>
    </Row>
    <Row>
      <TotalProfitEarned />
      <TotalBookings />
      <TotalCustomers />
      <BookingCancels />
    </Row>
  </Container>
);

MainDashboard.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect(state => ({
  rtl: state.rtl,
}))(withTranslation('common')(MainDashboard));
