import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { Button } from 'reactstrap';
import validate from './validate';
import renderFieldError from '../form/InputErrorHandler';
import renderCheckBoxField from '../form/CheckBox'

const RegisterForm = ({ handleSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [mailApproval, setMailApproval] = useState(false);
  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleChangePackage = e => {
    setConfirm(e.target?.checked);
  }

  const handleMailApproval = e => {
    setMailApproval(e.target?.checked);
  }

  const toggleShowPassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };
  //const passRegx = [/^([@#](?=[^aeiou]{7,13}$)(?=[[:alnum:]]{7,13}$)(?=.*[A-Z]{1,}.*$).+)$/];
  return (
    <form className="form" onSubmit={handleSubmit}>
      {/* <div className="form__form-group">
        <span className="form__form-group-label">Ünvan</span>
        <div className="form__form-group-field">
          <Field name="select" required component={renderSelectField} type="text" options={[ { value: 'one', label: 'SMMM' }, { value: 'two', label: 'Finans Müdürü' }, { value: 'three', label: 'Yönetim Organizasyon' } ]} />
        </div>
      </div> */}
      <div className="form__form-group">
        <span className="form__form-group-label">Adınız</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="name"
            component={renderFieldError}
            type="text"
            placeholder="Adınız"
            className="input-without-border-radius"
            required
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Soyadınız</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="surname"
            component={renderFieldError}
            type="text"
            placeholder="Soyadınız"
            className="input-without-border-radius"
            required
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">E-posta</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AlternateEmailIcon />
          </div>
          <Field
            name="email"
            component={renderFieldError}
            type="email"
            placeholder="example@mail.com"
            required
            className="input-without-border-radius"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Şifre</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component={renderFieldError}
            
            guide={false}
            type={showPassword ? 'text' : 'password'}
            placeholder="******"
            required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={toggleShowPassword}
          ><EyeIcon />
          </button>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Şifre Tekrar</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password2"
            component={renderFieldError}
            type={showPassword2 ? 'text' : 'password'}
            placeholder="******"
            required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword2 ? ' active' : ''}`}
            onClick={toggleShowPassword2}
          ><EyeIcon />
          </button>
        </div>
      </div>
      {/* <div className="form__form-group form__form-group--forgot">
        <span className="form__form-group-label">Cep Telefon Numaranız (xxx)xxx-xx-xx</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CellPhone />
          </div>
          <Field name="phone" required component={renderField} type="text" mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} className="phone-input" guide={false} />
        </div>
      </div> */}
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field required name="checkbox_confirm" component={renderCheckBoxField} onChange={(e) => handleChangePackage(e)} label="" /><a target="_blank" href="/Sozlesmeler">KVKK bilgilendirme metinlerini okudum ve kabul ediyorum</a>
        </div>
      </div>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field name="checkbox_mailApproval" component={renderCheckBoxField} onChange={(e) => handleMailApproval(e)} label="" /><a target="_blank" href="/Sozlesmeler"> Atax'ın tarafıma e-mail göndermesine onay veriyorum/muvafakat ediyorum.</a>
        </div>
      </div>
      <div className="account__btns register__btns">
        <Button type="submit" color="primary" className="account__btn" disabled={!confirm}>
          ÜCRETSİZ ÜYE OL
        </Button>
      </div>
    </form>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

RegisterForm.defaultProps = {
  errorMessage: '',
};

export default reduxForm({
  form: 'register_form',
  validate
})(RegisterForm);
