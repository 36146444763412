import React, {useContext,useEffect} from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import {jobIdContext} from '../../shared/contextApi/JobIdContextApi';

const DropZoneMultipleField = ({ name, value, onChange }) => {

  const files = value;
  const onDrop = (onDropFiles) => {
    onChange(onDropFiles.map(fl =>
      Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      })
    ));
  };
  



  const removeFile = (index, e) => {
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  };



  const getUploadParams = ({ meta }) => { return { url: './files/' } }

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => { }

  // Return array of uploaded files after submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
  }

  //const {jobId}= useContext(jobIdContext);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let jobId = params.get('jobId');

  let fType = ".xml";
  if(localStorage.getItem(jobId + "-fileType") === "zip"){
    fType = ".zip";
  }
  const d = JSON.parse(localStorage.getItem(jobId + "-upFiles"));
  let vkn = d.k?.split(" - ")[1];
  if(vkn === undefined)
  {
    vkn = d.k;
  }
  const y = d.p;
  let m = d.n;
  let mm = true;
  if(m.length === 1){
    mm = false
  }
  
  var arrFilesName = d.filePatterns;//[];
  /*for(let z = 0; z < m; z++){
    let zp = z + 1;
    zp > 9 ? arrFilesName.push(vkn + "-" + y + zp + "-Y-") : arrFilesName.push(vkn + "-" + y + "0" + zp + "-Y-");
  }*/



  const mali = (fileToUpload) =>{
        let stt = false;
        for (let k = 0; k< fileToUpload.length;k++){
            if(arrFilesName?.length > 0){
                 for(let x = 0; x < arrFilesName.length; x++){
                        stt = fileToUpload[k].name.includes(arrFilesName[x]);
                        if (stt){
                            break;
                        }
                 }
                 if(!stt){
                    fileToUpload.splice(k,1);
                    k--;
                 }
            }
        }
 }
  return (
    <>
      <div className="dropzone dropzone--multiple">
        <Dropzone
          className="dropzone__input"
          accept={fType}
          name={name}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          onDrop={(filesToUpload) => {
            mali(filesToUpload);
            onDrop(value ? value.concat(filesToUpload) : filesToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input">

              {(!files || files.length === 0)
                && (
                  <div className="dropzone__drop-here">
                    <span className="lnr lnr-upload" /> <b><h4>Lütfen E-defter ana klasörünü bu alana sürükleyerek bırakınız veya analiz yapılacak dönemin tüm yevmiye defterlerini yüklemek için tıklayınız</h4></b>
                  </div>
                )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {files && Array.isArray(files)
          && (
            <div className="dropzone__imgs-wrapper">
              {files.map((file, i) => (
                <div className="dropzone__img" key={file.i}>
                  <p className="dropzone__img-name">{file.name}</p>
                  <button className="dropzone__img-delete" type="button" onClick={e => removeFile(i, e)}>
                    Sil
                  </button>

                </div>
              ))}
            </div>
          )}
      </div>

      {files && Array.isArray(files)
        && (
          <table className="table table-hover dropzone-bottom-table" id="dbTable">
            <thead>
              <tr>
                <th>Dosya Adı</th>
                <th>Dosyayı Sil</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, i) => (
                <tr>
                  <td>{file.name}</td>
                  <td className="btnClear">
                    <button className="" type="button" onClick={e => removeFile(i, e)}>
                      <span className="sidebar__link-badge badge badge-secondary"><span>Sil</span></span>
                    </button>
                  </td>
                  {/* <td id={file.name.split('.').slice(0, -1).join('.')}>
                    <Badge color="warning">Bekleniyor</Badge>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </>
  );
};

DropZoneMultipleField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      files: PropTypes.string,
    })),
  ]).isRequired,
};

const renderDropZoneMultipleField = ({ input,jobId }) => {
    return <DropZoneMultipleField {...input} />
};

renderDropZoneMultipleField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDropZoneMultipleField;
