import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';

const AtaxDetailedForm = () => (
  <Container>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="user-form" style={{ padding: '20px' }}>
            <div className="search-result">
              <h3 className="page-title">
                A-TAX ELEKTRONİK DENETİM PROGRAMI KULLANIM KILAVUZU
              </h3>
              <br></br>
              <p className="search-result__preview">
                A-TAX Elektronik Denetim Programı (A-TAX), e-Defterlerin vergi,
                muhasebe ve diğer mali mevzuat hükümleri açısından denetimini
                yaparak raporlayan web tabanlı bir elektronik denetim
                programıdır.
              </p>
              <p className="search-result__preview">
                A-TAX a ilişkin tanıtım bilgileri{' '}
                <strong>www.ataxyazilim.com </strong> internet adresinde yer
                almaktadır.
              </p>
              <p className="search-result__preview">
                A-TAX erişim ya <strong>www.ataxyazilim.com</strong> adresindeki{' '}
                <strong>“Analize Giriş”</strong> butonu aracılığıyla ya da
                doğrudan tarayıcının adres kısmına{' '}
                <strong>www.ataxdenetim.com</strong> yazılarak sağlanabilir.
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">1. Sisteme Üye Olunması</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                A-TAX ı kullanabilmek için öncelikle üye olunmalıdır. Üye olmak
                için ya <strong>www.ataxyazilim.com</strong> adresindeki{' '}
                <strong>“Analize Giriş”</strong> butonuna basılmalı veya
                doğrudan tarayıcının adres kısmına{' '}
                <strong>www.ataxdenetim.com</strong> yazılarak gelen sayfada{' '}
                <strong>“Yeni Hesap Oluştur”</strong> butonuna basılmalıdır.
                Açılan sayfada istenilen mail adresi ve şifre bilgileri girilir,
                bunun üzerine sistem girilen mail adresine bir doğrulama mesajı
                gönderir. Gönderilen mesaj onaylandığında A-TAX a üyelik
                gerçekleşmiş olacaktır.
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">
                2. Paket/Ürün Satın Alınması
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                Analiz yapabilmek için <strong>www.ataxdenetim.com </strong>
                adresinden üye olarak giriş yapıldıktan sonra açılan sayfanın
                (anasayfanın) solunda yer alan menüden{' '}
                <strong>“Yeni Paket Al”</strong> butonuna basılmalıdır.
                Mevcut/açılan sayfadaki tarifeden satın alınmak istenilen
                paketin altındaki <strong>“Satın Al” </strong>butonuna
                basılmalı, açılan sayfada önce kaç adet firma için analiz
                yapılması planlanıyorsa o kadar kota sayısı seçilmelidir. Fatura
                düzenlenebilmesi için aynı sayfada, paketi satın alan firmaya
                ait unvan, vergi kimlik numarası (VKN no), adres, mail ve
                telefon bilgileri girilmelidir. Girilen bilgiler kontrol
                edildikten sonra satın almanın gerçekleştirilmesi için{' '}
                <strong>“Kaydet”</strong> butonuna basılmalıdır. Açılan sayfada
                Kişisel Verilerin Korunması Kanunu (KVKK) Formu ve Sözleşme gibi
                hukuken zorunlu belgelerin onaylanması için ilgili kutucuklar
                işaretlenmeli ve <strong>“Satın Al”</strong> butonuna
                basılmalıdır. Bundan sonra elektronik ödeme sistemine
                geçilmektedir. Gelen sayfaya kredi kartı bilgileri girilerek
                ödeme işlemi tamamlandığında satın alma gerçekleştirilmiş olacak
                ve analiz yapılabilir hale gelecektir.
              </p>
              <p className="search-result__preview">
                <strong>NOT:</strong> Ödeme sayfasındaki kredi kartıyla
                taksitlendirme işlemi şirketimiz ile ilgili olmayıp, ilgili
                elektronik ödeme sistemi ve banka tarafından
                gerçekleştirilmektedir.
              </p>
              <p className="search-result__preview">
                <strong>UNUTMAYINIZ!</strong> Sadece satın alınan paketteki kota
                sayısı kadar firma için denetim raporu düzenlenebilir. Cari
                dönem paketinde her firma için her bir aylık dönemde en fazla 3
                adet analiz yaparak rapor alma hakkı bulunmaktadır. Böylece 1
                yıllık bir dönemde bir firma için toplam 36 defa analiz hakkı
                olacaktır.
              </p>
              <p className="search-result__preview">
                Satın alınan paketler menüdeki{' '}
                <strong>“Mevcut Paketlerim” </strong>linkinden takip edilebilir.
                Burada en son alınan paket en üstte olacak şekilde
                listelenmiştir. Daha önce eklenmiş olan firmalara da ilgili
                olduğu paketin satırında <strong>“Firmalarım” </strong>linkinden
                ulaşılabilir.
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">3. Firma Eklenmesi</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                Paket satın alındıktan sonra paketteki kota sayısı kadar firma
                bilgisi sisteme girilerek analiz yapılabilir. Firma bilgisini
                girmek için Mevcut Paketlerim sayfasında, satın alınan paketin
                karşısındaki <strong>“Firma Ekle”</strong> butonuna
                basılmalıdır. Açılan sayfaya, analizi yapılacak firmanın unvanı,
                VKN si ve analizi gerçekleştirenin görevi bilgisi girilerek{' '}
                <strong>“Kaydet” </strong>butonuna basıldığında firma sisteme
                eklenmiş olacaktır.{' '}
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">
                4. Analizin Başlatılması ve Rapor Alınması
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                Ana sayfanın solunda yer alan <strong>“Analizi Başlat”</strong>{' '}
                butonuna basılarak analiz sayfasına ulaşılabilir. Gelen sayfada{' '}
                <strong>“Analiz Bilgilerini Giriniz”</strong> başlığı altında
                satın alınan en son paket seçili olarak gelecektir (birden fazla
                paket varsa hangi paketten analiz yapılacaksa önce o
                seçilmelidir). Daha sonra analizi yapılacak firma ve analiz
                dönemi seçilmelidir. Bu aşamada, analize tabi tutulacak dönemin
                en son ayının seçilmesine dikkat edilmelidir.{' '}
              </p>
              <p className="search-result__preview">
                <strong>Önemli:</strong> Sayfanın altındaki{' '}
                <strong>
                  “Raporları yüklemek istediğiniz dosya formatını seçiniz”
                </strong>{' '}
                kısmında iki dosya (zip ve xml) formatında yükleme seçeneği
                sunulmaktadır. Kullanıcının sıkıştırılmış e-Defter dosyaları
                varsa <strong>“zip”</strong> formatının seçilmesi ve bir sonraki
                aşamada da <strong>“zip”</strong> eklentili e-Defterlerin
                seçilmesi yükleme hızını artıracak ve zaman kaybını önleyerek
                analiz sonucunun daha hızlı çıkmasını sağlayacaktır.
              </p>
              <p className="search-result__preview">
                <strong>“Devam”</strong> butonuna basıldığında{' '}
                <strong>“e-Defter Analizi”</strong> başlığı altında e-Defter
                yükleme ekranı gelecektir. Açılan ekranda dosya yükleme alanına
                tıklanarak analize tabi tutulmak istenilen firmanın ilgili dönem
                defterleri sisteme yüklenebilir. Burada dikkat edilmesi gereken
                en önemli husus, Analiz yapılacak ay seçildikten sonra, ilgili
                ay ve öncesi tüm defterlerin sisteme yüklenmesi gerekmektedir.
                Örneğin analiz için 2021/Mart dönemi seçildi ise, Ocak, Şubat ve
                Mart 2021 aylarına ait tüm Yevmiye defter parçaları sisteme
                yüklenmelidir.
              </p>
              <p className="search-result__preview">
                <strong>Önemli:</strong> Bu aşamada yüklenecek defterlerin bir
                önceki aşamada seçilen sıkıştırılmış <strong>“zip” </strong>veya
                normal <strong>“xml”</strong> formatıyla aynı formatta olmasına
                dikkat edilmelidir.
              </p>
              <p className="search-result__preview">
                <strong>Daha hızlı dosya seçimi için;</strong> ayrı bir pencere
                açılarak defter dosyalarının olduğu klasör (Ör:2020) bulunur ve
                mouse la sürüklenerek dosya yükleme alanına bırakır. Bu durumda
                sistem klasörden sadece gerekli (Y) dosyalarını seçecektir.{' '}
              </p>
              <p className="search-result__preview">
                Bu aşamada seçilen e-Defter dosyaları sadece listelenmiştir,
                yükleme işlemine başlamadan önce e-Defter dosyalarının doğru
                seçilip seçilmediğini kontrol edilmelidir. Seçilen dosyaları
                sisteme yüklemek için yükleme sayfasının altındaki{' '}
                <strong>"DEFTER TARAT"</strong> butonuna basılması gerekir.
                Yükleme işlemi bağlantı hızına ve dosyaların büyüklüğüne bağlı
                olarak biraz sürebilir. Sıkıştırılmış (zip li) dosyaların
                ortalama yükleme işlemi 10 saniye ile 7 dakika arasında
                sürmektedir. Ancak sıkıştırılmamış dosyaların yüklenmesi biraz
                daha uzun sürebilir. A-TAX kendisine gönderilen dosyaları çok
                hızlı şekilde yükleyecek şekilde tasarlanmıştır, bu nedenle
                yükleme işlemi esnasında herhangi bir gecikme yaşanması halinde
                öncelikle bağlantı hızı, dosyaların büyüklüğü ve doğru
                dosyaların seçilip seçilmediği kontrol edilmelidir.
              </p>
              <p className="search-result__preview">
                E-Defter dosyalarının yükleme işlemi sayfanın altındaki barın
                (sayacın) altındaki göstergeden takip edilebilir. Örneğin bar,
                Aralık dönemi sorgusunda 12/12, Haziran dönemi analizinde 6/6
                şeklinde göründüğünde dosyaların yüklendiği anlaşılacaktır.
                Ardından barın sağında yer alan{' '}
                <strong>“Analizi Başlat”</strong> butonuna basılarak analiz ve
                raporlama işlemi başlatılmalıdır. Analiz işlemi e-Defterlerin
                büyüklüğüne bağlı olarak ortalama 1 dakika ile 10 dakika
                arasında sürmektedir. E-Defter sayısına ve büyüklüklerine göre
                daha uzun sürme ihtimali de vardır. Fakat kullanıcı{' '}
                <strong>“Analizi Başlat”</strong> butonuna bastıktan sonra
                işlemin bitmesini beklemek zorunda değildir, isterse sistemden
                ayrılabilir, başlattığı analizin raporuna daha sonra{' '}
                <strong>“Önceki Analizlerim”</strong> sayfasından ulaşabilir.{' '}
              </p>
              <p className="search-result__preview">
                Üretilen rapor <strong>“İndir”</strong> butonu kullanılarak
                kullanıcının bilgisayarına indirilebilir.
              </p>
              <p className="search-result__preview">
                <strong>Önemli:</strong> 12 aylık (yıllık) yapılan analizlerin
                doğru sonuç vermesi ve dönem sonu işlemlerinin doğruluğunun
                kontrolü için, yüklenecek 12 aylık yevmiye defterinde dönem sonu
                işlemleri ve kapanış kaydının yapılmış olması gerekmektedir.
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">
                5. Özelleştirilebilir Rapor
              </p>
              <p className="search-result__preview">
                PDF ve WORD formatında sunduğumuz raporun sadece belirli bir
                kısmına odaklanmak istediğinizde, örneğin sadece vergi
                mevzuatına uyum denetimi gerçekleştirmek istediğinizde bunu
                "Özelleştirilebilir Rapor"'u görüntüleyerek yapabilirsiniz.
                Özelleştirilebilir rapor içerisinde bulgular belirli başlıklar
                altında gruplanmıştır. Rapora eklemek istediğiniz tespitlerin ve
                bu tespitlerle ilgili örnek yevmiye kayıtlarını gösteren
                tabloların yanında bulunan +/- tuşlarına basarak raporu
                özelleştiriniz. Sonrasında "Raporu Yazdır" tuşuna basarak
                özelleştirilmiş rapora ait çıktıyı alabilirsiniz. Çıktı almak yerine PDF olarak da kaydedebilirsiniz.
                Özelleştirilebilir rapor, analiz sonunda yönlendilirilen sonuç ekranında veya menüdeki 
               "Önceki Analizlerim" sayfasında her analize ait raporlarla birlikte görüntülenebilir.
                Önceki Analizlerim -> Raporları GÖrüntüle -> Analiz Raporu (Özelleştirilebilir) "Raporu Görüntüle" tuşlarına 
                basarak raporu görüntüleyebilirsiniz. 
              </p>
            </div>
            <div className="search-result">
              <p className="search-result__title">6. Önceki Analizler</p>
              <p className="search-result__preview">
                Sistem her analize farklı bir <strong>“ID” </strong>numarası
                vermektedir. Kullanıcı VKN, dönem, tarih veya ID numarasını esas
                alarak önceki analiz ve raporlarını takip edebilir. Bir firmanın
                eski bir raporuna ulaşmak için VKN, dönem ve tarih bilgisi
                kullanılabilir.{' '}
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                Daha önce yapılmış olan analizlerin sonuçlarına{' '}
                <strong>“Önceki Analizlerim”</strong> sayfasından ulaşılabilir.
                Buradaki liste en yeni analiz sonucu en üstte olacak şekilde
                sıralanmıştır. Bu sayfadaki{' '}
                <strong>“Raporları Görüntüle” </strong>butonundan eski raporlar
                tekrar indirilebilir.{' '}
              </p>
            </div>

            <div className="search-result">
              <p className="search-result__title">7. Parola Değişikliğir</p>
            </div>

            <div className="search-result">
              <p className="search-result__preview">
                {' '}
                Kullanıcı sol menüdeki <strong>“Üyelik Bilgilerim”</strong>{' '}
                linkini kullanarak mevcut üyelik bilgilerini görüntüleyebilir ve
                şifre değişikliği yapabilir.{' '}
              </p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AtaxDetailedForm;
