import React, {useState} from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../form/Select';
import renderField from '../form/MaskInput';
import validate from './validate';
import renderRadioButtonField from '../form/RadioButton';
import renderDatePickerField from "../form/DatePicker";


const AnalysisCompanyDemo = ({ handleSubmit }) => {
    const [ozelHesapDonemi, setOzelHesapDonemi] = useState(false);
    const changeOzelHesapDonemi = (e) =>{
        var val= e !== "GENEL";
        setOzelHesapDonemi(val);
    }
    return (
    <Col md={12} lg={12}>
        <Card>
            <CardBody>
                <form className="form add_cuser" onSubmit={handleSubmit}>
                    <div className="form__half">
                        <div className="form__form-group">
                            <span className="form__form-group-label">Vergi Kimlik Numarası / TC Kimlik Numarası</span>
                            <div className="form__form-group-field">
                            <Field name="c_vkn" required component={renderField} mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]} guide={false} type="text" placeholder="TCK/VKN" />
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Hesap Dönemi Türü Seçiniz</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="financialPeriodType"
                                    component={renderRadioButtonField}
                                    label="GENEL HESAP DÖNEMİ"
                                    radioValue="GENEL"
                                    defaultChecked
                                    onChange={e=>changeOzelHesapDonemi(e)}
                                />
                                <Field
                                    name="financialPeriodType"
                                    component={renderRadioButtonField}
                                    label="ÖZEL HESAP DÖNEMİ"
                                    radioValue="ÖZEL"
                                    onChange={e=>changeOzelHesapDonemi(e)}

                                />
                                <Field
                                    name="financialPeriodType"
                                    component={renderRadioButtonField}
                                    label="KIST HESAP DÖNEMİ"
                                    radioValue="KIST"
                                    onChange={e=>changeOzelHesapDonemi(e)}

                                />
                            </div>
                        </div>

                        {
                            ozelHesapDonemi &&

                            <div className="form__form-group">
                                <span className="form__form-group-label">Başlangıç Tarihi</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="financialPeriodStartDate"
                                        required
                                        component={renderDatePickerField}
                                        onChange={()=>{}}
                                    />
                                </div>
                            </div>
                        }
                        {
                            ozelHesapDonemi &&
                            <div className="form__form-group">
                                <span className="form__form-group-label">Bitiş Tarihi</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="financialPeriodEndDate"
                                        required
                                        component={renderDatePickerField}
                                        onChange={()=>{}}
                                    />

                                </div>
                            </div>
                        }




                        <div className="form__form-group-date-cvc">
                        <div className="form__form-group form__form-group-date">
                            <span className="form__form-group-label">Hesap Dönemi Seçiniz / AY</span>
                            <div className="form__form-group-field">
                                <Field name="selectMonth" required  className="form__form-group-input-wrap--error-above" component={renderSelectField} type="text"
                                    options={[
                                        { value: 'zero', label: 'Lütfen Ay Seçiniz' },
                                        { value: '1', label: 'Ocak' },
                                        { value: '2', label: 'Şubat' },
                                        { value: '3', label: 'Mart' },
                                        { value: '4', label: 'Nisan' },
                                        { value: '5', label: 'Mayıs' },
                                        { value: '6', label: 'Haziran' },
                                        { value: '7', label: 'Temmuz' },
                                        { value: '8', label: 'Ağustos' },
                                        { value: '9', label: 'Eylül' },
                                        { value: '10', label: 'Ekim' },
                                        { value: '11', label: 'Kasım' },
                                        { value: '12', label: 'Aralık' },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="form__form-group form__form-group-cvc">
                            <span className="form__form-group-label">Hesap Dönemi Seçiniz / YIL</span>
                            <div className="form__form-group-field">
                                <Field name="selectYear" required className="form__form-group-input-wrap--error-above" component={renderSelectField} type="text"
                                    options={[
                                        { value: 'zero', label: 'Lütfen Yıl Seçiniz' },
                                        { value: '2019', label: '2019' },
                                        { value: '2020', label: '2020' },
                                        { value: '2021', label: '2021' },
                                        { value: '2022', label: '2022' },
                                        { value: '2023', label: '2023' },
                                        { value: '2024', label: '2024' }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form__form-group">
                            <span className="form__form-group-label">Raporları yüklemek istediğiniz dosya formatını seçiniz</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="fileType"
                                    component={renderRadioButtonField}
                                    label="zip"
                                    radioValue="zip"
                                    defaultChecked
                                />
                                <Field
                                    name="fileType"
                                    component={renderRadioButtonField}
                                    label="xml"
                                    radioValue="xml"

                                />
                            </div>
                        </div>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit">Kaydet</Button>
                    </ButtonToolbar>
                    </div>
                </form>
            </CardBody>
        </Card>
    </Col>
)};

AnalysisCompanyDemo.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'add_cuser', // a unique identifier for this form
    validate
})(AnalysisCompanyDemo);