import React from 'react';
import MaskedInput from 'react-text-mask';

const renderField = ({
  input, placeholder, type, mask, meta: { touched, error },className, guide = true, pipe, keepCharPositions = false
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <MaskedInput
      {...input}
      placeholder={placeholder}
      type={type}
      mask={mask}
      className={className}
      guide={guide}
      pipe={pipe}
      keepCharPositions={keepCharPositions}
    />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


export default renderField;
