import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';

const ModalComponent = ({
  color, title, message, colored, header, rtl, open, isClosed, closedLink, isForced, setShow
}) => {
  const [modal, setModal] = useState(open);

  const toggle = () => {
    setShow(false)
    setModal(prevState => !prevState);
    if(isForced)
    {
      window.location.href = closedLink;  
    }

  };

  const goModal = link =>{
    window.location.href = link;
  }

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">
          {message}
        </div>
        {isClosed ?
        <ButtonToolbar className="modal__footer">
          <Button className="modal_ok" outline={colored} color={color} onClick={() => goModal(closedLink)}>Tamam</Button>
        </ButtonToolbar>
        : ''
        }
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  rtl: RTLProps.isRequired,
  isClosed: PropTypes.bool,
  closedLink: PropTypes.string,

};

ModalComponent.defaultProps = {
  title: 'Defter Denetim',
  message: 'Finansal Analiz Uzmanı',
  colored: false,
  header: true,
};

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
